import { useMemo } from "react";
import { IShareholder } from "../../../Company/types";

const useShareholdersSort = (
  shareholders: IShareholder[],
  sortBy: string,
  isDescending: boolean,
) => {
  const sortedShareholders = useMemo(() => {
    const sorted = [...(shareholders || [])].sort((a, b) => {
      if (sortBy === "percentage") {
        return a.percentage < b.percentage ? -1 : 1;
      }
      if (sortBy === "name") {
        return a.name < b.name ? -1 : 1;
      }
      if (sortBy === "shareholder-type") {
        return a.shareholderType < b.shareholderType ? -1 : 1;
      }
      if (sortBy === "share-type") {
        return a.shareType < b.shareType ? -1 : 1;
      }

      return 0;
    });

    if (!isDescending) {
      return sorted.reverse();
    }

    return sorted;
  }, [sortBy, isDescending, shareholders]);

  return sortedShareholders;
};

export default useShareholdersSort;
