import { Circle } from "@mui/icons-material";
import {
  Box,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { dark, primary } from "../../pages/variables";
import React from "react";
import styled from "styled-components";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    PopperProps={{
      modifiers: [{ name: "offset", options: { offset: [0, -7] } }],
    }}
  />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: primary,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: primary,
    padding: "10px",
  },
}));

type Props = {
  color: string;
  status: string;
  statusDetail?: string;
};

const Status: React.FC<Props> = ({
  color,
  status,
  statusDetail,
}): JSX.Element => {
  const component = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Circle
        sx={{
          width: "0.5em",
          height: "0.5em",
          marginRight: 1,
          color,
        }}
      />
      <Typography
        variant="caption"
        color={dark}
        fontWeight={500}
        fontSize="0.875rem"
      >
        {status}
      </Typography>
    </Box>
  );
  return (
    <>
      {statusDetail ? ( // Include tooltip if detail is provided
        <StyledTooltip title={statusDetail} placement="bottom">
          {component}
        </StyledTooltip>
      ) : (
        component
      )}
    </>
  );
};

export default Status;
