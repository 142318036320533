import { useCallback } from "react";
import { useAuthFetch } from "./useAuthFetch";

const useEditUserFetcher = () => {
  const fetch = useAuthFetch();

  return useCallback(
    async ({ ...data }) => {
      const id = data?.id as string;
      delete data.id;

      const response = await fetch(
        `${process.env.REACT_APP_PORTAL_API_URL}/accounts/${id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        },
      );
      return response;
    },
    [fetch],
  );
};

export default useEditUserFetcher;
