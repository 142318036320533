import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Search from "./pages/Search/Search";
import Company from "./pages/Company";
import { Manual as Manual } from "./pages/Company";
import OrderHistory from "./pages/OrderHistory";
import Forbidden from "./pages/Forbidden";
import NotFound from "./pages/NotFound";
import Unavailable from "./pages/Unavailable";
import Layout from "./layouts/Layout";
import { CreditContextProvider } from "contexts/CreditContext";
import { UserContextProvider } from "contexts/UserContext";
import LoadingMessage from "components/LoadingMessage";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import { NodePopupContextProvider } from "contexts/NodePopupContext";
import { FeatureFlagsProvider } from "contexts/FeatureFlagContext";

const authedMessage = "We're authenticating your request";

const centeredStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const App: React.FC = () => {
  const onRedirect = () => <LoadingMessage message={authedMessage} />;

  return (
    <CreditContextProvider>
      <UserContextProvider>
        <NodePopupContextProvider>
          <FeatureFlagsProvider>
            <ErrorBoundary
              FallbackComponent={({ error }) => (
                <ErrorMessage
                  sx={{
                    ...centeredStyle,
                    height: "100vh",
                  }}
                  message={`${error.message} (${error.name})`}
                />
              )}
            >
              <Layout>
                <Routes>
                  <Route
                    path="/"
                    Component={withAuthenticationRequired(Home, {
                      onRedirecting: onRedirect,
                    })}
                  />
                  <Route
                    path="/home"
                    Component={withAuthenticationRequired(Home, {
                      onRedirecting: onRedirect,
                    })}
                  />
                  <Route
                    path="/admin"
                    Component={withAuthenticationRequired(Admin, {
                      onRedirecting: onRedirect,
                    })}
                  />
                  <Route
                    path="/search"
                    Component={withAuthenticationRequired(Search, {
                      onRedirecting: onRedirect,
                    })}
                  />
                  <Route
                    path="/company"
                    Component={withAuthenticationRequired(Company, {
                      onRedirecting: onRedirect,
                    })}
                  />
                  <Route
                    path="/manual-retrieval"
                    Component={withAuthenticationRequired(Manual, {
                      onRedirecting: onRedirect,
                    })}
                  />
                  <Route
                    path="/order-history"
                    Component={withAuthenticationRequired(OrderHistory, {
                      onRedirecting: onRedirect,
                    })}
                  />
                  <Route path="/forbidden" Component={Forbidden} />
                  <Route path="/unavailable" Component={Unavailable} />
                  <Route path="*" Component={NotFound} />
                </Routes>
              </Layout>
            </ErrorBoundary>
          </FeatureFlagsProvider>
        </NodePopupContextProvider>
      </UserContextProvider>
    </CreditContextProvider>
  );
};

export default App;
