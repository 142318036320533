import { Box } from "@mui/material";
import { secondary_mid } from "pages/variables";
import { useState } from "react";
import { useInterval } from "usehooks-ts";

const GreenPulsingDot = (): JSX.Element => {
  const [innerVisible, setInnerVisible] = useState(false);
  const [outerVisible, setOuterVisible] = useState(false);
  const [stage, setStage] = useState(0);
  useInterval(() => {
    setStage(stage + 1);
    switch (stage) {
      case 1:
        setInnerVisible(true);
        break;
      case 2:
        setOuterVisible(true);
        break;
      case 3:
        setInnerVisible(false);
        setOuterVisible(false);
        setStage(1);
        break;
      default:
    }
  }, 1000);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: " center",
        position: "relative",
        margin: 2,
        marginTop: "20px",
      }}
    >
      <Box
        sx={{
          width: "12px",
          height: "12px",
          borderRadius: "6px",
          backgroundColor: secondary_mid,
          opacity: outerVisible ? 0.1 : 0,
          position: "absolute",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          width: "8px",
          height: "8px",
          borderRadius: "4px",
          backgroundColor: secondary_mid,
          opacity: innerVisible ? 0.2 : 0,
          position: "absolute",
          zIndex: 2,
        }}
      />
      <Box
        sx={{
          width: "4px",
          height: "4px",
          borderRadius: "2px",
          backgroundColor: secondary_mid,
          position: "absolute",
          zIndex: 3,
        }}
      />
    </Box>
  );
};

export default GreenPulsingDot;
