import { createTheme, ThemeOptions } from "@mui/material";
import {
  primary,
  primary_dark,
  primary_mid,
  primary_light,
  secondary,
  secondary_mid,
  secondary_dark,
  red,
  amber,
} from "./variables";

const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: primary,
      dark: primary_dark,
      contrastText: primary_mid,
      light: primary_light,
    },
    success: {
      main: secondary,
      light: secondary_mid,
      dark: secondary_dark,
    },
    error: {
      main: red,
    },
    warning: {
      main: amber,
    },
  },
  typography: {
    fontFamily: [
      "Anthro",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
      "Sohne",
    ].join(","),
  },
};

export default createTheme(themeOptions);
