import { createTheme, ThemeOptions } from "@mui/material";

const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#121212",
    },
    secondary: {
      main: "#1184BA",
    },
  },
  components: {},
};

export default createTheme(themeOptions);
