import { FetchError } from "./FetchError";
import { useAuth0 } from "@auth0/auth0-react";

const auth0Scope = {
  audience: "Kyckr.Dashboard.Backend",
  scope: "read:current_user update:current_user_metadata offline_access search",
};

export let hasSentLastLoginUpdate = false;

export function setLastLoginUpdate(val: boolean) {
  hasSentLastLoginUpdate = val;
}

export function useAuthFetch() {
  const { getAccessTokenSilently } = useAuth0();

  const request = async (input: RequestInfo, init: RequestInit = {}) => {
    const accessToken = await getAccessTokenSilently(auth0Scope);
    if (!accessToken) {
      throw Error("No access token");
    }

    if (!hasSentLastLoginUpdate) {
      hasSentLastLoginUpdate = true;
      const data = {
        updateLastLogin: true,
      };
      fetch(`${process.env.REACT_APP_PORTAL_API_URL}/accounts/userId`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }).catch((error) => {
        console.error(error);
      });
    }

    const response = await fetch(input, {
      ...init,
      headers: {
        ...init.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response.statusText, response.status, response);
    }

    return response;
  };

  return request;
}
