import { Menu, MenuItem, IconButton, Box } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

type Props = {
  isActiveUserMenu: boolean;
  onEditClick?: React.MouseEventHandler;
  onRemoveClick?: React.MouseEventHandler;
  onPasswordResetClick?: React.MouseEventHandler;
  onActivateClick?: React.MouseEventHandler;
  hideDeleteButton?: boolean;
};

const ITEM_HEIGHT = 48;

const Action: React.FC<Props> = ({
  isActiveUserMenu,
  onEditClick,
  onRemoveClick,
  onPasswordResetClick,
  onActivateClick,
  hideDeleteButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onEditClick !== undefined) {
      onEditClick(event);
    }
  };

  const handleRemoveClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onRemoveClick !== undefined) {
      onRemoveClick(event);
    }
  };

  const handlePasswordResetClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onPasswordResetClick !== undefined) {
      onPasswordResetClick(event);
    }
  };

  const handleActivateClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onActivateClick !== undefined) {
      onActivateClick(event);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          borderRadius: "50%",
          margin: "auto",
          "&:hover": {
            borderRadius: "50%",
            margin: "auto",
          },
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {isActiveUserMenu ? (
          <Box>
            <MenuItem
              key={"Edit User"}
              selected={true}
              onBlur={handleMenuClose}
              onClick={handleActionClick}
            >
              {"Edit User"}
            </MenuItem>
            {!hideDeleteButton && (
              <MenuItem
                key="Delete User"
                selected={false}
                onBlur={handleMenuClose}
                onClick={handleRemoveClick}
              >
                {"Delete User"}
              </MenuItem>
            )}
            <MenuItem
              key="Password Reset"
              selected={false}
              onBlur={handleMenuClose}
              onClick={handlePasswordResetClick}
            >
              {"Password Reset"}
            </MenuItem>
          </Box>
        ) : (
          <MenuItem
            key="Activate User"
            selected={false}
            onBlur={handleMenuClose}
            onClick={handleActivateClick}
          >
            {"Activate User"}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default Action;
