import { useCallback } from "react";
import { useAuthFetch } from "./useAuthFetch";

const useExportAccounts = () => {
  const fetch = useAuthFetch();

  return useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_PORTAL_API_URL}/accounts/organization/members/export`,
      {
        method: "GET",
      },
    );

    const contentType = response.headers.get("content-type");
    let filename = "download.csv";
    if (contentType) {
      filename = nameBeautifier(contentType);
    }

    response.blob().then((blob) => download(blob, filename));
  }, [fetch]);
};

function download(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

function nameBeautifier(filename: string): string {
  filename = filename.replace("text/csv; filename=", "");
  filename = filename.replace(/\.\.\./g, "-"); //replace the ... with - (escape dot in regex)
  filename = filename.replace(/\.\./g, " ");
  return filename;
}

export default useExportAccounts;
