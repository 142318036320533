import { format, isValid } from "date-fns";

export const toDisplayDate = (date: string | Date): string => {
  try {
    if (isValid(new Date(date))) {
      return format(new Date(date), "dd/MM/yyyy");
    }
    return date.toString();
  } catch (e) {
    return date.toString();
  }
};

export const toDisplayTime = (date: string | Date): string => {
  try {
    if (isValid(new Date(date))) {
      return format(new Date(date), "HH:mm");
    }
    return date.toString();
  } catch (e) {
    return date.toString();
  }
};
