import { styled } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";
import { light } from "pages/variables";

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "white",
  textTransform: "none",
  fontSize: "12px",
  fontWeight: 500,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&.Mui-disabled": {
    backgroundColor: light,
  },
  width: "fit-content",
  padding: "8px 12px",
  lineHeight: "1.125rem",
}));

export default StyledButton;
