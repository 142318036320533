import qs from "query-string";
import { useApi } from "hooks/useApi";
import { IFilingSearchResponse } from "pages/Company/types";
import { CompanyProfile, Filing } from "./types";
import { useEffect, useState } from "react";
import { transform } from "utils/jurisdiction";
import { manualFilingRetrievalJurisdictions } from "components/CompanySearch/specialJurisdictions";

const useFilings = (
  companyProfile: CompanyProfile,
  companyCode: string,
  jurisdiction: string,
  loadFilingsManually = false,
) => {
  const [filings, setFilings] = useState<Filing[]>([]);
  const filingSearchParams = qs.stringify(
    {
      ...(companyProfile.registrationAuthorityCode && {
        registrationAuthorityCode: companyProfile.registrationAuthorityCode,
      }),
    },
    { skipNull: true },
  );

  const { isLoading, data } = useApi<IFilingSearchResponse>(
    `/FilingSearch/${transform(jurisdiction)}/${encodeURIComponent(
      companyCode || companyProfile.name || companyProfile.code || "",
    )}?${filingSearchParams}`,
    {
      enabled:
        (!!companyCode || !!companyProfile.name || !!companyProfile.code) &&
        (!manualFilingRetrievalJurisdictions.includes(jurisdiction) ||
          loadFilingsManually),
    },
  );

  useEffect(() => {
    if (data) {
      const parsedFilings = data?.products?.map((product) => ({
        id: product.id,
        countryISO: jurisdiction,
        title: product.productTitle,
        datePublished: product.displayDate,
        creditCost: product.tierValueNormalized,
        contains: product.productCategoriesTypes,
        deliveryTimeMinutes: product.deliveryTimeMinutes,
        filingSample: product.filingSample,
        productCode: product.productCode,
      }));
      setFilings(parsedFilings);
    }
  }, [data, jurisdiction]);

  return {
    filings,
    isLoading,
  };
};

export default useFilings;
