import React from "react";
import { Schedule, HourglassEmpty } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { dark, mid } from "../../../variables";

interface IWaitTimeProps {
  timeInMinutes: number | null;
}

const calculateWaitTime = (timeInMinutes: number) => {
  const minutes = timeInMinutes;
  const hours = Math.floor(timeInMinutes / 60);
  const days = Math.floor(timeInMinutes / 1440);

  return {
    minutes,
    hours,
    days,
  };
};

export const WaitTime: React.FC<IWaitTimeProps> = ({ timeInMinutes }) => {
  if (!timeInMinutes) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Schedule fontSize="small" sx={{ color: mid }} />
        <Typography color={dark} sx={{ fontSize: "0.875rem" }}>
          In Realtime
        </Typography>
      </Box>
    );
  }

  const { minutes, hours, days } = calculateWaitTime(timeInMinutes);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <HourglassEmpty fontSize="small" sx={{ color: mid }} />
      <Typography color={dark} sx={{ fontSize: "0.875rem" }}>
        {days
          ? `${days} day${days > 1 ? "s" : ""}`
          : hours
            ? `${hours} hour${hours > 1 ? "s" : ""}`
            : `${minutes} min${minutes > 1 ? "s" : ""}`}
      </Typography>
    </Box>
  );
};
