import { getJurisdictionOrSubJurisdictionName } from "jurisdictions";

export default function getErrorMessage(
  code: number | string,
  companyName: string,
  searchType: string,
  jurisdiction: string,
) {
  switch (code) {
    case "K-EBP-0030":
    case 104:
      return "Unable to connect to registry, please try again later";
    case 500:
      return "Error";
    case 109:
      return "Search was unsuccessful because too many matches were returned, please refine your search";
    case 110:
      return "The company number specified does not match the format expected for the registry";
    default:
      return `We can’t find any results for company ${searchType} “${companyName}” ${
        jurisdiction
          ? "in " + getJurisdictionOrSubJurisdictionName(jurisdiction)
          : ""
      }`;
  }
}
