import { ISearchResult } from "pages/Search/types";
import { useNavigate } from "react-router-dom";
import ResultItem from "./ResultItem";
import { Box } from "@mui/material";
import {
  getJurisdictionOrSubJurisdictionName,
  jurisdictions,
} from "jurisdictions";
import useCompanyResultsFilter from "./useCompanyResultsFilter";
import { useForm, useWatch } from "react-hook-form";
import FilterSelect from "components/FilterSelect";
import React from "react";
import { manualRetrievalJurisdictions } from "components/CompanySearch/specialJurisdictions";
import { Userpilot } from "userpilot";
type SelectOption = { name: string; isoAlpha2Code: string; type: string };

type FormInputs = {
  filterBy: SelectOption;
  isDescending: boolean;
};

type Props = {
  searchResults: ISearchResult[];
  jurisdiction: string | undefined;
};

const SearchResults: React.FC<Props> = ({
  searchResults,
  jurisdiction,
}: Props) => {
  const navigate = useNavigate();

  const getJurisdictionType = (countryISO: string) => {
    if (countryISO.length > 2) {
      const country = jurisdictions.find((j) =>
        j.isoAlpha2Code.startsWith(countryISO.slice(0, 2)),
      );

      return country?.name ?? "";
    } else {
      return "";
    }
  };

  const uniqueJurisdictions = [
    ...new Map(
      searchResults.map((sr) => [
        sr.countryISO,
        {
          name: getJurisdictionOrSubJurisdictionName(
            jurisdiction ? jurisdiction : sr.countryISO,
          ),
          type: getJurisdictionType(
            jurisdiction ? jurisdiction : sr.countryISO,
          ),
          isoAlpha2Code: jurisdiction ? jurisdiction : sr.countryISO,
        },
      ]),
    ).values(),
  ].sort((a, b) => {
    if (a.type == b.type) {
      return a.name < b.name ? -1 : 1;
    }
    const sortStringA = a.type !== "" ? a.type : a.name;
    const sortStringB = b.type !== "" ? b.type : b.name;
    return sortStringA < sortStringB ? -1 : 1;
  });

  const sortOptions = [
    { name: "All Countries", type: "", isoAlpha2Code: "all" },
    ...uniqueJurisdictions.values(),
  ];

  const onAscDescHandler = () => {
    Userpilot.track("Global Search Sort Order Clicked");
    setValue("isDescending", !isDescending);
  };

  const { control, setValue } = useForm<FormInputs>({
    defaultValues: {
      filterBy: sortOptions[0],
      isDescending: true,
    },
  });

  const filtering = useWatch({ control, name: "filterBy" });
  const isDescending = useWatch({ control, name: "isDescending" });

  if (!jurisdiction) {
    searchResults.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
  }

  const filteredResults = useCompanyResultsFilter(
    searchResults,
    filtering.isoAlpha2Code,
    isDescending,
  );

  const onViewCompanyClicked = (sr: ISearchResult, jur: string | undefined) => {
    const jurisdiction = jur ? jur : sr.countryISO;

    const uri = manualRetrievalJurisdictions.includes(jurisdiction)
      ? `/manual-retrieval?search=${encodeURIComponent(
          sr.name,
        )}&jurisdiction=${encodeURIComponent(jurisdiction)}&searchType=name`
      : `/company?jurisdiction=${encodeURIComponent(
          jurisdiction,
        )}&companyCode=${encodeURIComponent(sr.code)}`;

    navigate(uri, {
      replace: true,
      state: sr,
    });
  };

  return (
    <Box>
      {!jurisdiction && (
        <FilterSelect
          control={control}
          options={sortOptions}
          onAscDescHandler={onAscDescHandler}
          isDescending={isDescending}
          inputWidth="16rem"
        />
      )}

      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {filteredResults &&
          filteredResults.length > 0 &&
          filteredResults.map((sr, index) => (
            <ResultItem
              key={index}
              companyCode={sr.code}
              companyName={sr.name}
              companyNameInEnglish={sr.companyNameInEnglish}
              legalStatus={sr.legalStatus}
              jurisdiction={jurisdiction ? jurisdiction : sr.countryISO}
              companyNumber={jurisdiction === "NZ" ? sr.code : sr.id}
              address={sr.addresses?.[0]?.addressInOneLine}
              incorporationDate={sr.date ? new Date(sr.date) : null}
              onViewCompanyClicked={() =>
                onViewCompanyClicked(sr, jurisdiction)
              }
            />
          ))}
      </Box>
    </Box>
  );
};

export default SearchResults;
