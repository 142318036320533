import { useMutation } from "react-query";
import useAddUserFetcher from "./useAddUserFetcher";

export type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  credits: number;
  role: string;
  notifyByEmail: string;
};

export const useAddUser = (
  toastHandler: (v: string, f: boolean, isEditAction: boolean) => void,
  refetchUsers: () => void,
) => {
  const fetcher = useAddUserFetcher();

  return useMutation(
    (params: Inputs) => {
      return fetcher({
        ...params,
      });
    },
    {
      onSuccess: () => {
        refetchUsers();
        toastHandler("Successfully created user.", false, false);
      },
    },
  );
};
