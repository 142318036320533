import ErrorMessage from "components/ErrorMessage";

export default function NotFound(): JSX.Element {
  return (
    <ErrorMessage
      message="Page not found. Please return to the home page."
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "75vh",
        gap: 2,
      }}
    />
  );
}
