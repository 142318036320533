import { Box, Button, Grid, Typography } from "@mui/material";
import {
  light,
  lightest,
  primary,
  primary_dark,
  primary_light,
  white,
} from "pages/variables";
import { ReactComponent as Cross } from "assets/kyckr-framework/cross.svg";
import { styled } from "@mui/material/styles";
import { jurisdictions } from "jurisdictions";
import { ICandidate } from "pages/Company/Tabs/Ubo/types";
import { EdgeDataDefinition, NodeDataDefinition } from "cytoscape";
import { Popper } from "contexts/NodePopupContext";
import qs from "query-string";
import { isNullOrWhitespace } from "utils/string";
import { JointHoldingGroup, NodeShareholdings } from "./types";

type Props = {
  nodeId: string;
  popperCollection: Record<string, Popper | null>;
  nodeData: NodeDataDefinition | EdgeDataDefinition;
};

const PopupButton = styled(Button)({
  color: "white",
  textTransform: "none",
  fontSize: "12px",
  fontWeight: 500,
  fontFamily:
    "Anthro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif,Sohne;",
  backgroundColor: primary,
  "&:hover": {
    backgroundColor: primary_dark,
  },
  "&.Mui-disabled": {
    backgroundColor: light,
  },
  width: "fit-content",
  padding: "8px 12px",
  lineHeight: "1.125rem",
});

const CandidateDescription = styled(Typography)({
  fontSize: "0.75rem",
  fontWeight: 500,
  color: primary_dark,
  fontFamily: "Anthro",
  overflowWrap: "break-word",
});

const formatOwnership = (ownership: number): number => {
  if (isNaN(ownership)) {
    return ownership;
  }

  return Math.round((ownership + Number.EPSILON) * 100) / 100;
};

const normalizeName = (name: string | null) => {
  if (name) {
    name = name.toLowerCase();
    const arr = name.split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(" ");
  }

  return name;
};

const getCountryName = (countryIso: string | null) => {
  if (countryIso) {
    return (
      jurisdictions.find(
        (x) => x.isoAlpha2Code.toUpperCase() == countryIso.toUpperCase(),
      )?.name ?? countryIso
    );
  }
  return countryIso;
};

const NodePopup = ({ nodeId, popperCollection, nodeData }: Props) => {
  const name = normalizeName(nodeData.name);
  const type = normalizeName(nodeData.entityType);
  const role = normalizeName(nodeData.role);
  const jurisdiction = nodeData.countryIso;
  const country = getCountryName(jurisdiction);
  const companyCode = nodeData.companyCode;
  const registrationAuthorityCode: string | null =
    nodeData.registrationAuthorityCode ??
    nodeData.registrationAuthority ??
    nodeData.regAuth;
  const address: string | null = nodeData.address;
  const ownership: string | null = nodeData.rollupPercentage;
  const profileLink: string | null = nodeData.profileLink
    ? nodeData.profileLink.replace("json/", "")
    : null;

  const rfncDetails: string | null =
    nodeData.rfnc?.details ?? nodeData.rfnc?.type;
  const rfncType: string | null = nodeData.rfnc?.type;
  const candidates: ICandidate[] | null = nodeData.rfnc?.candidates;
  const shareholdings: NodeShareholdings = nodeData.shareholdings;
  const jointHoldingGroups: JointHoldingGroup[] = nodeData.jointHoldingGroups;
  const popupBgColor = primary_light;
  const fontColor = primary_dark;
  const NodeType = styled(Typography)({
    fontSize: "0.875rem",
    fontWeight: 900,
    color: fontColor,
    fontFamily: "Anthro",
    overflowWrap: "break-word",
  });

  const NodeDescription = styled(Typography)({
    fontSize: "0.875rem",
    fontWeight: 500,
    color: fontColor,
    fontFamily: "Anthro",
    overflowWrap: "break-word",
  });

  const handleClose = () => {
    if (popperCollection) {
      const popper = popperCollection[nodeId];
      if (popper) {
        popper.destroy();
      }
      popperCollection[nodeId] = null;
    }
  };

  const profileLinkParams = qs.stringify(
    {
      companyCode: companyCode,
      companyName: name?.toUpperCase(),
      ...(!isNullOrWhitespace(registrationAuthorityCode) &&
        jurisdiction === "DE" && {
          registrationAuthorityCode: registrationAuthorityCode,
        }),
    },
    { skipNull: true },
  );

  const openEnhancedProfileTab = () => {
    let urlParam = "";
    if (profileLink) {
      urlParam = "&url=" + encodeURI(profileLink);
    }

    const win = window.open(
      `/company?jurisdiction=${jurisdiction}&${profileLinkParams}${urlParam}`,
    );
    win && win.focus();
  };

  return (
    <Box
      sx={{
        width: 400,
        height: "auto",
        backgroundColor: popupBgColor,
        opacity: 1,
        borderRadius: 5,
        padding: 2,
      }}
    >
      <Grid container>
        <Grid item sm={11} sx={{ marginBottom: "10px" }}>
          <Typography
            variant="caption"
            color={fontColor}
            fontWeight={900}
            fontFamily={"Sohne"}
            fontSize={20}
            lineHeight={1.27}
            sx={{ overflowWrap: "break-word" }}
          >
            {nodeData.name}
          </Typography>
        </Grid>
        <Grid item sm={1} sx={{ paddingTop: 0.4 }}>
          <Cross
            onClick={handleClose}
            style={{ cursor: "pointer" }}
            stroke={fontColor}
          />
        </Grid>
        <Grid item sm={3}>
          <NodeType>Name</NodeType>
        </Grid>
        <Grid item sm={9}>
          <NodeDescription>{name}</NodeDescription>
        </Grid>
        <Grid item sm={3}>
          <NodeType>Type</NodeType>
        </Grid>
        <Grid item sm={9}>
          <NodeDescription>{type}</NodeDescription>
        </Grid>
        {role && (
          <>
            <Grid item sm={3}>
              <NodeType>Role</NodeType>
            </Grid>
            <Grid item sm={9}>
              <NodeDescription>{role}</NodeDescription>
            </Grid>
          </>
        )}
        {country && (
          <>
            <Grid item sm={3}>
              <NodeType>Country</NodeType>
            </Grid>
            <Grid item sm={9}>
              <NodeDescription>{country}</NodeDescription>
            </Grid>
          </>
        )}
        {address && (
          <>
            <Grid item sm={3}>
              <NodeType>Address</NodeType>
            </Grid>
            <Grid item sm={9}>
              <NodeDescription>{address}</NodeDescription>
            </Grid>
          </>
        )}
        {ownership && (
          <>
            <Grid item sm={3}>
              <NodeType>Ownership</NodeType>
            </Grid>
            <Grid item sm={9}>
              <NodeDescription>{ownership}</NodeDescription>
            </Grid>
          </>
        )}
        {companyCode &&
          jurisdiction &&
          (rfncType === "NO_SHAREHOLDERS" ||
            rfncType == null ||
            profileLink) && (
            <>
              <Grid item sm={3}>
                <NodeType>Profile</NodeType>
              </Grid>
              <Grid item sm={9}>
                <NodeDescription
                  onClick={() => {
                    openEnhancedProfileTab();
                  }}
                >
                  <PopupButton
                    sx={{
                      padding: "1px",
                      backgroundColor: primary,
                      "&:hover": {
                        backgroundColor: primary,
                      },
                      color: white,
                    }}
                  >
                    View
                  </PopupButton>
                </NodeDescription>
              </Grid>
            </>
          )}
        {rfncDetails && (
          <>
            <Grid item sm={3}>
              <NodeType>Details</NodeType>
            </Grid>
            <Grid item sm={9}>
              <NodeDescription>{rfncDetails}</NodeDescription>
            </Grid>
          </>
        )}
        {rfncDetails && rfncType == "UNSUPPORTED_JURISDICTION" && (
          <>
            <Grid item sm={3}>
              <CandidateDescription
                sx={{ fontWeight: 900, fontSize: "0.8rem" }}
              >
                Search
              </CandidateDescription>
            </Grid>
            <Grid item sm={9}>
              <CandidateDescription
                onClick={() => {
                  const iso = rfncDetails.substring(
                    rfncDetails.indexOf("(") + 1,
                    rfncDetails.indexOf(")"),
                  );
                  const searchLinkParams = qs.stringify(
                    {
                      jurisdiction: iso,
                      search: name,
                      searchType: "name",
                    },
                    { skipNull: true },
                  );
                  const win = window.open(`/search?${searchLinkParams}`);
                  win && win.focus();
                }}
              >
                <PopupButton
                  sx={{
                    padding: "1px",
                    backgroundColor: primary,
                    "&:hover": {
                      backgroundColor: primary,
                    },
                    color: white,
                  }}
                >
                  View
                </PopupButton>
              </CandidateDescription>
            </Grid>
          </>
        )}
        {candidates && candidates.length > 0 && (
          <>
            <Grid container>
              <Grid item sm={12}>
                <NodeType>Candidates</NodeType>
              </Grid>
            </Grid>
            <Box
              sx={{
                maxHeight: 150,
                overflow: "auto",
              }}
            >
              {candidates.map((candidate, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      padding: 0.5,
                      background: lightest,
                      borderRadius: 2,
                      marginBottom: 0.5,
                    }}
                  >
                    <Grid container>
                      <Grid item sm={3}>
                        <CandidateDescription
                          sx={{ fontWeight: 900, fontSize: "0.8rem" }}
                        >
                          Name
                        </CandidateDescription>
                      </Grid>
                      <Grid item sm={9}>
                        <CandidateDescription>
                          {candidate.companyName}
                        </CandidateDescription>
                      </Grid>
                      <Grid item sm={3}>
                        <CandidateDescription
                          sx={{ fontWeight: 900, fontSize: "0.8rem" }}
                        >
                          Code
                        </CandidateDescription>
                      </Grid>
                      <Grid item sm={9}>
                        <CandidateDescription>
                          {candidate.companyId}
                        </CandidateDescription>
                      </Grid>
                      <Grid item sm={3}>
                        <CandidateDescription
                          sx={{ fontWeight: 900, fontSize: "0.8rem" }}
                        >
                          Profile
                        </CandidateDescription>
                      </Grid>
                      <Grid item sm={9}>
                        <CandidateDescription
                          onClick={() => {
                            const candidateJurisdiction = candidate.countryIso;
                            const candidateLinkParams = qs.stringify(
                              {
                                companyCode: candidate.companyId,
                                companyName:
                                  candidate.companyName?.toUpperCase(),
                                ...(!isNullOrWhitespace(
                                  candidate.registrationAuthority,
                                ) && {
                                  registrationAuthorityCode:
                                    candidate.registrationAuthority,
                                }),
                              },
                              { skipNull: true },
                            );
                            const win = window.open(
                              `/company?jurisdiction=${candidateJurisdiction}&${candidateLinkParams}`,
                            );
                            win && win.focus();
                          }}
                        >
                          <PopupButton
                            sx={{
                              padding: "1px",
                              backgroundColor: popupBgColor,
                              "&:hover": {
                                backgroundColor: popupBgColor,
                              },
                              color: primary,
                            }}
                          >
                            View
                          </PopupButton>
                        </CandidateDescription>
                      </Grid>
                      <Grid item sm={3}>
                        <CandidateDescription
                          sx={{ fontWeight: 900, fontSize: "0.8rem" }}
                        >
                          Country
                        </CandidateDescription>
                      </Grid>
                      <Grid item sm={9}>
                        <CandidateDescription>
                          {getCountryName(candidate.countryIso)}
                        </CandidateDescription>
                      </Grid>
                      <Grid item sm={3}>
                        <CandidateDescription
                          sx={{ fontWeight: 900, fontSize: "0.8rem" }}
                        >
                          Address
                        </CandidateDescription>
                      </Grid>
                      <Grid item sm={9}>
                        <CandidateDescription>
                          {candidate.address}
                        </CandidateDescription>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
        {profileLink && (
          <Grid item sm={7} sx={{ marginTop: 1 }}>
            <PopupButton
              onClick={() => {
                const win = window.open(profileLink, "_blank");
                win && win.focus();
              }}
            >
              Download
            </PopupButton>
          </Grid>
        )}
        {shareholdings && type == "Person" && (
          <>
            <Grid container>
              <Grid item sm={12} sx={{ paddingBottom: 0.5 }}>
                <NodeType>Shareholdings</NodeType>
              </Grid>
            </Grid>
            <Box
              sx={{
                maxHeight: 300,
                overflow: "auto",
                width: "100%",
              }}
            >
              {shareholdings.shareholdings.map((shareholding, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      padding: 1,
                      background: lightest,
                      borderRadius: 2,
                      marginBottom: 0.5,
                      "box-shadow": "0px 2px 4px 0px #00000040",
                    }}
                  >
                    <Grid container>
                      <Grid item sm={12}>
                        <CandidateDescription
                          sx={{ fontWeight: 900, fontSize: "0.8rem" }}
                        >
                          {shareholding.parentNode}{" "}
                          {formatOwnership(shareholding.percentage)}%
                        </CandidateDescription>
                      </Grid>

                      <Grid item sm={12}>
                        {shareholding.shareholding
                          .sort(
                            (a, b) =>
                              Number(a.isJointlyHeld) - Number(b.isJointlyHeld),
                          )
                          .map((s, i) => {
                            return (
                              <Box
                                key={i}
                                sx={{
                                  paddingTop: 0.5,
                                  maxHeight: 150,
                                  overflow: "auto",
                                  marginBottom: 0.5,
                                }}
                              >
                                <Grid container>
                                  {jointHoldingGroups.find(
                                    (j) =>
                                      j.jointHoldingGroupId ==
                                      s.jointHoldingGroupId,
                                  ) ? (
                                    <>
                                      <Grid item sm={4}>
                                        <CandidateDescription
                                          sx={{
                                            fontWeight: 900,
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          Percentage
                                        </CandidateDescription>
                                      </Grid>
                                      <Grid item sm={8}>
                                        <CandidateDescription>
                                          {formatOwnership(s.percentage) + "%"}
                                        </CandidateDescription>
                                      </Grid>
                                      <Grid item sm={4}>
                                        <CandidateDescription
                                          sx={{
                                            fontWeight: 900,
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          Joint Ownership
                                        </CandidateDescription>
                                      </Grid>
                                      <Grid item sm={8}>
                                        <CandidateDescription>
                                          {jointHoldingGroups
                                            .find(
                                              (j) =>
                                                j.jointHoldingGroupId ==
                                                s.jointHoldingGroupId,
                                            )
                                            ?.shareholders.filter(
                                              (s) => s != nodeData.name,
                                            )
                                            .map((s) => {
                                              return (
                                                <CandidateDescription>
                                                  {s}
                                                  {"\n"}
                                                </CandidateDescription>
                                              );
                                            })}
                                        </CandidateDescription>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid item sm={4}>
                                        <CandidateDescription
                                          sx={{
                                            fontWeight: 900,
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          Percentage
                                        </CandidateDescription>
                                      </Grid>
                                      <Grid item sm={8}>
                                        <CandidateDescription>
                                          {formatOwnership(s.percentage) + "%"}
                                        </CandidateDescription>
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </Box>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default NodePopup;
