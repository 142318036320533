import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { primary } from "pages/variables";
import styled from "styled-components";

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: primary,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: primary,
    padding: "10px",
  },
}));
