import React, { ReactNode, useState } from "react";
import {
  Alert,
  Box,
  Drawer,
  Link,
  Portal,
  Snackbar,
  Theme,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import LogoImage from "assets/logo.svg";
import { useCreditContext } from "contexts/CreditContext";
import StyledButton from "components/Button";
import Settings from "../Settings";
import { useUserContext } from "contexts/UserContext";
import { Link as RouterLink } from "react-router-dom";
import { Userpilot } from "userpilot";
const Logo = styled("img")({
  position: "relative",
  top: "52%",
  transform: "translateY(-50%)",
});

type HeaderStripProps = {
  children: ReactNode;
};

const HeaderStrip = ({ children }: HeaderStripProps): JSX.Element => {
  return (
    <Box
      sx={{
        background: "white",
        display: "flex",
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
        justifyContent: "space-between",
        width: "100%",
        height: "60px",
      }}
    >
      {children}
    </Box>
  );
};

const HeaderRight = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(3),
      })}
    >
      {children}
    </Box>
  );
};

const LogoLink = (): JSX.Element => {
  return (
    <Link component={RouterLink} to="/home" underline="none">
      <Logo src={LogoImage} />
    </Link>
  );
};

const Header = (): JSX.Element => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [passwordMessage, setPasswordMessage] = useState<string>("");
  const [isSnackbarError, setSnackbarError] = useState<boolean>(false);

  const [settingsShown, toggleSettings] = useState<boolean>(false);
  const { credits } = useCreditContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { isAdmin } = useUserContext();
  const onPasswordReset = (str: string, isError: boolean) => {
    setSnackbarOpen(true);
    setPasswordMessage(str);
    setSnackbarError(isError);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  if (isMobile) {
    return (
      <Box>
        <HeaderStrip>
          <LogoLink />
          <HeaderRight>
            <StyledButton size="small">Settings</StyledButton>
          </HeaderRight>
        </HeaderStrip>
        <HeaderStrip>
          {credits && credits.remainingCredits && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="caption" color="grey.500" fontWeight={500}>
                {credits.remainingCredits} Credits Remaining
              </Typography>
            </Box>
          )}

          <HeaderRight>
            {isAdmin && (
              <Link
                component={RouterLink}
                to="/admin"
                underline="none"
                sx={{ marginLeft: 0 }}
              >
                <Typography
                  variant="caption"
                  color="primary.dark"
                  fontWeight={500}
                >
                  Admin
                </Typography>
              </Link>
            )}

            <Link
              component={RouterLink}
              to="/order-history"
              underline="none"
              sx={{ marginLeft: 0 }}
            >
              <Typography
                variant="caption"
                color="primary.dark"
                fontWeight={500}
              >
                Order History
              </Typography>
            </Link>
          </HeaderRight>
        </HeaderStrip>
      </Box>
    );
  }

  return (
    <HeaderStrip>
      <Link
        component={RouterLink}
        to="/home"
        underline="none"
        onClick={() => Userpilot.track("Home Button Icon Clicked")}
      >
        <Logo src={LogoImage} />
      </Link>
      <HeaderRight>
        {credits && credits.remainingCredits != undefined && (
          <Typography variant="caption" color="grey.500" fontWeight={500}>
            {credits.remainingCredits} Credits Remaining
          </Typography>
        )}

        {isAdmin && (
          <Link
            component={RouterLink}
            to="/admin"
            underline="none"
            sx={{ marginLeft: 0 }}
          >
            <Typography variant="caption" color="primary.dark" fontWeight={500}>
              Admin
            </Typography>
          </Link>
        )}
        <Link
          component={RouterLink}
          to="/order-history"
          underline="none"
          sx={{ marginLeft: 0 }}
        >
          <Typography variant="caption" color="primary.dark" fontWeight={500}>
            Order History
          </Typography>
        </Link>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.kyckr.com/hc/en-ie"
          style={{ textDecoration: "none" }}
          onClick={() => Userpilot.track("Helpdesk Button Clicked")}
        >
          <Typography variant="caption" color="primary.dark" fontWeight={500}>
            Helpdesk
          </Typography>
        </a>
        <StyledButton
          size="small"
          onClick={() => {
            Userpilot.track("Settings Button Clicked");
            toggleSettings(!settingsShown);
          }}
        >
          Settings
        </StyledButton>
        <Drawer
          anchor="right"
          open={settingsShown}
          onClose={() => toggleSettings(false)}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "primary.light",
            },
          }}
        >
          <Settings
            onClose={() => toggleSettings(false)}
            toastHandler={onPasswordReset}
          />
        </Drawer>
      </HeaderRight>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={isSnackbarError ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {passwordMessage}
          </Alert>
        </Snackbar>
      </Portal>
    </HeaderStrip>
  );
};

export default Header;
