export interface IOwnershipTreeResponse {
  ownershipTree: IOwnershipTree;
  responseCode: string;
}

export interface IOwnershipTree {
  id: string;
  name: string;
  address: string;
  nodes: INode[];
  ultimateBeneficialOwners: IUltimateBeneficialOwner[] | null;
  status: string;
  uboThreshold: number;
  maxCreditCost: number;
  maxLayers: number | null;
  dateCreated: string;
  totalCreditsSpent: number;
  unwrapFee: number;
  retrievalLocation: string;
}

export interface IUltimateBeneficialOwner {
  id: string;
  name: string;
  nameInEnglish: string;
  percentage: string;
  entityType: string;
}

export interface INode {
  level: string;
  entity: IEntity;
  edges: IEdge[];
  rollupPercentage: number | null;
  isDirector: boolean;
}

export interface IEntity {
  id: string;
  type: string;
  name: string;
  nameInEnglish: string;
  countryISO: string;
  companyCode: string;
  reasonForNonContinuation: IReasonForNonContinuation | null;
  links: ILinks | null;
  registrationAuthority: string | null;
}

export interface IReasonForNonContinuation {
  type: string;
  details: string;
  candidates: ICandidate[] | null;
}

export interface ICandidate {
  address: string | null;
  companyId: string | null;
  companyName: string | null;
  countryIso: string | null;
  registrationAuthority: string | null;
}
export interface ILinks {
  enhancedProfile: string;
}

export interface IEdge {
  nodeId: string;
  type: string;
  percentage: number | null;
  role: string | null;
  isCircular: boolean | null;
  shareholdings: IShareholding[];
}

export interface IShareholding {
  isJointlyHeld: boolean;
  jointHoldingGroupId: string | null;
  percentage: number;
}

export enum GraphOrientation {
  UpDown = "TB",
  DownUp = "BT",
  LeftRight = "LR",
  RightLeft = "RL",
}

import { DagreLayoutOptions } from "cytoscape-dagre";
// Make a new interface for layout options. Unsure what is supported / documented
// Current implementation doesn't match the docs & types
export interface LayoutOptions extends Omit<DagreLayoutOptions, "rankDir"> {
  rankDir: GraphOrientation;
  directed: boolean;
  maximal: boolean;
}
