export enum TabPages {
  FILINGS = "filings",
  UBO = "ubo-verify",
  OFFICIALS = "company-officials",
  OWNERSHIP = "ownership",
  SIGNIFICANT_CONTROL = "persons-of-significant-control",
  OFFICERS = "officers",
}

export enum Products {
  UBOVerify_Product_Order = "UBOVerify_Product_Order",
  UBOVerify_Unwrap = "UBOVerify_Unwrap",
  GBR_CompanyOnboarding_CompanyProfile = "GBR_CompanyOnboarding_CompanyProfile",
}

export enum UboStatus {
  SUCCESS = "SUCCESS",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum OrderStatus {
  Pending = "Pending",
  Failed = "Failed",
  Completed = "Completed",
}

export enum EntityType {
  COMPANY = "COMPANY",
  PERSON = "PERSON",
  REPRESENTATIVE = "REPRESENTATIVE",
}
