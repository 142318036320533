import React, { useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useCompanyContext } from "contexts/CompanyContext";
import { Filing as FilingType } from "contexts/CompanyContext/types";
import Filing from "./Filing";
import useTitleSearch from "hooks/useTitleSearch";
import Search from "components/Search";
import ErrorMessage from "components/ErrorMessage";
import { manualFilingRetrievalJurisdictions } from "components/CompanySearch/specialJurisdictions";
import {
  secondary_mid,
  secondary_dark,
  white,
  primary_dark,
} from "pages/variables";
import FilingsButton from "components/Button/Button";
import { Userpilot } from "userpilot";

const Filings: React.FC = () => {
  const { filings, filingsLoading, jurisdiction, setLoadFilingsManually } =
    useCompanyContext();

  const [searchFilter, setSearchFilter] = useState<string>("");
  const onSearchInput = (input: string) => {
    setSearchFilter(input);
  };

  const filteredResults = useTitleSearch<FilingType>(
    searchFilter,
    filings ?? [],
  );

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      data-testid="filings-root"
    >
      {filingsLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "200px",
          }}
          data-testid="filings-loading"
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <CircularProgress
              size="1em"
              color="success"
              variant="indeterminate"
            />
            <Typography sx={{ fontSize: "12px" }}>
              We're working on your request
            </Typography>
          </Box>
        </Box>
      ) : !manualFilingRetrievalJurisdictions.includes(jurisdiction) ? (
        <Box>
          <Box
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.primary.light}`,
              marginBottom: 1,
            })}
          >
            <Search
              textInputProps={{
                onFocus: () =>
                  Userpilot.track("Company Filings Search Focused"),
                placeholder: "Search filings",
              }}
              onSearchInput={onSearchInput}
              hasClearInputButton
            />
          </Box>
          {filteredResults.length !== 0 ? (
            filteredResults.map((filing, index) => (
              <Filing data={filing} key={index} />
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "200px",
              }}
            >
              {searchFilter !== "" && (
                <ErrorMessage message="No filings meet your search criteria, please try another search term" />
              )}
              {searchFilter === "" && (
                <ErrorMessage message="No filings available" />
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Box
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.primary.light}`,
              marginBottom: 1,
            })}
          >
            <Search
              textInputProps={{
                onFocus: () =>
                  Userpilot.track("Company Filings Search Focused"),
                placeholder: "Search filings",
              }}
              onSearchInput={onSearchInput}
              hasClearInputButton
            />
          </Box>
          {filteredResults.length !== 0 ? (
            filteredResults.map((filing, index) => (
              <Filing data={filing} key={index} />
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  width: "100%",
                  backgroundColor: "primary.light",
                  marginTop: "100px",
                  padding: "20px",
                  borderRadius: "10px",
                  [theme.breakpoints.down("sm")]: {
                    gridTemplateColumns: "1fr",
                    padding: "15px 0px 0px 15px",
                    marginTop: "20px",
                    height: "100%",
                  },
                })}
              >
                <Grid container direction="column" spacing={1}>
                  <Grid item display="flex">
                    <Typography
                      color={"primary.dark"}
                      fontSize="1.5rem"
                      fontWeight={700}
                      variant="h4"
                      fontFamily="Sohne"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      Retrieve company filings
                    </Typography>
                  </Grid>
                  <Grid item display="flex">
                    <Typography
                      color={"primary.dark"}
                      fontSize="1.0rem"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      Click here to load filings directly from the registry.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    sx={(theme) => ({
                      marginTop: "15px",
                      [theme.breakpoints.down("sm")]: {
                        marginTop: "10px",
                      },
                    })}
                  >
                    <FilingsButton
                      sx={{
                        width: "220px",
                        backgroundColor: secondary_mid,
                        ":hover": {
                          backgroundColor: secondary_dark,
                          color: white,
                        },
                        color: primary_dark,
                        fontWeight: 700,
                        fontSize: "1.0rem",
                        padding: "12px",
                      }}
                      onClick={() => {
                        setLoadFilingsManually(true);
                      }}
                    >
                      Get started
                    </FilingsButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Filings;
