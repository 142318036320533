import { SxProps } from "@mui/material/styles";

type Props = {
  width?: string;
  height?: string;
  transform?: string;
  maskBgColor?: string;
  sx?: SxProps;
};

const RepresentativesIcon: React.FC<Props> = ({
  width,
  height,
  transform,
  maskBgColor,
}): JSX.Element => {
  const defaultTransform = "scale(0.8) translate(5,3.75)";
  return (
    <svg
      width={width ?? 35}
      height={height ?? 35}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="representativesMask">
        <rect width="100%" height="100%" fill="white" />
        <path
          d="M7.5 5.75H3C2.44772 5.75 2 6.19771 2 6.75V15.75C2 16.3023 2.44772 16.75 3 16.75H17C17.5523 16.75 18 16.3023 18 15.75V6.75C18 6.19771 17.5523 5.75 17 5.75H12.5M7.5 5.75H12.5M7.5 5.75C7.5 4.36929 8.61929 3.25 10 3.25C11.3807 3.25 12.5 4.36929 12.5 5.75"
          stroke="black"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform={transform ?? defaultTransform}
        />
      </mask>
      <circle
        cx="12"
        cy="12"
        r="12"
        fill={maskBgColor}
        mask="url(#representativesMask)"
      />
    </svg>
  );
};

export default RepresentativesIcon;
