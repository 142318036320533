import { yupResolver } from "@hookform/resolvers/yup";
import { Clear } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { useEditUser, Inputs } from "hooks/useEditUser";
import { lightest } from "../../variables";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextInput } from "components/Form";
import * as yup from "yup";
import RadioButtonGroupInput from "components/Form/RadioButtonGroupInput";
import EditUserButton from "components/Button";
import ErrorLabel from "components/ErrorMessage/ErrorLabel";
import { Toast } from "components/Toast/Toast";
import { User } from "../../../types";

type Props = {
  onClose: () => void;
  userDetails: User | undefined;
  setUserDetails: Dispatch<SetStateAction<User | undefined>>;
  toastHandler: (v: string, f: boolean, isEditAction: boolean) => void;
};

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required("Name is required"),
    lastName: yup.string().required("Surname is required"),
    role: yup.string().required("Role is required"),
  })
  .required();

const EditUser: React.FC<Props> = ({
  onClose,
  userDetails,
  setUserDetails,
  toastHandler,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const { mutateAsync: editUser, isLoading } = useEditUser(toastHandler);
  const [openToast, setOpenToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [role, setRole] = useState<string>(userDetails?.role || "User");
  const [firstName] = useState<string>(userDetails?.firstName || "");
  const [lastName] = useState<string>(userDetails?.lastName || "");

  useEffect(() => {
    setValue("role", role);
    // TODO:: bad use of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  useEffect(() => {
    setValue("firstName", firstName);
    setValue("lastName", lastName);
    // TODO:: bad use of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName]);

  const onSubmit = async (data: Inputs) => {
    const user = {
      ...userDetails,
      firstName: data.firstName,
      lastName: data.lastName,
      role: data.role,
    } as User;

    try {
      setOpenToast;
      setUserDetails(user);
      await editUser(user);
      onClose();
      // TODO:: Type for error
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.response?.body) {
        try {
          const errorData = await error.response.json();
          setErrorMessage(errorData.message);
          return;
        } catch {}
      }
      setErrorMessage("Generic error occurred.");
    }
  };

  const onError = () => {
    console.error(errors);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Box p="40px 20px 0px 32px" width="25vw">
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize="1.125rem"
            fontFamily="Sohne"
            color="primary.dark"
          >
            Edit User
          </Typography>
          <IconButton
            aria-label="close-settings"
            sx={{ alignSelf: "center" }}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <Clear sx={{ color: "primary.dark" }} fontSize="small" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            marginTop: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <TextInput<Inputs>
              name="firstName"
              label="Name"
              defaultValue={firstName}
              sx={{
                label: {
                  paddingLeft: "10px",
                  color: "rgb(2, 42, 55)",
                  zIndex: 1,
                },
                input: {
                  paddingLeft: "10px",
                  border: "1px solid rgb(211, 217, 219)",
                  borderRadius: "8px",
                  backgroundColor: lightest,
                },
              }}
              control={control}
            />
            <TextInput<Inputs>
              name="lastName"
              label="Surname"
              defaultValue={lastName}
              sx={{
                label: {
                  paddingLeft: "10px",
                  color: "rgb(2, 42, 55)",
                  zIndex: 1,
                },
                input: {
                  paddingLeft: "10px",
                  border: "1px solid rgb(211, 217, 219)",
                  borderRadius: "8px",
                  backgroundColor: lightest,
                },
              }}
              control={control}
            />
            <RadioButtonGroupInput
              name="role"
              control={control}
              label="Role"
              data={[
                { id: "Standard", value: "User" },
                { id: "OrgAdmin", value: "Admin" },
              ]}
              value={role}
              setValue={setRole}
              sx={{
                margin: "16px",
                label: {
                  paddingLeft: "10px",
                  color: "rgb(2, 42, 55)",
                },
              }}
            />
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "inherit", margin: "16px" }}
          >
            <EditUserButton
              size="small"
              sx={{ height: "40px" }}
              startIcon={
                isLoading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : null
              }
              type="submit"
              disabled={isLoading}
            >
              Save Changes
            </EditUserButton>
          </Box>
          {errorMessage && (
            <ErrorLabel errorMessage={errorMessage}></ErrorLabel>
          )}
        </Box>
      </Box>
      {openToast && (
        <Toast
          message="Unable to edit account."
          isOpen={openToast}
          duration={4000}
          onClose={() => {
            setOpenToast(false);
          }}
        />
      )}
    </form>
  );
};

export default EditUser;
