import { useCallback, useReducer } from "react";

interface IVisitedTab {
  uboVisited: boolean;
  companyOfficialsVisited: boolean;
  officersVisited: boolean;
  shareholdersVisited: boolean;
  pscVisited: boolean;
}

export type IActionType =
  | "ubo-verify"
  | "company-officials"
  | "officers"
  | "ownership"
  | "persons-of-significant-control";

type IAction = { type: IActionType };

const initialState: IVisitedTab = {
  uboVisited: false,
  companyOfficialsVisited: false,
  officersVisited: false,
  shareholdersVisited: false,
  pscVisited: false,
};

function reducer(state: IVisitedTab, action: IAction): IVisitedTab {
  switch (action.type) {
    case "ubo-verify":
      return { ...state, uboVisited: true };
    case "company-officials":
      return { ...state, companyOfficialsVisited: true };
    case "officers":
      return { ...state, officersVisited: true };
    case "ownership":
      return { ...state, shareholdersVisited: true };
    case "persons-of-significant-control":
      return { ...state, pscVisited: true };
    default:
      throw new Error();
  }
}

const useVisitedTab = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setVisited = useCallback((tab: IActionType) => {
    dispatch({ type: tab });
  }, []);

  return {
    ...state,
    setVisited,
  };
};

export default useVisitedTab;
