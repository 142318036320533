import { Box } from "@mui/material";
import { useCompanyContext } from "contexts/CompanyContext";
import PersonOfSignificantControl from "./PersonOfSignificantControl";
import usePersonOfSignificantControlSort from "./usePersonOfSignificantControlSort";
import SortSelect from "components/SortSelect";
import { useForm, useWatch } from "react-hook-form";

type FormInputs = {
  sortBy: { label: string; data: string };
  isDescending: boolean;
};

function formatDate(
  dobDay: number,
  dobMonth: number,
  dobYear: number,
): Date | null {
  if (dobDay === 0 || dobMonth === 0 || dobYear === 0) {
    return null;
  }

  return new Date(`${dobDay}/${dobMonth}/${dobYear}`);
}

const PersonsOfSignificantControl = (): JSX.Element => {
  const { companyProfile } = useCompanyContext();

  const sortOptions = [
    { label: "Name", data: "name" },
    { label: "Nationality", data: "nationality" },
    { label: "Country of residence", data: "country-of-residence" },
  ];

  const { control, setValue } = useForm<FormInputs>({
    defaultValues: {
      sortBy: sortOptions[0],
      isDescending: true,
    },
  });

  const sorting = useWatch({ control, name: "sortBy" });
  const isDescending = useWatch({ control, name: "isDescending" });

  const personsOfSignificantControl =
    companyProfile.directorAndShareDetails?.personsOfSignificantControl ?? [];
  const personsOfSignificantControlMapped = personsOfSignificantControl.map(
    (person) => {
      return {
        name: person.name,
        addressInOneLine: person.address,
        nationality: person.nationality,
        residency: person.countryOfResidence,
        dateOfBirth: person.dobDay //check for valid values before format. Would fail for a 0!
          ? formatDate(person.dobDay, person.dobMonth, person.dobYear)
          : null,
        dateNotified: person.notifiedOn,
        dateCeased: person.ceasedOn,
        controls: person.natureOfControl,
      };
    },
  );

  const sortedPersonsOfSignificantControl = usePersonOfSignificantControlSort(
    personsOfSignificantControlMapped,
    sorting.data,
    isDescending,
  );

  const onAscDescHandler = () => {
    setValue("isDescending", !isDescending);
  };

  return (
    <Box>
      <SortSelect
        control={control}
        options={sortOptions}
        onAscDescHandler={onAscDescHandler}
        isDescending={isDescending}
        inputWidth="11rem"
      />
      {sortedPersonsOfSignificantControl.map(
        (
          {
            name,
            addressInOneLine,
            nationality,
            residency,
            dateOfBirth,
            dateNotified,
            dateCeased,
            controls,
          },
          index,
        ) => (
          <PersonOfSignificantControl
            key={index}
            name={name}
            addressInOneLine={addressInOneLine}
            nationality={nationality}
            residency={residency}
            dateOfBirth={dateOfBirth}
            dateNotified={dateNotified}
            dateCeased={dateCeased}
            controls={controls}
          />
        ),
      )}
    </Box>
  );
};

export default PersonsOfSignificantControl;
