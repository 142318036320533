import { Box, Typography } from "@mui/material";
import { dark } from "../variables";

type Props = {
  label: string;
  data?: string;
};

export default function LabeledData({ label, data }: Props): JSX.Element {
  if (!data) return <></>;

  return (
    <Box>
      <Typography fontSize="0.75rem" color="primary.dark">
        {label}
      </Typography>
      <Typography fontSize="0.75rem" color={dark}>
        {data}
      </Typography>
    </Box>
  );
}
