import React, { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export type Popper = {
  // TODO:: This shoul be using Generics, not Any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any;
  destroy: () => void;
  forceUpdate: () => void;
  // TODO:: This shoul be using Generics, not Any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update: () => Promise<Partial<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOptions: (setOptionsAction: any) => Promise<Partial<any>>;
};

interface Props {
  children?: React.ReactNode;
}

type NodePopupContextValue = {
  popperCollection: Record<string, Popper | null>;
  clearNodePopups: () => void;
};

export const NodePopupContext = React.createContext<
  NodePopupContextValue | undefined
>(undefined);

export function useNodePopupContext(): NodePopupContextValue {
  const context = useContext(NodePopupContext);
  if (context === undefined) {
    throw new Error("useNodePopup must be used within a NodePopupContext");
  }

  return context;
}

export const NodePopupContextProvider: React.FC<Props> = ({
  children,
}: Props) => {
  const popperCollection = useRef<Record<string, Popper | null>>({});
  const clearNodePopups = () => {
    if (popperCollection.current) {
      for (const key in popperCollection.current) {
        const value = popperCollection.current[key] as Popper;
        if (value) {
          value.destroy();
          popperCollection.current[key] = null;
        }
      }
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location && location.pathname != "/company") {
      clearNodePopups();
    }
  }, [location]);

  return (
    <NodePopupContext.Provider
      value={{
        popperCollection: popperCollection.current,
        clearNodePopups: clearNodePopups,
      }}
    >
      {children}
    </NodePopupContext.Provider>
  );
};
