import {
  AccessTime,
  CalendarMonth,
  LocationOnOutlined,
  LocationCityOutlined,
} from "@mui/icons-material";
import { Box, styled, Typography } from "@mui/material";
import { dark, mid } from "pages/variables";
import Twemoji from "react-twemoji";
import { toDisplayDate } from "utils/dates";

const EmojiFlag = styled(Twemoji)`
  .emoji {
    width: 25px;
  }
`;

EmojiFlag.defaultProps = {
  options: {
    base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
  },
};

export type PersonOfSignificantControl = {
  name: string;
  addressInOneLine: string;
  nationality: string;
  residency: string;
  dateOfBirth: Date | null;
  dateNotified: string;
  dateCeased: string;
  controls: string[];
};

export default function PersonOfSignificantControl({
  name,
  addressInOneLine,
  nationality,
  residency,
  dateOfBirth,
  dateNotified,
  dateCeased,
  controls,
}: PersonOfSignificantControl): JSX.Element {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: 1,
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "start",
        }}
      >
        <Typography fontSize="1.125rem" fontFamily="Sohne" color="primary.dark">
          {name}
        </Typography>
        <Box
          sx={() => ({
            display: "flex",
            gridTemplateColumns: "auto auto auto",
            alignSelf: "start",
            gridColumnGap: 10,
            marginTop: 1,
            gap: 2,
          })}
        >
          {addressInOneLine && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <LocationOnOutlined fontSize="small" sx={{ color: mid }} />
              <Typography variant="caption" color={dark}>
                {addressInOneLine}
              </Typography>
            </Box>
          )}
          {nationality && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {nationality === "British" && <EmojiFlag>{"🇬🇧"}</EmojiFlag>}
              <Typography variant="caption" color={dark}>
                {nationality}
              </Typography>
            </Box>
          )}
          {dateOfBirth && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <CalendarMonth fontSize="small" sx={{ color: mid }} />
              <Typography variant="caption" color={dark}>
                {toDisplayDate(dateOfBirth)}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={() => ({
            display: "flex",
            gridTemplateColumns: "auto auto auto",
            alignSelf: "start",
            gridColumnGap: 10,
            marginTop: 1,
            gap: 2,
          })}
        >
          {residency && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <LocationCityOutlined fontSize="small" sx={{ color: mid }} />
              <Typography variant="caption" color={dark}>
                {residency}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={() => ({
            display: "flex",
            gridTemplateColumns: "auto auto auto",
            alignSelf: "start",
            gridColumnGap: 10,
            marginTop: 1,
            gap: 2,
          })}
        >
          {dateNotified && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <AccessTime fontSize="small" sx={{ color: mid }} />
              <Typography variant="caption" color={dark}>
                Notified on {dateNotified}
              </Typography>
            </Box>
          )}
          {dateCeased && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <CalendarMonth fontSize="small" sx={{ color: mid }} />
              <Typography variant="caption" color={dark}>
                Ceased on {toDisplayDate(dateCeased)}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={() => ({
            display: "grid",
            alignSelf: "start",
            gridColumnGap: 10,
            marginTop: 1,
            marginBottom: 1,
            gap: 2,
          })}
        >
          {controls.map((control) => (
            <Typography key={control} variant="body2">
              {control}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
