import { useMemo } from "react";

type HasTitle = {
  title: string;
};

const useTitleSearch = <T extends HasTitle>(
  searchFilter: string,
  filings: T[],
) => {
  const filteredResults = useMemo(
    () =>
      filings.filter(
        (f) =>
          searchFilter.toLowerCase() === "" ||
          f.title.toLowerCase().includes(searchFilter.toLowerCase()),
      ),
    [searchFilter, filings],
  );

  return filteredResults;
};

export default useTitleSearch;
