import { SxProps } from "@mui/material/styles";

type Props = {
  width?: string;
  height?: string;
  transform?: string;
  maskBgColor?: string;
  sx?: SxProps;
};

const PersonIcon: React.FC<Props> = ({
  width,
  height,
  transform,
  maskBgColor,
}): JSX.Element => {
  const defaultTransform = "scale(0.9) translate(3.25,2.75)";
  return (
    <svg
      width={width ?? 35}
      height={height ?? 35}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="personMask">
        <rect width="100%" height="100%" fill="white" />
        <g
          clipPath="url(#clip0_3434_4543)"
          transform={transform ?? defaultTransform}
        >
          <mask id="path-1-inside-1_3434_4543" fill="white">
            <path d="M16.4815 17C16.7678 17 17.0015 16.7676 16.9853 16.4817C16.8756 14.5492 16.1552 12.7208 14.9497 11.3431C13.637 9.84285 11.8565 9 10 9C8.14349 9 6.36301 9.84285 5.05025 11.3431C3.8448 12.7208 3.12441 14.5492 3.01469 16.4817C2.99846 16.7676 3.23215 17 3.51852 17V17C3.80489 17 4.03524 16.7676 4.05429 16.4818C4.16219 14.864 4.77264 13.3365 5.78355 12.1812C6.90183 10.9032 8.41853 10.1852 10 10.1852C11.5815 10.1852 13.0982 10.9032 14.2164 12.1812C15.2274 13.3365 15.8378 14.864 15.9457 16.4818C15.9648 16.7676 16.1951 17 16.4815 17V17Z" />
          </mask>
          <path
            d="M16.4815 17C16.7678 17 17.0015 16.7676 16.9853 16.4817C16.8756 14.5492 16.1552 12.7208 14.9497 11.3431C13.637 9.84285 11.8565 9 10 9C8.14349 9 6.36301 9.84285 5.05025 11.3431C3.8448 12.7208 3.12441 14.5492 3.01469 16.4817C2.99846 16.7676 3.23215 17 3.51852 17V17C3.80489 17 4.03524 16.7676 4.05429 16.4818C4.16219 14.864 4.77264 13.3365 5.78355 12.1812C6.90183 10.9032 8.41853 10.1852 10 10.1852C11.5815 10.1852 13.0982 10.9032 14.2164 12.1812C15.2274 13.3365 15.8378 14.864 15.9457 16.4818C15.9648 16.7676 16.1951 17 16.4815 17V17Z"
            stroke="black"
            strokeWidth="2"
            mask="url(#path-1-inside-1_3434_4543)"
          />
          <circle cx="10" cy="6" r="3.5" stroke="black" />
        </g>
        <defs>
          <clipPath id="clip0_3434_4543">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </mask>
      <circle
        cx="12"
        cy="12"
        r="12"
        fill={maskBgColor}
        mask="url(#personMask)"
      />
    </svg>
  );
};

export default PersonIcon;
