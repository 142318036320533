import React from "react";
import { Box } from "@mui/material";

type Props = {
  errorMessage: string;
};

const ErrorLabel: React.FC<Props> = ({ errorMessage }): JSX.Element => {
  return (
    <Box>
      <div
        style={{
          marginTop: "-10px",
          marginLeft: "18px",
          color: "rgb(202, 40, 40)",
          fontFamily:
            "Anthro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif,Sohne",
          fontSize: "0.75rem",
          fontWeight: "400",
        }}
      >
        {errorMessage}
      </div>
    </Box>
  );
};

export default ErrorLabel;
