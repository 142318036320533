import {
  AccessTime,
  CalendarMonth,
  LocationOnOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { dark, mid, midlight } from "pages/variables";
import { toDisplayDate } from "utils/dates";

export type Officer = {
  name: string;
  title: string;
  address: string;
  date: string;
};

export default function Officer({
  name,
  title,
  address,
  date,
}: Officer): JSX.Element {
  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: 1,
        borderBottom: `1px solid ${midlight}`,
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "start",
        }}
      >
        <Typography fontSize="1.125rem" fontFamily="Sohne" color="primary.dark">
          {name}
        </Typography>
        <Box
          sx={() => ({
            display: "flex",
            alignSelf: "start",
            gridColumnGap: 10,
            marginTop: 1,
            paddingBottom: "5px",
            gap: 2,
          })}
        >
          {address && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <LocationOnOutlined fontSize="small" sx={{ color: mid }} />
              <Typography variant="caption" color={dark}>
                {address}
              </Typography>
            </Box>
          )}
          {title && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <AccessTime fontSize="small" sx={{ color: mid }} />
              <Typography variant="caption" color={dark}>
                {title}
              </Typography>
            </Box>
          )}
          {date && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <CalendarMonth fontSize="small" sx={{ color: mid }} />
              <Typography variant="caption" color={dark}>
                {toDisplayDate(date)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
