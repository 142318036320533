import { useCallback } from "react";
import { useAuthFetch } from "./useAuthFetch";

const useRemoveUser = (id: string) => {
  const fetch = useAuthFetch();

  return useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_PORTAL_API_URL}/accounts/${id}`,
      {
        method: "DELETE",
      },
    );
    return response;
  }, [fetch, id]);
};

export default useRemoveUser;
