/* eslint-disable */

//fonts
export let primary_font_family = "Sohne, sans-serif";
export let secondary_font_family = "Anthro, sans-serif";

//heading
export let heading_extra_large = "45px";
export let heading_largest = "38px";
export let heading_larger = "32px";
export let heading_large = "24px";
export let heading_medium = "20px";
export let heading_small = "18px";
export let heading_smaller = "14px";
export let heading_smallest = "12px";

export let heading_height_extra_large = "normal";
export let heading_height_largest = "normal";
export let heading_height_larger = "normal";
export let heading_height_large = "24px";
export let heading_height_medium = "normal";
export let heading_height_small = "normal";
export let heading_height_smaller = "normal";
export let heading_height_smallest = "normal";

export let heading_extra_large_secondary = heading_extra_large;
export let heading_largest_secondary = heading_largest;
export let heading_larger_secondary = heading_larger;
export let heading_large_secondary = "22px";
export let heading_medium_secondary = heading_medium;
export let heading_small_secondary = "18px";
export let heading_smaller_secondary = heading_smaller;
export let heading_smallest_secondary = heading_smallest;

export let heading_height_extra_large_secondary = heading_height_extra_large;
export let heading_height_largest_secondary = heading_height_largest;
export let heading_height_larger_secondary = heading_height_larger;
export let heading_height_large_secondary = heading_height_large;
export let heading_height_medium_secondary = heading_height_medium;
export let heading_height_small_secondary = heading_height_small;
export let heading_height_smaller_secondary = heading_height_smaller;
export let heading_height_smallest_secondary = heading_height_smallest;

//base (base, other text elements) sizes
export let base_extra_large = "60px";
export let base_largest = "32px";
export let base_larger = "28px";
export let base_large = "24px";
export let base_medium = "18px";
export let base_small = "16px";
export let base_smaller = "14px";
export let base_smallest = "12px";
export let base_tiny = "10px";

export let base_height_extra_large = "68px";
export let base_height_largest = "normal";
export let base_height_larger = "normal";
export let base_height_large = "normal";
export let base_height_medium = "18px";
export let base_height_small = "21px";
export let base_height_smaller = "20px";
export let base_height_smallest = "18px";
export let base_height_tiny = "12px";

//meta
export let meta_regular = "16px";
export let meta_small = "14px";
export let meta_smaller = "12px";
export let meta_smallest = "10px";
export let meta_tiny = "8px";
export let meta_spacing = "0.01em";

//link
export let link_large = "16px";
export let link_regular = "14px";
export let link_small = "12px";

//icon
export let icon_larger = "20px";
export let icon_large = "18px";
export let icon_regular = "16px";
export let icon_small = "14px";
export let icon_smaller = "12px";
export let icon_smallest = "10px";

//weights
export let weight_thin = "100";
export let weight_light = "300";
export let weight_regular = "400";
export let weight_medium = "500";
export let weight_semibold = "600";
export let weight_bold = "700";
export let weight_black = "900";

//colours
export let primary = "#004257";
export let primary_mid = "#1D6A83";
export let primary_light = "#E6F0F3";
export let primary_dark = "#022A37";

export let secondary = "#00C673";
export let secondary_mid = "#0CF191";
export let secondary_light = "#DEFFF1";
export let secondary_dark = "#073D26";

export let tertiary = "#C92D8F";
export let tertiary_mid = "#E548AA";
export let tertiary_light = "#FFAADF";
export let tertiary_dark = "#A71871";

export let darkest = "#252C2E";
export let darker = "#3C4244";
export let dark = "#50585B";
export let middark = "#889195";
export let mid = "#AEB8BC";
export let midlight = "#D3D9DB";
export let light = "#F0F4F5";
export let lighter = "#F4F5F5";
export let lightest = "#FAFAFA";

export let white = "rgba(255,255,255,1)";
export let black = "rgba(0,0,0,1)";

export let red = "#CA2828";
export let green = "#00B167";
export let amber = "#EAAB30";
export let cherry = "#FEE4E2";
export let azul = "#E6F0F3";
export let scarlet = "#D92D20";
export let legalAmber = "#FFB841";

//common properties
export let gap = "6px";
export let transition = "all 0.15s ease";

export let base_padding = "12px";
//padding combinations
export let padding_small = "2px 8px";
export let padding_regular = "6px 15px";
export let padding_large = "12px 20px";

//components properties
//avatar properties
export let avatar_border_radius = "50%";
//button properties
export let button_border_radius = "5px";
export let button_gap = gap;

export let button_small = "12px";
export let button_height_small = "18px";
export let button_regular = "14px";
export let button_height_regular = "18px";
export let button_large = "16px";
export let button_height_large = "18px";

//checkbox properties/radio
export let checkbox_radius = "2px";
export let radio_radius = "50%";
export let checkbox_transition = transition;
export let checkbox_small = "12px";
export let checkbox_regular = "16px";
export let checkbox_large = "20px";

//select
export let select_radius = "8px";

//dropdown properties
export let dropdown_shadow = "0px 4px 24px rgba(0,0,0,0.05)";
export let dropdown_gap = gap;
export let dropdown_radius = "8px";

//input properties
export let input_gap = gap;
export let input_radius = "8px";

//toggle properties
//[width container, height container, size of circle, start transform, end transform]
export let toggle_small = [
  "20px",
  "12px",
  "8px",
  "translateX(1px)",
  "translateX(9px)",
];
export let toggle_regular = [
  "26px",
  "16px",
  "10px",
  "translateX(2px)",
  "translateX(12px)",
];
export let toggle_large = [
  "32px",
  "20px",
  "12px",
  "translateX(3px)",
  "translateX(16px)",
];
export let toggle_transition = transition;
export let toggle_radius = "15px";
export let toggle_container_size = ["44px", "24px"]; //width, height

//loaders
export let bar_radius = "10px";
export let bar_height = "3px";
export let bar_transition = transition;
export let step_gap = "2px";

//number picker
export let number_picker_gap = gap;
export let number_picker_radius = "8px";

//pagination
export let pagination_dots_gap = gap;
export let pagination_text_gap = "15px";

//rating
export let rating_gap = "2px";

//swatch
export let swatch_gap = gap;
export let swatch_radius = "50%";

//table

//tooltip
export let tooltip_radius = "8px";
