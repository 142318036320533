import { KyckrError, buildFullAPIURL } from "hooks/useApi";
import { Order } from "./types";
import { toDisplayDate } from "utils/dates";
import { OrderHistoryResponse } from "./types";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import { useAuthFetch } from "hooks/useAuthFetch";
import { jurisdictions } from "jurisdictions";

export type subGroup = Order[];
export type dayGroup = Map<string, subGroup>;

const convertKyckrIdToJurisdiction = (KyckrId: string) => {
  if (KyckrId && KyckrId.includes("|")) {
    const parts = KyckrId.split("|");
    if (parts.length > 0) {
      return parts[0];
    }
  }
  return (
    jurisdictions.find((jur) => jur.nodeCodes?.includes(KyckrId))
      ?.isoAlpha2Code ?? ""
  );
};

const groupTransactions = (filingEntries: Order[]) => {
  const dateMap = new Map<string, dayGroup>();

  filingEntries
    .sort(
      (item1, item2) => +new Date(item2.orderDate) - +new Date(item1.orderDate),
    )
    .forEach((order) => {
      // Default a name if it's missing
      if (!order.companyDetails.companyName) {
        order.companyDetails.companyName = `Company Number: ${order.companyDetails.companyNumber}`;
      } else {
        order.companyDetails.companyName =
          order.companyDetails.companyName.trim();
      }

      order.jurisdiction = convertKyckrIdToJurisdiction(
        order.companyDetails.kyckrId,
      );

      const dayGroupKey = toDisplayDate(order.orderDate).toString(); // This should always be a string, but the util function sometimes returns Date / unknown??

      // Initialise missing ones
      if (!dateMap.has(dayGroupKey)) dateMap.set(dayGroupKey, new Map());

      // This returns a reference so we don't need to set it again
      const dayGroup = dateMap.get(dayGroupKey) as dayGroup; // "as" gets rid of the undefined option here, it's initilised above

      // initialise and fetch subgroup from the day
      const getSubGroup = (id: string | number): subGroup => {
        const subGroupKey = id.toString();

        if (!dayGroup.has(subGroupKey)) dayGroup.set(subGroupKey, []);

        return dayGroup.get(subGroupKey) as subGroup; // "as" gets rid of the undefined option here, it's initilised above
      };

      // If it's a parent, group by UBO order ID and put it first (other items may already be in the list)
      // If this item is a child, it goes into 1 or more groups, not at root level
      // Everything else goes by Company Name (may just be the company number, see defaulting above)
      if (order.isUboGroupParent) {
        getSubGroup(order.orderId).unshift(order);
      } else if (order.meta?.portalGroupingIds?.length) {
        order.meta.portalGroupingIds.forEach((id) => {
          getSubGroup(id).push(order);
        });
      } else {
        getSubGroup(order.companyDetails.companyName).push(order);
      }
    });

  return dateMap;
};

type OrdersLoader = {
  queryApi: UseInfiniteQueryResult<OrderHistoryResponse, KyckrError>;
  orders: Map<string, dayGroup>;
  ordersSeen: number;
  maxOrders: number;
  visibleOrders: number;
};

const useOrders = (
  startDate: Date | null,
  endDate: Date | null,
  searchTerm: string,
  orderTypes: string,
): OrdersLoader => {
  const params = [
    startDate
      ? `startDate=${encodeURIComponent(startDate.toISOString())}`
      : null,
    endDate ? `endDate=${encodeURIComponent(endDate.toISOString())}` : null,
    searchTerm ? `companyName=${encodeURIComponent(searchTerm)}` : null,
    `includeOrganisationOrders=${orderTypes}`,
  ].filter((i) => i != null);
  const qs = params.join("&");

  const fetch = useAuthFetch();
  const infResponse = useInfiniteQuery<OrderHistoryResponse, KyckrError>(
    `/v2/orders?${qs}`,
    {
      queryFn: async ({ pageParam }) => {
        let qsFinal = qs;
        if (pageParam) {
          qsFinal += `&continuationToken=${pageParam}`;
        }

        const response = await fetch(buildFullAPIURL(`/v2/orders?${qsFinal}`));
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      },
      getNextPageParam: (lastPage) => lastPage?.data?.continuationToken,
    },
  );

  let orders = new Map<string, dayGroup>();
  let visibleOrders = 0;
  if (infResponse?.data) {
    const allOrders = infResponse.data.pages.flatMap(
      (page) => page.data.orders,
    );
    visibleOrders = allOrders.length;
    orders = groupTransactions(allOrders);
  }
  // Try and figure out how many items have been searched so far.
  const latestPage = infResponse.data?.pages[infResponse.data.pages.length - 1];

  return {
    queryApi: infResponse,
    orders,
    ordersSeen: latestPage?.data?.ordersSeen ?? 0,
    maxOrders: latestPage?.data?.maxOrders ?? 0,
    visibleOrders,
  };
};

export default useOrders;
