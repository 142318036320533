import { SxProps } from "@mui/material/styles";

type Props = {
  width?: string;
  height?: string;
  transform?: string;
  maskBgColor?: string;
  sx?: SxProps;
};

const JointShareholderIcon: React.FC<Props> = ({
  width,
  height,
  transform,
  maskBgColor,
}): JSX.Element => {
  const defaultTransform = "scale(0.9) translate(3.25,2.75)";
  return (
    <svg
      width={width ?? 35}
      height={height ?? 35}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="jointShareholderMask">
        <rect width="100%" height="100%" fill="white" />
        <g
          clipPath="url(#clip0_3434_4543)"
          transform={transform ?? defaultTransform}
        >
          <path
            d="M1.6001 15.55C1.6001 12.787 3.83867 10.5472 6.6001 10.5472C9.36152 10.5472 11.6001 12.787 11.6001 15.55M18.3999 13.72C18.3999 11.7859 16.8329 10.218 14.8999 10.218C14.0281 10.218 13.2308 10.5369 12.6181 11.0645C12.5109 11.1568 12.4093 11.2555 12.314 11.36M9.56885 7.42044C9.56885 9.06097 8.23969 10.3909 6.6001 10.3909C4.9605 10.3909 3.63135 9.06097 3.63135 7.42044C3.63135 5.77992 4.9605 4.45001 6.6001 4.45001C8.23969 4.45001 9.56885 5.77992 9.56885 7.42044ZM16.978 8.02928C16.978 9.17765 16.0476 10.1086 14.8999 10.1086C13.7522 10.1086 12.8218 9.17765 12.8218 8.02928C12.8218 6.88092 13.7522 5.94998 14.8999 5.94998C16.0476 5.94998 16.978 6.88092 16.978 8.02928Z"
            stroke="black"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </mask>
      <circle
        cx="12"
        cy="12"
        r="12"
        fill={maskBgColor}
        mask="url(#jointShareholderMask)"
      />
    </svg>
  );
};

export default JointShareholderIcon;
