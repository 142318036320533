import { CalendarMonth, LocationOnOutlined } from "@mui/icons-material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import { Box, styled, Typography } from "@mui/material";
import { dark, mid, midlight } from "pages/variables";
import Twemoji from "react-twemoji";
import { toDisplayDate } from "utils/dates";
import { StyledTooltip } from "components/StyledTooltip/StyledTooltip";
export const EmojiFlag = styled(Twemoji)`
  .emoji {
    width: 25px;
  }
`;

EmojiFlag.defaultProps = {
  options: {
    base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
  },
};

export type CompanyOfficial = {
  name: string;
  addressInOneLine: string;
  nationality: string;
  dateOfBirth: string;
  dateAppointed: string;
  role: string;
};

export default function CompanyOfficial({
  name,
  addressInOneLine,
  nationality,
  dateOfBirth,
  dateAppointed,
  role,
}: CompanyOfficial): JSX.Element {
  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: 1,
        borderBottom: `1px solid ${midlight}`,
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "start",
        }}
      >
        <Typography fontSize="1.125rem" fontFamily="Sohne" color="primary.dark">
          {name}
        </Typography>
        <Box
          sx={() => ({
            display: "flex",
            alignSelf: "start",
            gridColumnGap: 10,
            marginTop: 1,
            paddingBottom: "5px",
            gap: 2,
          })}
        >
          {addressInOneLine && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <StyledTooltip title="Registered Address" placement="bottom">
                <LocationOnOutlined fontSize="small" sx={{ color: mid }} />
              </StyledTooltip>
              <Typography variant="caption" color={dark}>
                {addressInOneLine}
              </Typography>
            </Box>
          )}
          {nationality && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <StyledTooltip title="Nationality" placement="bottom">
                <FlagOutlinedIcon fontSize="small" sx={{ color: mid }} />
              </StyledTooltip>
              <Typography variant="caption" color={dark}>
                {nationality}
              </Typography>
            </Box>
          )}
          {dateOfBirth && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <StyledTooltip title="Birth Date" placement="bottom">
                <CakeOutlinedIcon fontSize="small" sx={{ color: mid }} />
              </StyledTooltip>
              <Typography variant="caption" color={dark}>
                {toDisplayDate(dateOfBirth)}
              </Typography>
            </Box>
          )}
          {role && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <StyledTooltip title="Official Title" placement="bottom">
                <WorkOutlineIcon fontSize="small" sx={{ color: mid }} />
              </StyledTooltip>
              <Typography variant="caption" color={dark}>
                {role}
              </Typography>
            </Box>
          )}
          {dateAppointed && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <StyledTooltip title="Appointed Date" placement="bottom">
                <CalendarMonth fontSize="small" sx={{ color: mid }} />
              </StyledTooltip>
              <Typography variant="caption" color={dark}>
                {toDisplayDate(dateAppointed)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
