import ErrorMessage from "components/ErrorMessage";

export default function Unavailable(): JSX.Element {
  return (
    <ErrorMessage
      message="Error - Please contact our support team at support@kyckr.com and we endevour to resolve your issue as quickly as possible."
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "75vh",
        gap: 2,
      }}
    />
  );
}
