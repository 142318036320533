import { useNavigate } from "react-router-dom";
import { Box, Chip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import "assets/fonts/fonts.css";
import CompanySearch from "components/CompanySearch";
import { dark, middark, midlight } from "../variables";
import qs from "query-string";
import { useEffect, useState } from "react";
import { RecentSearch } from "types";
import { useUserContext } from "contexts/UserContext";
import { manualRetrievalJurisdictions } from "components/CompanySearch/specialJurisdictions";

export default function Home(): JSX.Element {
  const navigate = useNavigate();
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);
  const { user } = useUserContext();
  useEffect(() => {
    const rs: RecentSearch[] = JSON.parse(
      localStorage.getItem("recentSearches") || "null",
    );
    setRecentSearches(rs);
  }, []);

  const onChipDelete = (index: number) => () => {
    setRecentSearches((chips) => {
      const newSearches = [
        ...chips.slice(0, index),
        ...chips.slice(index + 1, chips.length),
      ];
      localStorage.setItem("recentSearches", JSON.stringify(newSearches));

      return newSearches;
    });
  };

  const recentSearchlink = ({
    search,
    searchType,
    regAuth,
    jurisdiction,
  }: RecentSearch) => {
    const searchRoute = manualRetrievalJurisdictions.includes(
      jurisdiction.isoAlpha2Code,
    )
      ? "manual-retrieval"
      : "search";

    const params =
      jurisdiction?.isoAlpha2Code !== "null"
        ? qs.stringify(
            {
              search,
              jurisdiction: jurisdiction.isoAlpha2Code,
              searchType,
              regAuth,
            },
            { skipNull: true },
          )
        : qs.stringify(
            {
              search,
              searchType,
              regAuth,
            },
            { skipNull: true },
          );

    return `/${searchRoute}?${params}`;
  };

  const buildState = ({ jurisdiction }: RecentSearch) => {
    if (jurisdiction?.isoAlpha2Code === "null") {
      localStorage.setItem("isGlobalSearch", JSON.stringify(true));
    }

    return {
      name: jurisdiction.name,
      type: jurisdiction.type,
      isoAlpha2Code: jurisdiction.isoAlpha2Code,
    };
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          display: "grid",
          padding: "50px 70px",
          gridTemplateColumns: "1fr 1fr",
          gap: "0px 30px",
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
            padding: "40px 25px",
          },
        })}
      >
        <div>
          <Typography variant="h6" color="primary.dark" fontWeight={500}>
            Get Started
          </Typography>
          <Typography
            variant="h4"
            fontFamily="Sohne"
            color="primary.dark"
            mb="25px"
          >
            Welcome, {user?.name}
          </Typography>
          <CompanySearch />
          <Typography
            variant="h6"
            color="primary.dark"
            fontWeight={500}
            sx={{ marginTop: 3 }}
          >
            Past searches
          </Typography>
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            {recentSearches &&
              recentSearches.slice(0, 10).map((recentSearch, index) => (
                <Chip
                  label={recentSearch.search}
                  onClick={() => {
                    navigate(recentSearchlink(recentSearch), {
                      state: buildState(recentSearch),
                      replace: true,
                    });
                  }}
                  deleteIcon={<Close />}
                  onDelete={onChipDelete(index)}
                  sx={{
                    backgroundColor: "primary.light",
                    color: middark,
                    fontWeight: 600,
                    fontSize: "0.75rem",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: midlight,
                      color: dark,
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "0.875rem",
                    },
                  }}
                  data-testid={`list-item-${index}`}
                  key={`list-item-${index}`}
                />
              ))}
          </Box>
        </div>
      </Box>
    </>
  );
}
