import { useMemo } from "react";
import { CompanyOfficial } from "./CompanyOfficial";

const useCompanyOfficialsSort = (
  companyOfficials: CompanyOfficial[],
  sortBy: string,
  isDescending: boolean,
) => {
  const sortedOfficials = useMemo(() => {
    const sorted = companyOfficials.sort((a, b) => {
      if (sortBy === "appointment-date") {
        return a.dateAppointed < b.dateAppointed ? -1 : 1;
      }
      if (sortBy === "name") {
        return a.name < b.name ? -1 : 1;
      }
      if (sortBy === "role") {
        return a.role < b.role ? -1 : 1;
      }

      return 0;
    });

    if (!isDescending) {
      return sorted.reverse();
    }

    return sorted;
  }, [companyOfficials, sortBy, isDescending]);

  return sortedOfficials;
};

export default useCompanyOfficialsSort;
