import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import QueryString from "query-string";
import { dark } from "../variables";
import { jurisdictions } from "../../jurisdictions";
import Filings from "./Tabs/Filings";
import { useCompanyContext } from "contexts/CompanyContext";
import { ICompanyProfileResultAddress } from "pages/Company/types";

const Manual = (): JSX.Element => {
  const location = useLocation();
  const { searchType, search, jurisdiction } = QueryString.parse(
    location.search,
  );
  const { companyProfile, setCompanyProfile } = useCompanyContext();
  const [companyName, setCompanyName] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");

  useEffect(() => {
    searchType === "name" ? setCompanyName(search) : setCompanyNumber(search);
  }, [searchType, search]);

  useEffect(() => {
    setCompanyProfile({
      ...companyProfile,
      name: companyName,
      code: companyNumber,
      addresses: [
        { addressInOneLine: companyAddress } as ICompanyProfileResultAddress,
      ],
    });
    // TODO:: Bad use of useEffect, need to refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, companyNumber, companyAddress]);

  const subJurisdiction: string | undefined = jurisdictions
    .find((j) => j.isoAlpha2Code == jurisdiction.slice(0, 2))
    ?.subJurisdictions?.find((j) => j.isoAlpha2Code == jurisdiction)?.name;

  return (
    <Box
      p="20px 70px"
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        gridTemplateAreas: '"registration tabs"',
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
          gridTemplateAreas: '"registration" "tabs"',
        },
        gridColumnGap: "50px",
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          gridArea: "registration",
        }}
      >
        <Typography
          variant="h4"
          fontSize="2.375rem"
          fontFamily="Sohne"
          color="primary.dark"
          mb={4}
        >
          {companyName}
        </Typography>
        <Typography color="primary.dark" fontWeight={500}>
          Company requires manual document retrieval
        </Typography>
        <Typography color={dark} fontSize="0.85rem">
          Your search for "{companyName}"{" "}
          {subJurisdiction ? " in " + subJurisdiction : undefined} cannot be
          completed automatically due to restrictions by the jurisdiction
          registry. <br />
          <br />
          Not to worry, one of our team will conduct the document retrieval on
          your behalf. <br />
          <br />
          Please provide additional information you have to help this process.
        </Typography>
        <Box>
          <Typography color="primary.dark" fontWeight={500} mb={1}>
            Company name
          </Typography>
          <TextField
            size="small"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            sx={{
              width: "90%",
              input: {
                color: "primary.dark",
                fontWeight: 500,
                fontSize: "0.75rem",
                borderRadius: "15px",
              },
            }}
          />
        </Box>
        <Box>
          <Typography color="primary.dark" fontWeight={500} mb={1}>
            Company number (optional)
          </Typography>
          <TextField
            size="small"
            value={companyNumber}
            placeholder="123456"
            onChange={(e) => setCompanyNumber(e.target.value)}
            sx={{
              width: "90%",
              input: {
                color: "primary.dark",
                fontWeight: 500,
                fontSize: "0.75rem",
                borderRadius: "5px",
              },
            }}
          />
        </Box>
        <Box>
          <Typography color="primary.dark" fontWeight={500} mb={1}>
            Address (optional)
          </Typography>
          <TextField
            size="small"
            value={companyAddress}
            placeholder="123, High Street"
            onChange={(e) => setCompanyAddress(e.target.value)}
            sx={{
              width: "90%",
              input: {
                color: "primary.dark",
                fontWeight: 500,
                fontSize: "0.75rem",
                borderRadius: "5px",
              },
            }}
          />
        </Box>
      </Box>
      <Box sx={{ gridArea: "tabs" }}>
        <Filings />
      </Box>
    </Box>
  );
};

export default Manual;
