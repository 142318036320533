import { useMemo } from "react";
import { Officer } from "./Officer";

const useOfficersSort = (
  officers: Officer[],
  sortBy: string,
  isDescending: boolean,
) => {
  const sortedOfficials = useMemo(
    () =>
      officers.sort((a, b) => {
        if (sortBy === "appointment-date") {
          return a.date < b.date ? -1 : 1;
        }
        if (sortBy === "name") {
          return a.name < b.name ? -1 : 1;
        }
        if (sortBy === "role") {
          return a.title < b.title ? -1 : 1;
        }

        return 0;
      }),
    [officers, sortBy],
  );

  if (!isDescending) {
    return sortedOfficials.reverse();
  }

  return sortedOfficials;
};

export default useOfficersSort;
