import { Box, Typography } from "@mui/material";
import { dark } from "../variables";

const LabeledDataList: React.FC<{
  label: string;
  dataList: string[] | undefined;
}> = ({ label, dataList }) => {
  if (!dataList || dataList.length == 0) return <></>;

  return (
    <Box>
      <Typography fontSize="0.75rem" color="primary.dark">
        {label}
      </Typography>
      {dataList?.map((data, index) => (
        <Typography key={index} fontSize="0.75rem" color={dark}>
          {data}
        </Typography>
      ))}
    </Box>
  );
};

export default LabeledDataList;
