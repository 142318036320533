import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import useApiFetcher from "./useApiFetcher";

export type KyckrError = { code: number | string } & Error;

export function buildFullAPIURL(path: string): string {
  return `${process.env.REACT_APP_PORTAL_API_URL}${path}`;
}

export const useApi = <T>(
  path: string,
  options: UseQueryOptions<T, KyckrError, T, QueryKey> = {},
) => {
  const queryKey = [buildFullAPIURL(path)];

  return useQuery<T, KyckrError>(queryKey, useApiFetcher<T>(), options);
};
