import {
  Clear,
  LockReset,
  ManageAccounts,
  PersonOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
} from "@mui/material";
import React from "react";

type Props = {
  showPopup: boolean;
  isLoading: boolean;
  titleText: string;
  bodyText: string;
  iconName: string;
  actionConfirmTitle: string;
  setShowPopup: () => void;
  actionConfirm: () => void;
  actionCancel: () => void;
};

const Popup: React.FC<Props> = ({
  showPopup,
  isLoading,
  titleText,
  bodyText,
  iconName,
  actionConfirmTitle,
  actionConfirm,
  actionCancel,
}) => {
  return (
    <Dialog open={showPopup} onClose={() => actionCancel()}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}
        >
          {iconName === "PersonOff" && (
            <PersonOff sx={{ color: "primary.dark" }} />
          )}
          {iconName === "ManageAccounts" && (
            <ManageAccounts sx={{ color: "primary.dark" }} />
          )}
          {iconName === "LockReset" && (
            <LockReset sx={{ color: "primary.dark" }} />
          )}
          <DialogTitle sx={{ color: "primary.dark", paddingLeft: 1 }}>
            {titleText}
          </DialogTitle>
        </Box>
        <IconButton
          aria-label="close-settings"
          sx={{ alignSelf: "center", marginRight: 1 }}
          onClick={() => actionCancel()}
        >
          <Clear sx={{ color: "primary.dark" }} fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          fontFamily: "Anthro",
          fontSize: "1rem",
          color: "primary.dark",
          marginBottom: "60px",
          lineHeight: "26px",
        }}
      >
        {bodyText}
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: "row",
          justifyContent: "right",
          alignContent: "end",
          paddingRight: 2,
          paddingBottom: 2,
          paddingTop: 0,
          mt: -2,
        }}
      >
        <FormGroup
          sx={{
            flexDirection: "row",
            mr: 1,
          }}
        >
          <Button
            sx={{
              textTransform: "capitalize",
              mr: 2,
              backgroundColor: "primary.main",
              color: "primary.light",
              "&:hover": {
                backgroundColor: "primary.dark",
                color: "primary.light",
              },
            }}
            onClick={async () => {
              await actionConfirm();
            }}
          >
            {isLoading && <CircularProgress color="info" size="24px" />}
            {!isLoading && actionConfirmTitle}
          </Button>
          <Button
            autoFocus
            onClick={() => actionCancel()}
            sx={{
              textTransform: "capitalize",
              upperCase: "false",
              backgroundColor: "primary.light",
              color: "primary.dark",
              "&:hover": {
                color: "white",
                backgroundColor: "primary.dark",
              },
            }}
          >
            Cancel
          </Button>
        </FormGroup>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
