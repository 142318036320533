import { Box, CircularProgress, Typography } from "@mui/material";
import SearchUbo from "components/SearchUbo/SearchUbo";
import UboVisualization from "components/UboVisualization/UboVisualization";
import { useCompanyContext } from "contexts/CompanyContext";
import { CompanyProfile } from "contexts/CompanyContext/types";
import {
  IUltimateBeneficialOwner,
  IEdge,
  IEntity,
  INode,
  IOwnershipTree,
  IOwnershipTreeResponse,
  IReasonForNonContinuation,
  LayoutOptions,
} from "pages/Company/Tabs/Ubo/types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { uboPersonValues } from "uboConstants";
import Cytoscape from "cytoscape";
import qs from "query-string";
import { UBOVerifyCreateResponse } from "components/UBOVerifyStartPaper/types";
import { useApi } from "hooks/useApi";
import { useCreditContext } from "contexts/CreditContext";
import { GraphOrientation } from "pages/Company/Tabs/Ubo/types";
import { Grid } from "pages/Company/types";
import UboDrawer from "components/UboDrawer/UboDrawer";
import { useNodePopupContext } from "contexts/NodePopupContext";
import { EntityType, UboStatus } from "../../../constants";
import { Userpilot } from "userpilot";
type Props = {
  grid: Grid;
  isActive: boolean;
  treeId?: number | null;
  regAuth?: string | null;
  isInitialUnwrap: boolean;
  setIsInitialUnwrap: React.Dispatch<React.SetStateAction<boolean>>;
};

const UBOVerify: React.FC<Props> = ({
  grid,
  isActive,
  treeId,
  regAuth,
  isInitialUnwrap,
  setIsInitialUnwrap,
}) => {
  const isPerson = (shareholderType: string | null) =>
    uboPersonValues.some(
      (item) => item.toLowerCase() == shareholderType?.toLowerCase(),
    );

  const {
    companyProfile: cp,
    enhancedLoading,
    enhancedLoaded,
  } = useCompanyContext();

  const defaultUboThreshold = 25;
  const search = useLocation().search;

  const [showRepresentatives, setShowRepresentatives] =
    useState<boolean>(false);
  const [cyRef, setCyRef] = useState<Cytoscape.Core | null>(null);
  const [showGraph, setShowGraph] = useState<boolean>(true);
  const [isGraphReady, setIsGraphReady] = useState<boolean>(false);
  const [loadUboCreate, setLoadUboCreate] = useState<boolean>(false);
  const [loadUboList, setLoadUboList] = useState<boolean>(false);
  const [uboNonSuccessStatus, setUboNonSuccessStatus] = useState<
    string | undefined
  >();
  const [maxCreditCost, setMaxCreditCost] = useState("5");
  const [uboThreshold, setUboThreshold] = useState("25");
  const [maxLayers, setMaxLayers] = useState<string | null>(null);
  const [uboUnavailable, setUboUnavailable] = useState<boolean>(false);
  const [ownershipTree, setOwnershipTree] = useState<
    IOwnershipTreeResponse | undefined
  >();
  const [styleLoaded, setStyleLoaded] = useState(false);
  const [orientation, setOrientation] = useState<GraphOrientation>(
    GraphOrientation.UpDown,
  );
  const [hideSuccessMessage, setHideSuccessMessage] = useState(!!treeId); //if we come from history (treeId) => hide "UBO results generated successfully!" message

  const defaultLayout: LayoutOptions = {
    name: "dagre",
    rankDir: orientation,
    fit: true,
    directed: true, // Not supported in Dagre Layout?
    padding: 0,
    spacingFactor: 2.25,
    animate: true,
    animationDuration: 500,
    avoidOverlap: true,
    maximal: false, // Not supported in Dagre Layout?
  };

  const [layout, setLayout] = useState(defaultLayout);
  const { refetchCredits } = useCreditContext();
  const { clearNodePopups, popperCollection } = useNodePopupContext();
  const { companyCode, jurisdiction, companyProfile, setCompanyProfile } =
    useCompanyContext();
  const [prevOrderId, setPrevOrderId] = useState<string>("");

  const uboCreateParams = qs.stringify({
    maxCreditCost: maxCreditCost,
    uboThreshold: uboThreshold,
    maxLayers: maxLayers,
    regAuth: regAuth ?? cp.registrationAuthorityCode,
    prevOrderId: prevOrderId,
  });

  const { isLoading: uboCreateLoading, data: uboCreateData } =
    useApi<UBOVerifyCreateResponse>(
      `/uboverify/create/${jurisdiction}/${encodeURIComponent(
        companyCode,
      )}?${uboCreateParams}`,
      {
        enabled: loadUboCreate,
        staleTime: 1,
        onSuccess: (data: UBOVerifyCreateResponse) => {
          if (!(data.responseCode === "201")) {
            setUboUnavailable(true);
            setUboNonSuccessStatus(data.responseCode);
          }

          refetchCredits();
        },
        onError: () => {
          setUboUnavailable(true);
          setUboNonSuccessStatus("500");
        },
      },
    );

  const [isInitialRefetch, setIsInitialRefetch] = useState<boolean>(true);

  const intervalMs = 2000;
  const { isLoading: uboListLoading, data: uboListData } =
    useApi<IOwnershipTreeResponse>(`/uboverify/list/${prevOrderId}`, {
      enabled: loadUboList,
      staleTime: 1,
      refetchInterval: (uboListData: IOwnershipTreeResponse | undefined) => {
        if (uboListData?.ownershipTree.status === UboStatus.IN_PROGRESS) {
          setIsInitialRefetch(false);
          return intervalMs;
        }
        return false;
      },
    });

  useEffect(() => {
    if (
      uboCreateData &&
      uboCreateData.ownershipTree &&
      uboCreateData.ownershipTree.status === UboStatus.SUCCESS
    ) {
      setLoadUboCreate(false);
      setPrevOrderId(uboCreateData.ownershipTree.orderId);
      setLoadUboList(true);
    }
  }, [uboCreateData]);

  useEffect(() => {
    if (uboListData?.ownershipTree) {
      if (uboListData.ownershipTree.status !== UboStatus.IN_PROGRESS) {
        if (uboListData.ownershipTree.status !== UboStatus.SUCCESS) {
          setUboNonSuccessStatus(uboListData.ownershipTree.status);
        }
        setOwnershipTree(uboListData);
        setCompanyProfile({
          ...companyProfile,
          registrationAuthorityCode:
            uboListData.ownershipTree.nodes.find((x) => x.level == "1")?.entity
              .registrationAuthority ??
            companyProfile.registrationAuthorityCode,
        });
        setStyleLoaded(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uboListData]);

  useEffect(() => {
    if (treeId != null) {
      setLoadUboCreate(false);
      setPrevOrderId("" + treeId);
      setLoadUboList(true);
    }
  }, [treeId]);

  useEffect(() => {
    if (treeId == null) {
      const searchParams = new URLSearchParams(search);
      const constructHoldingNode = (cp: CompanyProfile) => {
        if (cp && cp.name) {
          cp.name = decodeURI(cp.name);
        }
        const initialTree = {
          ownershipTree: {
            nodes: [
              {
                level: "1",
                edges: [] as IEdge[],
                entity: {
                  id: "1",
                  type: "COMPANY",
                  name: cp.name,
                  nameInEnglish: cp.name,
                  countryISO: searchParams.get("jurisdiction"),
                  reasonForNonContinuation: {
                    type: "UNWRAP_REQUIRED",
                    details: "Unwrap required.",
                  } as IReasonForNonContinuation,
                } as IEntity,
              } as INode,
            ] as INode[],
            name: cp.name,
            address: cp.addresses[0]?.addressInOneLine,
            status: "ROOT",
          } as IOwnershipTree,
        } as IOwnershipTreeResponse;
        return initialTree;
      };

      setIsGraphReady(false);
      setShowGraph(false);

      setOwnershipTree(constructHoldingNode(cp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const removeNonAlphaNumericChars = (input: string): string => {
      return input.replace(/[^\p{L}\p{N}]/gu, "");
    };
    const constructRootOwnershipTree = (
      cp: CompanyProfile,
      status?: string,
    ): IOwnershipTreeResponse => {
      if (cp && cp.name) {
        cp.name = decodeURI(cp.name);
      }
      const shareholderNodes: (INode | undefined)[] = cp.directorAndShareDetails
        ?.shareHolders
        ? cp.directorAndShareDetails.shareHolders
            .map((shareholder) => {
              if (shareholder?.percentage == "0") return;

              const entityType = isPerson(shareholder.shareholderType)
                ? EntityType.PERSON
                : EntityType.COMPANY;
              return {
                level: "2",
                edges: [] as IEdge[],
                entity: {
                  id: removeNonAlphaNumericChars(
                    shareholder.name +
                      shareholder.percentage +
                      shareholder.shareType,
                  ),
                  type: entityType,
                  name: shareholder.name,
                  nameInEnglish: shareholder.name,
                  reasonForNonContinuation:
                    entityType == EntityType.COMPANY
                      ? ({
                          type: "UNWRAP_REQUIRED",
                          details: "Unwrap required.",
                        } as IReasonForNonContinuation)
                      : null,
                } as IEntity,
                rollupPercentage: Number(shareholder.percentage),
              } as INode;
            })
            .filter((x) => x !== undefined)
        : ([] as INode[]);

      const directorNodes = cp.directorAndShareDetails?.directors
        ? cp.directorAndShareDetails.directors.map((director) => {
            return {
              level: "2",
              isDirector: true,
              entity: {
                id: director.name,
                type: EntityType.PERSON,
                name: director.name,
                nameInEnglish: director.name,
              },
            } as INode;
          })
        : ([] as INode[]);

      const shareholderEdges: (IEdge | undefined)[] = cp.directorAndShareDetails
        ?.shareHolders
        ? cp.directorAndShareDetails?.shareHolders
            .map((x) => {
              if (x?.percentage == "0") return;
              return {
                nodeId: removeNonAlphaNumericChars(
                  x.name + x.percentage + x.shareType,
                ),
                percentage: Number(x.percentage),
                type: "SHAREHOLDER",
                role: null,
                isCircular: null,
                shareholdings: [],
              } as IEdge;
            })
            .filter((x) => x !== undefined)
        : [];

      const representativeEdges = cp.directorAndShareDetails?.directors
        ? cp.directorAndShareDetails?.directors.map<IEdge>(
            (x) =>
              ({
                nodeId: x.name,
                role: x.title ?? x.officerRole,
                type: EntityType.REPRESENTATIVE,
              }) as IEdge,
          )
        : [];

      const initialTree = {
        ownershipTree: {
          nodes: [
            {
              level: "1",
              entity: {
                id: "1",
                type: EntityType.COMPANY,
                name: cp.name,
                nameInEnglish: cp.name,
                countryISO: searchParams.get("jurisdiction"),
              } as IEntity,
              edges: shareholderEdges.concat(representativeEdges),
            } as INode,
            ...shareholderNodes,
            ...directorNodes,
          ] as INode[],
          name: cp.name,
          address: cp.addresses[0]?.addressInOneLine,
          ultimateBeneficialOwners: shareholderNodes
            ? shareholderNodes
                .filter(
                  (n) =>
                    n?.rollupPercentage &&
                    n?.rollupPercentage >= defaultUboThreshold &&
                    n?.entity.type == EntityType.PERSON,
                )
                .map(
                  (node) =>
                    ({
                      id: node?.entity.id,
                      name: node?.entity.name,
                      percentage: node?.rollupPercentage
                        ? node.rollupPercentage.toString()
                        : null,
                      entityType: EntityType.PERSON,
                    }) as IUltimateBeneficialOwner,
                )
            : ([] as IUltimateBeneficialOwner[]),
          status: status ?? "ROOT",
        } as IOwnershipTree,
      } as IOwnershipTreeResponse;
      return initialTree;
    };

    if (cp) {
      if (
        (!enhancedLoading && enhancedLoaded && isInitialUnwrap) ||
        uboUnavailable ||
        uboNonSuccessStatus
      ) {
        setShowRepresentatives(true);
        setIsGraphReady(false);
        setShowGraph(false);
        setOwnershipTree(
          constructRootOwnershipTree(cp, ownershipTree?.ownershipTree.status),
        );
        setStyleLoaded(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uboUnavailable,
    uboNonSuccessStatus,
    enhancedLoading,
    enhancedLoaded,
    isInitialUnwrap,
  ]);

  const onUnwrap = (
    maxCost: string,
    maxLayers: string | null,
    uboThr: string,
  ) => {
    setHideSuccessMessage(false);
    setLoadUboList(false);
    setMaxCreditCost(maxCost);
    setMaxLayers(maxLayers);
    setUboThreshold(uboThr);
    setLoadUboCreate(true);
    setIsInitialUnwrap(false);
    setIsGraphReady(false);
    setShowGraph(false);
    setUboNonSuccessStatus(undefined);
    setOwnershipTree(undefined);
    setIsInitialRefetch(true);
    clearNodePopups();
  };

  const toggleRepresentatives = () => {
    Userpilot.track("Sidebar Show Representatives Icon Clicked");

    setShowRepresentatives(!showRepresentatives);
  };

  const toggleVerticalOrientation = () => {
    Userpilot.track("Sidebar Change Orientation Icon Clicked");

    if (orientation == GraphOrientation.UpDown) {
      setOrientation(GraphOrientation.DownUp);
    } else {
      setOrientation(GraphOrientation.UpDown);
    }
  };

  const resetLayout = () => {
    Userpilot.track("Sidebar Reset Visual Icon Clicked");

    if (cyRef) {
      cyRef.layout(layout).run();
      cyRef.fit();
      clearNodePopups();
    }
  };

  const isSingleLayout = grid?.isSingleLayout;

  useEffect(() => {
    Userpilot.track("Entered UBO Verify tab");
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <SearchUbo
          onUnwrap={onUnwrap}
          enableUnwrapButton={isInitialUnwrap || showGraph}
          sx={{ zIndex: 1 }}
          uboUnavailable={uboUnavailable}
          uboNonSuccessStatus={uboNonSuccessStatus}
          ownershipTree={ownershipTree}
          forceHideSuccessMessage={hideSuccessMessage}
        />
        {(isSingleLayout || !!treeId) && ( // show the drawer when opening with a treeId (history)
          <UboDrawer
            showRepresentatives={showRepresentatives}
            toggleRepresentatives={toggleRepresentatives}
            orientation={orientation}
            toggleOrientation={toggleVerticalOrientation}
            resetLayout={resetLayout}
            downloadUrl={uboListData?.ownershipTree?.retrievalLocation}
          />
        )}
      </Box>
      {ownershipTree && (
        <UboVisualization
          setShowGraph={setShowGraph}
          grid={grid}
          movable={true}
          showRepresentatives={showRepresentatives}
          cyRef={cyRef}
          setCyRef={setCyRef}
          isGraphReady={isGraphReady}
          setIsGraphReady={setIsGraphReady}
          ownershipTree={ownershipTree}
          orientation={orientation}
          styleLoaded={styleLoaded}
          setStyleLoaded={setStyleLoaded}
          layout={layout}
          setLayout={setLayout}
          isActive={isActive}
          popperCollection={popperCollection}
          clearNodePopups={clearNodePopups}
          isInitialUnwrap={isInitialUnwrap}
        />
      )}
      {!showGraph && (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            marginY: "15px",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "55vh",
          }}
        >
          <CircularProgress
            size="1em"
            color="success"
            variant="indeterminate"
          />
          <Typography sx={{ fontSize: "12px" }}>
            {uboCreateLoading
              ? "We're working on your request"
              : uboListLoading && isInitialRefetch
                ? "Connecting to registries..."
                : "Generating company structure..."}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UBOVerify;
