import React, { useEffect } from "react";
import qs from "query-string";
import {
  Box,
  CircularProgress,
  Link,
  Typography,
  Snackbar,
} from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import { Filing as FilingType } from "contexts/CompanyContext/types";
import Button from "components/Button/Button";
import { mid, dark } from "pages/variables";
import { WaitTime } from "./WaitTime";
import { toDisplayDate } from "utils/dates";
import { useCreditContext } from "contexts/CreditContext";
import { useApi } from "hooks/useApi";
import { IProductOrderResponse } from "components/FilingsPaper/types";
import { useCompanyContext } from "contexts/CompanyContext";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CustomerReferenceDialog from "./CustomerReferenceDialog";
import { Userpilot } from "userpilot";
import DuplicateOrderDialog from "./DuplicateOrderDialog";
import { OrderHistoryResponse } from "pages/OrderHistory/types";
import { useFeatureFlags } from "contexts/FeatureFlagContext";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

type Props = {
  data: FilingType;
};

export const getDisableDialogSetting = (): boolean => {
  return JSON.parse(localStorage.getItem("disableFilingDialog") || "false");
};
export const setDisableDialogSetting = (newVal: boolean) => {
  localStorage.setItem("disableFilingDialog", JSON.stringify(newVal));
};

const Filing: React.FC<Props> = ({
  data: {
    id,
    title,
    deliveryTimeMinutes,
    datePublished,
    creditCost,
    filingSample,
    countryISO,
  },
}) => {
  const isManual = id.startsWith("ROWOFF");
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);

  const [customerReferenceOpen, setCustomerReferenceOpen] =
    React.useState<boolean>(false);

  const [disableCustomerReferenceDialog, setDisableCustomerReferenceDialog] =
    React.useState<boolean>(getDisableDialogSetting());

  const [customerRef, setCustomerRef] = React.useState<string>("");

  // Dialog should be dismissed and not shown by default on page load
  // This shouldn't strictly be necessary but because we're using the presence of
  // and API response to determine whether to open the dialog we were getting issues
  // with the Dialog opening when using back/forward and cached API responses being present
  const [duplicateOrderDismissed, setDuplicateOrderDismissed] =
    React.useState<boolean>(true);

  const featureFlags = useFeatureFlags();
  const { companyProfile } = useCompanyContext();
  const { refetchCredits, credits } = useCreditContext();

  // Setup product call for later. This places the actual order
  // Disabled by default, called via refetch
  const productOrderParams = qs.stringify(
    {
      companyName: companyProfile.name,
      companyNumber: companyProfile.code,
      companyAddress: isManual
        ? companyProfile.addresses[0]?.addressInOneLine
        : "",
      displayDate: datePublished,
      customerRef: customerRef,
    },
    { skipNull: true },
  );

  const {
    data: productOrderData,
    error: productOrderError,
    isLoading: productOrderIsLoading,
    refetch: orderProductRefetch,
  } = useApi<IProductOrderResponse>(
    `/productorder/${countryISO}/${id}?${productOrderParams}`,
    {
      enabled: false,
      onSuccess: () => {
        setSnackbarOpen(true);
        refetchCredits();
      },
      onError: () => {
        setSnackbarOpen(true);
      },
    },
  );

  // Single entry point to allow us to track it
  const orderProductCall = () => {
    Userpilot.track("Filing Ordered");
    return orderProductRefetch();
  };

  const productOrdered = Boolean(
    !productOrderIsLoading && productOrderData && !productOrderError,
  );

  // Setup duplicate order check for later.
  // Disabled by default, called via refetch
  const duplicateOrderQueryParams = qs.stringify({
    filingId: id,
    includeOrganisationOrders: true,
    pageSize: 1,
  });
  const {
    data: duplicateOrderResp,
    error: duplicateOrderError,
    isLoading: duplicateOrderIsLoading,
    refetch: duplicateOrderCheck,
  } = useApi<OrderHistoryResponse>(`/v2/orders?${duplicateOrderQueryParams}`, {
    enabled: false,
    keepPreviousData: false, // Don't use same data with different keys, it flashes the wrong order
    retry: false, // Don't retry on error, it's too slow
    staleTime: 0, // Don't consider old data useful for this check
  });

  const duplicateDialogEnabled =
    featureFlags.features["FilingSearch|WarnDuplicateOrders"] &&
    !duplicateOrderError;

  // If we have a response, is it usable. If we don't there's either:
  //  - no valid previous order
  //  - or we haven't tried to request one yet.
  // Check happens on calling duplicateOrderCheck()
  const havePreviousOrder =
    duplicateOrderResp !== undefined &&
    duplicateOrderResp.data?.orders?.length > 0 &&
    duplicateOrderResp.data.orders[0].status !== "Failed";

  useEffect(() => {
    if (
      duplicateOrderResp &&
      duplicateOrderResp.data?.orders?.length > 0 &&
      duplicateOrderResp.data.orders[0].status === "Pending"
    ) {
      Userpilot.track("Repeat Order Found but Pending");
    }
  }, [duplicateOrderResp]);

  // Only open the dialog if:
  //  - We have a valid order fetched
  //  - The feature flag is enabled
  //  - The dialog hasn't been dismissed previously for this filing (resets on pressing order button)
  const duplicateOrderOpen =
    duplicateDialogEnabled && havePreviousOrder && !duplicateOrderDismissed;

  // Send userpilot event when the dialog opens, but only once per filing
  useEffect(() => {
    if (duplicateOrderOpen) Userpilot.track("Repeat Order Found");
  }, [id, duplicateOrderOpen]);

  useEffect(() => {
    if (customerReferenceOpen) Userpilot.track("Filing Reference Opened");
  }, [id, customerReferenceOpen]);

  const duplicateOrderRequested = () => {
    // Duplicate order checks complete
    // Either they want it anyway, they don't have an old one, or there was an error
    setDuplicateOrderDismissed(true);

    // If it costs money and they have the dialog enabled, check there first
    if (creditCost > 0 && !getDisableDialogSetting())
      setCustomerReferenceOpen(true);
    else orderProductCall();
  };

  // Skip the duplicate order box if we searched for an order but none was found
  // OR if there was an error finding it
  if (
    !duplicateOrderDismissed &&
    ((duplicateOrderResp && !havePreviousOrder) || duplicateOrderError)
  ) {
    Userpilot.track("Repeat Order NOT Found");
    duplicateOrderRequested();
  }

  const notEnoughCredits =
    credits && credits.remainingCredits != undefined
      ? credits.remainingCredits < creditCost
      : false;

  const filingOrderButtonClicked = () => {
    Userpilot.track("Filing Button Clicked");
    if (creditCost > 0) {
      if (duplicateDialogEnabled) {
        setDuplicateOrderDismissed(false);
        duplicateOrderCheck();
      } else {
        duplicateOrderRequested();
      }
    } else {
      Userpilot.track("Josh test 0 cost event", {
        mike: "this one",
        something: "else",
        companyName: companyProfile.name,
      });
      orderProductCall();
    }
  };

  const getButtonContent = () => {
    if (productOrderIsLoading || duplicateOrderIsLoading) {
      return (
        <>
          <CircularProgress color="inherit" size="small" />
          Loading...
        </>
      );
    }

    if (productOrdered) return "Ordered";
    if (notEnoughCredits) return "Not enough credits";
    if (creditCost === 0) return "Order";

    return `Order for ${creditCost} Credit${creditCost > 1 ? "s" : ""}`;
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleDisableCustomerReferenceDialog = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDisableCustomerReferenceDialog(event.target.checked);
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "25px",
        borderBottom: `1px solid ${theme.palette.primary.light}`,
      })}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Typography fontSize="1.125rem" fontFamily="Sohne" color="primary.dark">
          {title}
        </Typography>
        <Button
          disabled={
            productOrdered ||
            productOrderIsLoading ||
            duplicateOrderIsLoading ||
            notEnoughCredits
          }
          sx={{
            whiteSpace: "nowrap",
            minWidth: "113px",
            alignSelf: "flex-start",
            marginLeft: 1,
            color: "white",
          }}
          data-testid={"order-button-" + id}
          onClick={filingOrderButtonClicked}
        >
          {getButtonContent()}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingTop: 1,
          paddingBottom: "14px",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <WaitTime
            timeInMinutes={
              deliveryTimeMinutes ? parseInt(deliveryTimeMinutes) : null
            }
          />
          {datePublished && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <CalendarMonth fontSize="small" sx={{ color: mid }} />
              <Typography color={dark} sx={{ fontSize: "0.875rem" }}>
                {toDisplayDate(datePublished)}
              </Typography>
            </Box>
          )}
        </Box>
        {filingSample && (
          <Link
            variant="body2"
            href={filingSample}
            target="_blank"
            color={dark}
            sx={{ textDecoration: "none" }}
          >
            Sample
          </Link>
        )}
      </Box>
      {/* TODO:: Show duplicate order check error here? */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={productOrderError ? "error" : "success"}
          sx={{ width: "100%", color: "white" }}
        >
          {productOrderError
            ? "There was an error."
            : `Ordered "${title}". It’s on its way to your registered email
          address.`}
        </Alert>
      </Snackbar>
      <DuplicateOrderDialog
        dialogOpen={duplicateOrderOpen}
        setDialogDismissed={setDuplicateOrderDismissed}
        doAction={duplicateOrderRequested}
        order={duplicateOrderResp?.data?.orders?.[0]}
      ></DuplicateOrderDialog>
      <CustomerReferenceDialog
        dialogOpen={customerReferenceOpen}
        setDialogOpen={(val: React.SetStateAction<boolean>) => {
          setCustomerReferenceOpen(val);

          setDisableCustomerReferenceDialog(getDisableDialogSetting());
        }}
        creditCost={creditCost}
        customerRef={customerRef}
        setCustomerRef={setCustomerRef}
        disableDialog={disableCustomerReferenceDialog}
        handleDisableDialog={handleDisableCustomerReferenceDialog}
        doAction={orderProductCall}
        persistDisableDialogSetting={() => {
          if (disableCustomerReferenceDialog)
            Userpilot.track("Pop Up 'Dont Show Me Again' set");
          setDisableDialogSetting(disableCustomerReferenceDialog);
        }}
        orderType="document"
      ></CustomerReferenceDialog>
    </Box>
  );
};

export default Filing;
