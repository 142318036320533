import { ISearchResult } from "pages/Search/types";
import { useMemo } from "react";
import { Userpilot } from "userpilot";

const useCompanyResultsFilter = (
  searchResults: ISearchResult[] | undefined,
  filterBy: string,
  isDescending: boolean,
) => {
  const filteredResults = useMemo(() => {
    if (filterBy != "all")
      Userpilot.track("Filtered Global Search Results By Country");

    return searchResults?.filter(
      (f) => filterBy === "all" || f.countryISO === filterBy,
    );
  }, [searchResults, filterBy]);

  if (!isDescending && filteredResults) {
    return filteredResults.reverse();
  }

  return filteredResults;
};

export default useCompanyResultsFilter;
