import { Clear, Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  IconButton,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import { lightest, middark } from "../../pages/variables";
import React from "react";

type Props = {
  onSearchInput: (input: string) => void;
  hasClearInputButton?: boolean;
  textInputProps?: StandardTextFieldProps;
};

const Search: React.FC<Props> = ({
  onSearchInput,
  hasClearInputButton,
  textInputProps,
}): JSX.Element => {
  const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchInput(event.target.value);
  };

  const onResetInput = () => {
    (document.getElementById("textfield") as HTMLInputElement).value = "";
    onSearchInput("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <SearchIcon sx={{ color: "primary.dark" }} />
      <TextField
        id="textfield"
        size="small"
        InputProps={{
          "aria-label": "search-filings",
          disableUnderline: true,
        }}
        variant="standard"
        sx={{
          width: 200,
          margin: 2,
          backgroundColor: lightest,
          "& .MuiInput-root": {
            fontSize: "0.875rem",
            fontWeight: 500,
            "& :placeholder": {
              color: middark,
            },
          },
        }}
        onChange={onChangeEvent}
        {...textInputProps}
      />
      {hasClearInputButton && (
        <IconButton
          aria-label="clear-date-filter"
          sx={{ alignSelf: "center" }}
          onClick={(e) => {
            e.stopPropagation();
            onResetInput();
          }}
        >
          <Clear sx={{ color: "primary.dark" }} fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default Search;
