import React from "react";
import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "components/Button/Button";
import { secondary } from "pages/variables";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { Userpilot } from "userpilot";

type Props = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  creditCost: number | undefined;
  customerRef: string;
  setCustomerRef: (value: string) => void;
  disableDialog: boolean;
  handleDisableDialog: (event: React.ChangeEvent<HTMLInputElement>) => void;
  doAction: () => void;
  persistDisableDialogSetting: () => void;
  orderType: string;
};

const CustomerReferenceDialog: React.FC<Props> = ({
  dialogOpen,
  setDialogOpen,
  creditCost,
  //   customerRef,
  setCustomerRef,
  disableDialog,
  handleDisableDialog,
  doAction,
  persistDisableDialogSetting,
  orderType,
}) => {
  const handleCloseDialog = () => {
    Userpilot.track("Dismiss Filing Reference Pop Up");
    setDialogOpen(false);
  };
  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setCustomerRef(e.target.value);
  };
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      data-testid="filing-customer-reference-dialog"
    >
      <DialogContent
        sx={{
          fontFamily: "Anthro",
          color: "primary.dark",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "1.3rem",
            fontWeight: 600,
            mb: 1,
          }}
        >
          Are you sure you want to order this {orderType}?
        </Typography>
        <Typography sx={{ fontSize: "1.1rem" }}>
          Ordering it will deduct
          <span style={{ fontWeight: 600 }}> {creditCost ?? ""} credits </span>
          from your balance.
        </Typography>
        <FormGroup>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              mt: 4,
            }}
          >
            <Typography
              sx={{
                mb: 1,
              }}
            >
              Add a Customer Reference (optional)
            </Typography>
            <TextField
              id="outlined-size-small"
              onChange={onChange}
              size="small"
              helperText="This will appear in the order history and usage report."
              FormHelperTextProps={{ style: { textAlign: "center" } }}
              inputProps={{ style: { fontSize: "0.874rem" } }}
              InputLabelProps={{ style: { fontSize: "0.874rem" } }}
              fullWidth
            />
          </Box>
          <FormControlLabel
            sx={{
              mt: 2,
              mb: 2,
              ml: "auto",
              mr: "auto",
            }}
            control={
              <Checkbox
                checked={disableDialog}
                onChange={handleDisableDialog}
                style={{ color: secondary }}
              />
            }
            label="Don't show me this again"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions
        sx={{
          mt: -2,
          paddingRight: 2,
          paddingBottom: 2,
          paddingLeft: 2,
          paddingTop: 0,
        }}
      >
        <FormGroup
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 2,
            width: "100%",
          }}
        >
          <Button
            autoFocus
            onClick={handleCloseDialog}
            sx={{
              backgroundColor: "primary.light",
              color: "primary.dark",
              "&:hover": {
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="filing-dialog-order-button"
            onClick={() => {
              Userpilot.track("Filing Button Clicked");
              doAction();
              persistDisableDialogSetting();
              handleCloseDialog();
            }}
          >
            Yes, continue
          </Button>
        </FormGroup>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerReferenceDialog;
