import { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import QueryString from "query-string";
import { useApi } from "hooks/useApi";
import { ISearchResponse, ISearchResult } from "./types";
import { Search as SearchIcon } from "@mui/icons-material";
import RetryButton from "components/Button";
import getErrorMessage from "./getErrorMessage";
import SearchResults from "./SearchResults";
import { transform } from "utils/jurisdiction";
import { Userpilot } from "userpilot";

export default function Search(): JSX.Element {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [contentTop, setContentTop] = useState<number>(0);

  const location = useLocation();
  const { getAccessTokenWithPopup } = useAuth0();
  const { search, jurisdiction, searchType, regAuth } = QueryString.parse(
    location.search,
  );

  const auth0Scope = {
    audience: "Kyckr.Dashboard.Backend",
    scope: "search",
  };

  const regAuthParams = regAuth ? `&regAuth=${regAuth}` : "";

  const searchUri = jurisdiction
    ? `/GetSearchResults?searchTerm=${encodeURIComponent(
        search,
      )}&jurisdiction=${jurisdiction}&searchType=${searchType}${regAuthParams}`
    : `/CacheCompanySearch/${encodeURIComponent(search)}${regAuthParams}`;

  const resp = useApi<ISearchResponse>(searchUri);
  const { isLoading, refetch, data } = resp;
  let { error } = resp;

  const getTokenAndTryAgain = async () => {
    await getAccessTokenWithPopup(auth0Scope);
    refetch();
  };

  const searchResults: ISearchResult[] | undefined = data?.searchResults?.map(
    ({ countryISO, ...rest }) => ({
      ...rest,
      countryISO: transform(countryISO),
    }),
  );

  if (!error && searchResults?.length === 0) {
    error = {
      code: 102,
      name: "Empty result set",
      message: "No results",
    };
  }

  if (data && data.errorResponse) {
    error = {
      code: data.errorResponse.code,
      name: "Kyckr error",
      message: data.errorResponse.message,
    };
  }

  useEffect(() => {
    const { current: content } = contentRef;

    if (content) {
      setContentTop(content.getBoundingClientRect().top);
    }
  }, [contentRef]);

  useEffect(() => {
    Userpilot.track("Entered search page");
  }, []);
  return (
    <Box p="20px 70px">
      <Typography variant="h4" fontFamily="Sohne" color="primary.dark">
        Results
      </Typography>
      {isLoading && (
        <Box
          ref={contentRef}
          sx={{
            width: "100%",
            height: `calc(100vh - ${contentTop}px)`,
            marginTop: "-50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
          }}
        >
          <CircularProgress color="success" size="2rem" />
          <Typography variant="caption" color="grey.500" fontWeight={500}>
            Loading...
          </Typography>
        </Box>
      )}
      {error && (
        <Box
          ref={contentRef}
          sx={{
            width: "100%",
            height: `calc(100vh - ${contentTop}px)`,
            marginTop: "-50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SearchIcon sx={{ color: "primary.dark" }} />
          <Typography variant="h6" color="primary.dark" fontWeight={700}>
            Sorry...
          </Typography>
          <Typography
            variant="caption"
            color="grey.500"
            fontWeight={500}
            align="center"
          >
            {getErrorMessage(error.code, search, searchType, jurisdiction)}
          </Typography>
          {jurisdiction != null && jurisdiction != "" && (
            <Typography
              variant="caption"
              color="black"
              fontWeight={600}
              align="center"
              lineHeight={2.5}
            >
              Why not try Global Search in the Country drop down
            </Typography>
          )}
          {error.message === "Consent required" && (
            <RetryButton onClick={getTokenAndTryAgain} sx={{ marginTop: 1 }}>
              Consent to search
            </RetryButton>
          )}
        </Box>
      )}
      {!error && data && searchResults && searchResults.length > 0 && (
        <>
          <Typography
            variant="subtitle2"
            fontFamily="Anthro"
            fontSize={"13px"}
            color="grey.500"
            mb="25px"
          >
            {searchResults.length} results returned
          </Typography>
          <SearchResults
            searchResults={searchResults}
            jurisdiction={jurisdiction as string | undefined}
          />
        </>
      )}
    </Box>
  );
}
