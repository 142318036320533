import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Status from "components/Status/Status";
import Time from "./Time";
import { toDisplayTime } from "../../utils/dates";
import StyledButton from "components/Button";
import { midlight, amber, secondary_mid, red } from "../variables";
import qs from "query-string";
import { OrderStatus, TabPages } from "../constants";
import { Order } from "./types";
import { Userpilot } from "userpilot";

const toNormalisedStatus = (
  status: Order["status"],
): { normalisedStatus: string; statusColour: string } => {
  switch (status) {
    case "Pending":
      return { normalisedStatus: OrderStatus.Pending, statusColour: amber };
    case "Success":
      return {
        normalisedStatus: OrderStatus.Completed,
        statusColour: secondary_mid,
      };
    case "Failed":
      return { normalisedStatus: OrderStatus.Failed, statusColour: red };
    default:
      return {
        normalisedStatus: `Status Unknown`,
        statusColour: secondary_mid,
      };
  }
};

const renderTransaction = (
  order: Order,
  key?: string,
  isSubOrder?: boolean,
) => {
  const { statusColour, normalisedStatus } = toNormalisedStatus(order.status);
  const profileLinkParams = qs.stringify(
    {
      companyCode: order.companyDetails.companyNumber,
      companyName: encodeURIComponent(order.companyDetails.companyName),
    },
    { skipNull: true },
  );

  const url = order.links?.document;
  const openEnhancedProfileTab = () => {
    const win = window.open(
      `/company?jurisdiction=${order.jurisdiction}&${profileLinkParams}${
        url ? "&url=" + encodeURI(url) : ""
      }`,
    );
    win && win.focus();
  };

  const openUboVisualizationTab = () => {
    const componentUrl = `/company?jurisdiction=${
      order.jurisdiction ?? "GB"
    }&companyCode=${order.companyDetails.companyNumber}&treeId=${
      order.orderId
    }&selectedTab=${TabPages.UBO}&companyName=${
      order.companyDetails.companyName
    }`;
    window.open(componentUrl, "_blank");
  };

  return (
    <Box key={key}>
      {isSubOrder && (
        <Box>
          <Typography fontSize="0.75rem" fontWeight="500">
            {order.companyDetails.companyName}
            {isSubOrder &&
            order.companyDetails.companyNumber &&
            order.companyDetails.companyName.indexOf(
              order.companyDetails.companyNumber,
            ) == -1
              ? ` ${order.companyDetails.companyNumber}`
              : ""}
          </Typography>
        </Box>
      )}
      {order.orderId && (
        <Box>
          <Typography fontSize="0.75rem" fontWeight="500">
            Order ID - {order.orderId}
          </Typography>
        </Box>
      )}

      {order.customerReference && !order.isUboGroupParent && (
        <Box>
          <Typography fontSize="0.75rem" fontWeight="500">
            Customer Reference - {order.customerReference}
          </Typography>
          {}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography fontSize="0.75rem" color="primary.dark">
          {order.productDetails.productCategory}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Status color={statusColour} status={normalisedStatus} />
          <Time time={toDisplayTime(order.orderDate).toString()} />
          {
            <>
              {order.isUboGroupParent
                ? normalisedStatus !== OrderStatus.Failed &&
                  order.relativeOwner === "self" && (
                    <StyledButton
                      sx={{ width: "80px" }}
                      disabled={normalisedStatus === OrderStatus.Pending}
                      onClick={() => {
                        Userpilot.track("View Button Clicked");
                        openUboVisualizationTab();
                      }}
                    >
                      View
                    </StyledButton>
                  )
                : order.jurisdiction !== "DE" &&
                  order.relativeOwner === "self" && (
                    <StyledButton
                      sx={{ width: "80px" }}
                      disabled={normalisedStatus !== OrderStatus.Completed}
                      onClick={() => {
                        Userpilot.track("View Button Clicked");
                        openEnhancedProfileTab();
                      }}
                    >
                      View
                    </StyledButton>
                  )}
              {url != null && url != "" && (
                <StyledButton
                  sx={{ width: "80px" }}
                  disabled={normalisedStatus !== OrderStatus.Completed}
                  onClick={() => {
                    Userpilot.track("Download Button Clicked");
                    const win = window.open(url, "_blank");
                    win && win.focus();
                  }}
                >
                  Download
                </StyledButton>
              )}
            </>
          }
        </Box>
      </Box>
    </Box>
  );
};

type Props = {
  orders: Order[];
};

const CompanyCard = ({ orders }: Props): JSX.Element => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Box
      data-testid={`company-card-${orders[0].companyDetails.companyName}`}
      sx={() => ({
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "flex-start",
        width: "100%",
        marginTop: 5,
        borderBottom: `1px solid ${midlight}`,
      })}
    >
      <Box>
        <Typography
          variant="body1"
          component={"span"}
          fontSize="1.125rem"
          fontWeight="600"
          color="primary.dark"
        >
          {orders[0].companyDetails.companyName}
        </Typography>
      </Box>
      <Typography
        variant="caption"
        color="grey.500"
        fontWeight={500}
        fontSize="0.75rem"
        style={{ wordWrap: "break-word" }}
      >
        {orders[0].companyDetails.companyNumber}
      </Typography>
      {renderTransaction(orders[0], "order0", false)}
      {orders.length > 1 && (
        <>
          <Collapse in={expanded} timeout="auto" sx={{ width: "100%" }}>
            {orders
              .slice(1)
              .map((order, i) => renderTransaction(order, `order${i}`, true))}
          </Collapse>
          <Button
            variant="text"
            color="success"
            onClick={() => setExpanded(!expanded)}
            sx={{ marginBottom: 2, fontSize: "0.75rem" }}
          >
            {expanded ? "Show less" : "Show more"}
          </Button>
        </>
      )}
    </Box>
  );
};

export default CompanyCard;
