import React, { useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useApi } from "hooks/useApi";
import { useUserContext } from "contexts/UserContext";
import { AccountPasswordReset } from "types";
import { Userpilot } from "userpilot";

type Props = {
  onClose: () => void;
  toastHandler: (v: string, f: boolean) => void;
};

const getDisableFilingDialog = () => {
  // Both dialogs are rolled into one setting. Just OR them.
  return JSON.parse(localStorage.getItem("disableFilingDialog") || "false");
};

const Settings: React.FC<Props> = ({ onClose, toastHandler }) => {
  const { refetch: resetPassword } = useApi("/accounts/own/password/reset", {
    enabled: false,
    retry: false,
    onSuccess: (str: AccountPasswordReset) => {
      toastHandler(str.message, false);
    },
    onError: (error: string | Error) => {
      const str =
        typeof error === "object" ? error?.message : (error as string);
      toastHandler(str, true);
    },
  });
  const { logout } = useUserContext();
  const [disableFilingDialog, setDisableFilingDialog] = useState<boolean>(
    getDisableFilingDialog(),
  );
  const resetDisableFilingDialog = (e: React.ChangeEvent, checked: boolean) => {
    localStorage.setItem("disableFilingDialog", `${!checked}`);

    setDisableFilingDialog(!checked);
  };
  return (
    <Box p="40px 10px 0px 32px" width="20vw">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize="1.125rem" fontFamily="Sohne" color="primary.dark">
          Settings
        </Typography>
        <IconButton
          aria-label="close-settings"
          sx={{ alignSelf: "center" }}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <Clear sx={{ color: "primary.dark" }} fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 1, marginTop: 3 }}
      >
        <Button
          variant="text"
          sx={{ textTransform: "none", maxWidth: "fit-content" }}
          onClick={async () => {
            Userpilot.track("Requested Password Reset");
            resetPassword();
          }}
        >
          <Typography color="primary.dark">Request new password</Typography>
        </Button>
        <FormControlLabel
          label="Order pop up enabled"
          control={
            <Switch
              checked={!disableFilingDialog}
              onChange={resetDisableFilingDialog}
              sx={{
                marginLeft: "20px",
                label: {
                  zIndex: 1,
                  marginLeft: "0",
                },
                input: {
                  border: "1px solid rgb(211, 217, 219)",
                  borderRadius: "8px",
                  marginLeft: "0",
                },
              }}
            />
          }
        />
        <Button
          variant="text"
          sx={{ textTransform: "none", maxWidth: "fit-content" }}
          onClick={() => {
            Userpilot.track("Log Out Clicked");
            logout({ returnTo: window.location.origin });
          }}
        >
          <Typography color="primary.dark">Logout</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Settings;
