import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import UnwrapStructureButton from "components/Button/Button";
import { secondary } from "pages/variables";
import { Dispatch, SetStateAction } from "react";

type Props = {
  setCurrentTabValue: Dispatch<SetStateAction<string>>;
};

const Banner = ({ setCurrentTabValue }: Props): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        backgroundColor: "primary.light",
        marginBottom: "20px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item alignItems="center" justifyContent="center" display="flex">
          <UnwrapStructureButton
            sx={{
              backgroundColor: secondary,
              mx: "auto",
              marginLeft: 2,
            }}
            onClick={() => setCurrentTabValue("ubo-verify")}
          >
            Unwrap Ownership
          </UnwrapStructureButton>
        </Grid>
        <Grid item xs={10}>
          <Typography
            color={"primary.dark"}
            fontSize="0.85rem"
            sx={{
              padding: "20px",
              fontWeight: 500,
              display: "flex",
              marginRight: "120px",
            }}
          >
            Generate an interactive ownership tree that automates the analysis
            and calculates the ultimate beneficial ownership in seconds, not
            hours, using primary source data.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Banner;
