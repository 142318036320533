import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import { Box, Typography, SxProps } from "@mui/material";

type Props = {
  message: string;
  sx?: SxProps;
};

const ErrorMessage: React.FC<Props> = ({ message, sx }): JSX.Element => {
  return (
    <Box sx={sx ? sx : { display: "flex", alignItems: "center", gap: 2 }}>
      <ErrorOutline color="error" />
      <Typography color="error" fontSize="0.75rem">
        {message}
      </Typography>
    </Box>
  );
};

export default ErrorMessage;
