import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QueryString from "query-string";
import { Clear, Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  capitalize,
  IconButton,
  SxProps,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "components/Button";

import { IJurisdiction, jurisdictions } from "../../jurisdictions";

import {
  excludedJurisdictions,
  highlightedJurisdictions,
  manualRetrievalJurisdictions,
} from "./specialJurisdictions";

import { SelectInput, TextInput } from "components/Form";

import { RecentSearch } from "types";
import { Userpilot } from "userpilot";

type SearchOption = { label: string; data: string };

type JurOption = RecentSearch["jurisdiction"];

type FormInputs = {
  search: string;
  jurisdiction: JurOption;
  searchType: SearchOption;
};

const schema = yup
  .object()
  .shape({
    search: yup.string().required("Company is required"),
    jurisdiction: yup.mixed().required("Jurisdiction is required"),
    searchType: yup.mixed().required("Search type required"),
  })
  .required();

type Props = {
  sx?: SxProps;
};

export default function CompanySearch({ sx }: Props): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  // read the url jurisdiction to prepopulate the country field
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const searchHandler = (
    search: string,
    jurOption: JurOption,
    searchType: string,
  ) => {
    const { isoAlpha2Code: jurisdiction, regAuthCode: regAuth } = jurOption;
    const recentSearches: RecentSearch[] = JSON.parse(
      localStorage.getItem("recentSearches") || "[]",
    );

    localStorage.setItem(
      "recentSearches",
      JSON.stringify([
        {
          search,
          jurisdiction: jurOption,
          searchType,
          ...(regAuth && { regAuth }),
        },
        ...recentSearches,
      ]),
    );

    localStorage.setItem("isGlobalSearch", JSON.stringify(isGlobalSearch));
    if (isGlobalSearch) Userpilot.track("Global Search Used");

    // assume global search if jurisdiction is null
    if (jurisdiction !== "null") {
      if (manualRetrievalJurisdictions.includes(jurisdiction)) {
        navigate(
          `/manual-retrieval?search=${encodeURIComponent(
            search,
          )}&jurisdiction=${jurisdiction}&searchType=${searchType}`,
          { replace: true, state: jurOption },
        );
      } else {
        const regAuthParams = regAuth ? `&regAuth=${regAuth}` : "";
        navigate(
          `/search?search=${encodeURIComponent(
            search,
          )}&jurisdiction=${jurisdiction}&searchType=${searchType}${regAuthParams}`,
          { replace: true, state: jurOption },
        );
      }
    } else {
      const regAuthParams = regAuth ? `&regAuth=${regAuth}` : "";
      navigate(
        `/search?search=${encodeURIComponent(
          search,
        )}&searchType=name${regAuthParams}`,
        { replace: true, state: jurOption },
      );
    }
  };

  // initially filter jurisdictions then remove excluded subjurisdictions if any (e.g. US and CA)
  const filteredJurisdictions = jurisdictions
    .filter(
      (jurisdiction) =>
        !excludedJurisdictions.includes(jurisdiction.isoAlpha2Code),
    )
    .map((jurisdiction) => {
      return {
        ...jurisdiction,
        subJurisdictions: jurisdiction.subJurisdictions?.filter(
          (s) => !excludedJurisdictions.includes(s.isoAlpha2Code),
        ),
      };
    });

  const topJurisdictions = filteredJurisdictions.filter((jurisdiction) =>
    highlightedJurisdictions.includes(jurisdiction.isoAlpha2Code),
  );

  const orderByHighlighted = (arr: IJurisdiction[]) => {
    const ordered: IJurisdiction[] = [];
    highlightedJurisdictions.forEach((element) => {
      const currentElement = arr.find((x) => x.isoAlpha2Code == element);
      if (currentElement != undefined) {
        ordered.push(currentElement);
      }
    });
    return ordered;
  };

  const bottomJurisdictions = filteredJurisdictions.filter(
    (jurisdiction) => !topJurisdictions.includes(jurisdiction),
  );

  const jurisdictionToOption = (
    acc: JurOption[],
    { name, isoAlpha2Code, subJurisdictions }: IJurisdiction,
  ) => {
    if (subJurisdictions) {
      return [
        ...acc,
        { name, type: "", isoAlpha2Code },
        ...subJurisdictions.map(
          ({ name: subName, isoAlpha2Code, regAuthCode }) => ({
            name: subName,
            isoAlpha2Code,
            type: `${name}:`,
            regAuthCode,
          }),
        ),
      ];
    }

    return [...acc, { name, type: "", isoAlpha2Code }] as JurOption[];
  };

  const globalSearchDisplayMessage = "Global Search";

  const options = useMemo(
    () => [
      { name: globalSearchDisplayMessage, isoAlpha2Code: "null" },
      ...orderByHighlighted(topJurisdictions).reduce(jurisdictionToOption, []),
      { name: "---", isoAlpha2Code: "null" },
      ...bottomJurisdictions.reduce(jurisdictionToOption, []),
    ],
    [topJurisdictions, bottomJurisdictions],
  );

  const passedOption = location.state as JurOption;
  const shouldPrepopulate =
    location?.pathname?.includes("search") ||
    location?.pathname?.includes("manual-retrieval");

  let defaultSearchValue = shouldPrepopulate
    ? QueryString.parse(location.search).search
    : "";

  // set country if jurisdiction available
  const jurisdiction = query.get("jurisdiction");
  let defaultOption = options[1];
  if (jurisdiction) {
    defaultOption =
      options.find((x) => x.isoAlpha2Code == jurisdiction) || defaultOption;
  }

  const defaultJurOption: JurOption =
    shouldPrepopulate && !jurisdiction ? passedOption : defaultOption;

  const { searchType } = QueryString.parse(location.search);
  let defaultSearchTypeOption = shouldPrepopulate
    ? { label: capitalize(searchType), data: searchType }
    : { label: "Name", data: "name" };

  // set company code and search type number if companyCode available
  const companyCode = query.get("companyCode");
  if (companyCode) {
    defaultSearchValue = companyCode;
    defaultSearchTypeOption = { label: "Number", data: "number" };
  }

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      search: defaultSearchValue,
      jurisdiction: defaultJurOption,
      searchType: defaultSearchTypeOption,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ search, jurisdiction, searchType }: FormInputs) => {
    searchHandler(search, jurisdiction, searchType.data);
  };

  const onError = () => {
    console.error(errors);
  };

  const [isGlobalSearch, setIsGlobalSearch] = useState<boolean>(() => {
    const gs: boolean = JSON.parse(
      localStorage.getItem("isGlobalSearch") || "null",
    );

    if (gs == null) {
      return defaultJurOption.name === globalSearchDisplayMessage;
    }

    return gs;
  });

  useEffect(() => {
    localStorage.setItem("isGlobalSearch", JSON.stringify(false));
  }, []);

  const handleJurisdictionDropdownOptions = (
    event: React.SyntheticEvent | Event,
    value: JurOption,
  ) => {
    event.preventDefault();
    setIsGlobalSearch(
      value?.name === globalSearchDisplayMessage ? true : false,
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Box
        sx={(theme) => ({
          display: "grid",
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
          },
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "1fr min-content",
          },
          alignContent: "start",
          justifyContent: "space-between",
          borderBottom: `1px solid ${theme.palette.primary.light}`,
          sx,
        })}
      >
        <Box
          sx={() => ({
            display: "flex",
            justifySelf: "start",
            alignSelf: "center",
            width: "100%",
          })}
        >
          <SearchIcon sx={{ alignSelf: "center", color: "primary.dark" }} />
          <TextInput<FormInputs>
            name="search"
            InputProps={{ "aria-label": "search", disableUnderline: true }}
            control={control}
            id="search-input"
            placeholder={
              "Search by company name" + (!isGlobalSearch ? " or number" : "")
            }
            sx={{ minWidth: "16rem", width: "100%" }}
          />
          <IconButton
            aria-label="clear-date-filter"
            sx={{ alignSelf: "center" }}
            onClick={(e) => {
              e.stopPropagation();
              setValue("search", "");
            }}
          >
            <Clear sx={{ color: "primary.dark" }} fontSize="small" />
          </IconButton>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexWrap: "nowrap",
            alignSelf: "center",
            [theme.breakpoints.down("sm")]: {
              justifySelf: "start",
            },
            [theme.breakpoints.up("sm")]: {
              justifySelf: "end",
            },
          })}
        >
          <SelectInput
            name="jurisdiction"
            control={control}
            label="Country"
            sx={{ marginLeft: 3, minWidth: "16rem" }}
            options={options}
            onChange={handleJurisdictionDropdownOptions}
            getOptionLabel={(option) => {
              return option.type === "" ? option.name : ` ${option.name}`;
            }}
            groupBy={(option) => option.type ?? ""}
            getOptionDisabled={(option) => {
              return (
                (option.name !== globalSearchDisplayMessage &&
                  option.isoAlpha2Code === "null") ||
                option.isoAlpha2Code == "US"
              );
            }}
          />
          {!isGlobalSearch && (
            <SelectInput
              name="searchType"
              control={control}
              label="Search type"
              sx={{ marginLeft: 1, minWidth: "8rem" }}
              options={[
                { label: "Name", data: "name" },
                { label: "Number", data: "number" },
              ]}
              getOptionLabel={(option) => option.label}
            />
          )}
          <Button
            aria-label="search"
            sx={{ marginLeft: 1, minWidth: "8rem" }}
            onClick={() => Userpilot.track("Search Button Clicked")}
            type={"submit"}
          >
            Search
          </Button>
        </Box>
      </Box>
      {isGlobalSearch && (
        <Typography variant="caption" color="primary.dark" fontWeight={500}>
          Global search uses a database of registry data powered by
          OpenCorporates. Please be advised that search results are not live.
        </Typography>
      )}
    </form>
  );
}
