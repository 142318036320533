import { Box, IconButton } from "@mui/material";
import { SwapVert } from "@mui/icons-material";
import { SelectInput } from "components/Form";
import { Control } from "react-hook-form";

type Props = {
  // TODO:: This shoul be using Generics, not Any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  options: { name: string; isoAlpha2Code: string; type: string }[];
  onAscDescHandler: () => void;
  isDescending: boolean;
  inputWidth: string;
};

const FilterSelect = ({
  control,
  options,
  onAscDescHandler,
  isDescending,
  inputWidth,
}: Props): JSX.Element => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <IconButton aria-label="sort-button" onClick={onAscDescHandler}>
        <SwapVert
          fontSize="small"
          sx={{
            "&.MuiSvgIcon-root": {
              transform: `${
                !isDescending ? "rotateY(180deg)" : "rotateY(0deg)"
              }`,
            },
          }}
        />
      </IconButton>

      <Box>
        <SelectInput
          options={options}
          label="Filter by"
          name="filterBy"
          control={control}
          sx={{ marginLeft: 3, minWidth: inputWidth }}
          disableClearable
          getOptionLabel={(option) => option.name}
          groupBy={(option) => option.type}
        />
      </Box>
    </Box>
  );
};

export default FilterSelect;
