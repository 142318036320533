import { Box, Fab, Popover } from "@mui/material";
import { StyledTooltip } from "components/StyledTooltip/StyledTooltip";
import { primary, primary_dark, white } from "pages/variables";
import React, { ReactElement, ReactNode } from "react";

type Props = {
  icon: ReactElement;
  tooltipTitle: string;
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  setPopperOpen?: (value: boolean) => void;
  popperOpen?: boolean;
  disabled?: boolean;
};

const FloatingButtonMenu: React.FC<Props> = ({
  icon,
  tooltipTitle,
  children,
  onClick,
  setPopperOpen,
  popperOpen,
  disabled,
}): JSX.Element => {
  const [tooltipOpen, setToolTipOpen] = React.useState<boolean>(false);
  const [localPopperOpen, setLocalPopperOpen] = React.useState<boolean>(false);
  const buttonRef = React.useRef();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setToolTipOpen(false);
    if (onClick) {
      onClick(event);
    } else {
      popperOpen ? handleStateChange(false) : handleStateChange(true);
    }
  };

  const handleStateChange = (value: boolean) => {
    setPopperOpen ? setPopperOpen(value) : setLocalPopperOpen(value);
  };

  return (
    <Box ref={buttonRef} sx={{ margin: "10px" }}>
      <StyledTooltip
        title={tooltipTitle}
        arrow
        placement={"left"}
        onOpen={() => setToolTipOpen(!popperOpen && true)}
        onClose={() => setToolTipOpen(false)}
        open={tooltipOpen}
      >
        <Fab
          onClick={(event) => handleClick(event)}
          disabled={disabled}
          sx={{
            display: "flex",
            alignItems: "center",
            color: popperOpen ? white : primary,
            backgroundColor: popperOpen ? primary : white,
            ":hover": {
              backgroundColor: popperOpen ? primary_dark : undefined,
            },
          }}
        >
          {icon}
        </Fab>
      </StyledTooltip>
      {children ? (
        <Popover
          open={popperOpen ? popperOpen : localPopperOpen}
          anchorEl={buttonRef.current}
          anchorOrigin={{
            vertical: 0,
            horizontal: -28,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          disablePortal={true}
          sx={{ position: "unset" }}
          PaperProps={{
            sx: {
              overflow: "visible",
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 28,
                right: -9,
                width: 20,
                height: 20,
                backgroundColor: "inherit",
                transform: "translateY(-50%) rotate(45deg)",
                boxShadow: "3px -3px 5px -2px rgba(0,0,0,0.1)",
              },
            },
          }}
          onClose={() => handleStateChange(false)}
          disableScrollLock
        >
          {children}
        </Popover>
      ) : null}
    </Box>
  );
};

export { FloatingButtonMenu };
