import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import UnwrapButton from "components/Button/Button";
import React, { useCallback, useEffect, useState } from "react";
import { lightest, red } from "../../pages/variables";
import { ErrorOutline, ThumbUp } from "@mui/icons-material";
import {
  defaultMaxCreditCost_const,
  defaultStartCostRate_const,
  defaultUnwrapFee_const,
  uboStatuses,
} from "uboConstants";
import { useCreditContext } from "contexts/CreditContext";
import { IOwnershipTreeResponse } from "pages/Company/Tabs/Ubo/types";
import { useCompanyContext } from "contexts/CompanyContext/CompanyContext";
import { Userpilot } from "userpilot";

type Props = {
  onUnwrap: (maxCost: string, maxLayers: string | null, uboThr: string) => void;
  enableUnwrapButton: boolean;
  sx?: SxProps;
  uboNonSuccessStatus: string | undefined;
  uboUnavailable: boolean;
  ownershipTree: IOwnershipTreeResponse | undefined;
  forceHideSuccessMessage: boolean;
};

const defaultUboVal = "25";
const minLimitCost = 1;
const maxLimitCost = 50;
const maxCostMessageRequired = "Max credit cost required"; // Error 1
const maxCostMessageInvalid = `Please enter a number between ${minLimitCost} and ${maxLimitCost}`; // Error 2
const maxCostMessageHigherThanRemainingCredits =
  "Not enough credits to perform unwrap."; // Error 3
const maxCostMessageLowerThanEnhancedCost =
  "Maximum credit cost is lower than the initial unwrap."; // Error 4
const maxLayersMessageInvalid = "Please enter a number between 3 and 50";
const SearchUbo: React.FC<Props> = ({
  onUnwrap,
  enableUnwrapButton,
  sx,
  uboNonSuccessStatus,
  uboUnavailable,
  ownershipTree,
  forceHideSuccessMessage,
}): JSX.Element => {
  const { credits } = useCreditContext();
  const { enhancedProfileCost } = useCompanyContext();
  const enhancedProfileWithUnwrapFee = // keep into consideration the cost of unwrap fee (2 credits atm)
    (enhancedProfileCost ?? defaultMaxCreditCost_const) +
    defaultUnwrapFee_const;
  const defaultMaxCreditCost = enhancedProfileCost
    ? (
        enhancedProfileCost * defaultStartCostRate_const +
        defaultUnwrapFee_const * defaultStartCostRate_const
      ).toString() // default cost should be 3 times the root profile + 3 times the unwrap fee
    : defaultMaxCreditCost_const.toString();
  const defaultMaxLayers = null;
  const [maxCostError, setStateMaxCostError] = useState(0);
  const [maxCreditCost, setStateMaxCreditCost] = useState(defaultMaxCreditCost);
  const [maxLayersError, setStateMaxLayersError] = useState(0);
  const [maxLayers, setStateMaxLayers] = useState<string | null>(
    defaultMaxLayers,
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(true);

  const isMaxCostLowerThanEnhancedCost = useCallback(
    (maxCost: number) => {
      if (!enhancedProfileCost) {
        return false;
      }

      return maxCost < enhancedProfileWithUnwrapFee;
    },
    [enhancedProfileCost, enhancedProfileWithUnwrapFee],
  );

  const isMaxCostHigherThanRemainingCredits = useCallback(
    (maxCost: number) => {
      if (!credits || !credits?.remainingCredits) {
        return false;
      }

      return Number(maxCost) > credits?.remainingCredits;
    },
    [credits],
  );

  // const isCreditsAvailable = useCallback(() => {
  //   if (!credits || !credits?.remainingCredits || !enhancedProfileCost) {
  //     return false;
  //   }

  //   return (
  //     credits?.remainingCredits >= Number(maxCreditCost) &&
  //     Number(maxCreditCost) >= enhancedProfileWithUnwrapFee
  //   );
  // }, [credits, maxCreditCost, enhancedProfileCost]);

  const setMaxCreditCost = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;

    if (isEmptyOrWhiteSpace(val)) {
      setStateMaxCostError(1); //Error Message 1
      return;
    }

    if (!isNumeric(val) || val.includes(".")) {
      setStateMaxCostError(2); //Error Message 1
      return;
    }

    const numberVal = Number(val);

    if (isMaxCostHigherThanRemainingCredits(numberVal)) {
      setStateMaxCreditCost(Number(val).toString());
      setStateMaxCostError(3);
      return;
    }

    if (isMaxCostLowerThanEnhancedCost(numberVal)) {
      setStateMaxCreditCost(Number(val).toString());
      setStateMaxCostError(4);
      return;
    }

    if (numberVal < minLimitCost || numberVal > maxLimitCost) {
      setStateMaxCostError(2); //Error Message 2
      return;
    }

    Userpilot.track("Max Credits Changed");

    setStateMaxCostError(0); //No Error Message
    setStateMaxCreditCost(Number(val).toString()); //remove leading zeroes
  };

  const setMaxLayers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;

    if (!val || val === "0") {
      setStateMaxLayersError(0);
      setStateMaxLayers(null);
      return;
    }

    if (!isNumeric(val) || val.includes(".")) {
      setStateMaxLayersError(1); //Error Message 1
      return;
    }

    const numberVal = Number(val);

    if (numberVal > 50 || numberVal < 3) {
      setStateMaxLayersError(1);
      return;
    }

    Userpilot.track("Max Layers Changed");

    setStateMaxLayersError(0); //No Error Message
    setStateMaxLayers(Number(val).toString()); //remove leading zeroes
  };
  useEffect(() => {
    if (isMaxCostHigherThanRemainingCredits(Number(defaultMaxCreditCost))) {
      setStateMaxCostError(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // initial render only

  const isNumeric = (str: string | unknown) => {
    if (typeof str != "string") {
      return false;
    }

    // use type coercion to parse the _entirety_ of the string
    // ...and ensure strings of whitespace fail
    return !isNaN(Number(str)) && !isNaN(parseFloat(str));
  };

  const isEmptyOrWhiteSpace = (str: string) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  const [uboThreshold, setStateUboThreshold] = useState(defaultUboVal);
  const setUboThreshold = (event: SelectChangeEvent<string>) => {
    Userpilot.track("UBO Threshold Changed");

    setStateUboThreshold(event.target.value);
  };

  const actionUnwrap = () => {
    if (maxCostError || maxLayersError) {
      return;
    }

    Userpilot.track("UBO Unwrap Button Clicked");

    onUnwrap(maxCreditCost, maxLayers, uboThreshold);
  };

  const inputCommonStyle = {
    backgroundColor: "primary.light",
    borderRadius: 2,
    padding: 0.5,
    mr: 1.35,
  };

  const inputTextCommonStyle = {
    fontWeight: 600,
    fontSize: "0.75rem",
    verticalAlign: "center",
  };

  const errorLabelCommonStyle = {
    marginTop: 5,
    overflow: "visible",
    color: red,
    whiteSpace: "nowrap",
    width: 300,
    fontSize: "0.75rem",
    fontWeight: "300",
    fontFamily:
      "Anthro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif,Sohne; !important",
  };

  const constructUboErrorMessage = (status: string | undefined) => {
    const uboStatus = uboStatuses.find((s) => s.status === status);
    return uboStatus
      ? uboStatus.message
      : uboStatuses.find((s) => s.status === "SERVICE_UNAVAILABLE")?.message;
  };

  const [uboErrorMessage, setUboErrorMessage] = useState<string | undefined>(
    "",
  );

  useEffect(() => {
    if (uboNonSuccessStatus || uboUnavailable) {
      setUboErrorMessage(
        constructUboErrorMessage(
          uboNonSuccessStatus ?? ownershipTree?.ownershipTree.status,
        ),
      );
    }
  }, [
    uboNonSuccessStatus,
    setUboErrorMessage,
    ownershipTree?.ownershipTree.status,
    uboUnavailable,
  ]);

  useEffect(() => {
    if (maxCostError != 0 || maxLayersError != 0) {
      setShowSuccessMessage(false);
    }
  }, [maxCostError, maxLayersError]);

  useEffect(() => {
    if (ownershipTree?.ownershipTree?.status !== "ROOT") {
      setShowSuccessMessage(true);
    }
  }, [ownershipTree]);

  const showMessageTreeGenerationSuccess = () => {
    return (
      ownershipTree?.ownershipTree.status !== "ROOT" &&
      showSuccessMessage &&
      !forceHideSuccessMessage
    );
  };

  return (
    <Box sx={{ display: "inline-block" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          position: "relative",
          ...sx,
        }}
      >
        <FormControl>
          <InputLabel
            id="max-credit-cost"
            sx={{
              display: "flex",
              marginTop: -2.3,
              fontFamily:
                "Anthro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif,Sohne;",
              fontWeight: 400,
              fontSize: "0.60rem",
              lineHeight: "1.4375rem",
            }}
          >
            Max Credit Cost*
          </InputLabel>
          {maxCostError === 1 && (
            <InputLabel
              id="max-credit-cost-error"
              sx={{ ...errorLabelCommonStyle }}
            >
              {maxCostMessageRequired}
            </InputLabel>
          )}
          {maxCostError === 2 && (
            <InputLabel
              id="max-credit-cost-error"
              sx={{ ...errorLabelCommonStyle }}
            >
              {maxCostMessageInvalid}
            </InputLabel>
          )}
          {maxCostError === 3 && (
            <InputLabel
              id="max-credit-cost-error"
              sx={{ ...errorLabelCommonStyle }}
            >
              {maxCostMessageHigherThanRemainingCredits}
            </InputLabel>
          )}
          {maxCostError === 4 && (
            <InputLabel
              id="max-credit-cost-error"
              sx={{ ...errorLabelCommonStyle }}
            >
              {maxCostMessageLowerThanEnhancedCost}
            </InputLabel>
          )}
          {maxLayersError === 1 && (
            <InputLabel
              id="max-layers-error"
              sx={{
                ...errorLabelCommonStyle,
                ...(maxCostError !== 0 && { marginTop: 8 }),
              }}
            >
              {maxLayersMessageInvalid}
            </InputLabel>
          )}

          <TextField
            id="max-credit-cost-input"
            inputProps={{
              min: 0,
              style: {
                ...inputTextCommonStyle,
                textAlign: "center",
              },
            }}
            defaultValue={maxCreditCost}
            placeholder={defaultMaxCreditCost}
            onChange={setMaxCreditCost}
            variant="standard"
            sx={{
              ...inputCommonStyle,
              width: 80,
              mt: 2,
              height: "26px",
            }}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </FormControl>
        <FormControl>
          <InputLabel
            id="max-layers"
            sx={{
              display: "flex",
              marginTop: -2.3,
              fontFamily:
                "Anthro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif,Sohne;",
              fontWeight: 400,
              fontSize: "0.60rem",
              lineHeight: "1.4375rem",
            }}
          >
            Max Layers
          </InputLabel>

          <TextField
            id="max-layers-input"
            inputProps={{
              min: 0,
              style: {
                ...inputTextCommonStyle,
                textAlign: "center",
              },
            }}
            defaultValue={maxLayers}
            placeholder={defaultMaxLayers ?? "-"}
            onChange={setMaxLayers}
            variant="standard"
            sx={{
              ...inputCommonStyle,
              width: 80,
              mt: 2,
              height: "26px",
            }}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </FormControl>
        <FormControl>
          <InputLabel
            id="ubo-threshold"
            sx={{ marginTop: 1.6, fontSize: "0.75rem" }}
          >
            UBO Threshold
          </InputLabel>
          <Select
            labelId="ubo-threshold"
            id="ubo-threshold-select"
            required
            onChange={setUboThreshold}
            variant="standard"
            disableUnderline
            value={uboThreshold}
            sx={{
              ...inputCommonStyle,
              ...inputTextCommonStyle,
              width: 100,
            }}
          >
            <MenuItem value={5}>5%</MenuItem>
            <MenuItem value={10}>10%</MenuItem>
            <MenuItem value={15}>15%</MenuItem>
            <MenuItem value={20}>20%</MenuItem>
            <MenuItem value={25}>25%</MenuItem>
          </Select>
        </FormControl>
        <UnwrapButton
          disabled={!enableUnwrapButton}
          onClick={() => actionUnwrap()}
          sx={{
            borderRadius: "4px",
            height: "34px",
            fontWeight: 500,
            marginTop: 1.9,
            paddingTop: "8px",
            paddingInline: "12px",
            textTransform: "capitalize",
            backgroundColor: "primary.main",
            color: "primary.light",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
            fontSize: "12px",
          }}
        >
          Unwrap
        </UnwrapButton>
      </Box>
      {ownershipTree && enableUnwrapButton && (
        <Box
          sx={{
            display: "flex",
            marginY: "40px",
            marginLeft: "15px",
            zIndex: 1,
            position: "relative",
          }}
        >
          <Box>
            {uboNonSuccessStatus ? (
              <>
                {uboErrorMessage && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      backgroundColor: lightest,
                    }}
                  >
                    <ErrorOutline
                      color="error"
                      sx={{
                        verticalAlign: "middle",
                      }}
                    />
                    <Typography color="error" sx={{ fontSize: "0.85em" }}>
                      {uboErrorMessage}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <>
                {showMessageTreeGenerationSuccess() ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      backgroundColor: lightest,
                    }}
                  >
                    <ThumbUp
                      color="success"
                      sx={{
                        verticalAlign: "middle",
                      }}
                    />
                    <Typography
                      color="success.main"
                      sx={{ fontSize: "0.85em" }}
                    >
                      UBO results generated successfully!
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ height: "30px" }} /> // offset to center the clicking with the visual
                )}
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SearchUbo;
