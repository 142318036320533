import React, { useCallback, useContext, useEffect } from "react";
import { useApi } from "hooks/useApi";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Userpilot } from "userpilot";

interface Props {
  children?: React.ReactNode;
}

type Credits = {
  remainingCredits: number | undefined;
  accountCredits: number | undefined;
  validTo: string;
  validFrom: string;
};

type CreditResponse = {
  customerCreditBalance: Credits;
};

type CreditContextValue = {
  credits: Credits | undefined;
  setRemainingCredits: (value: number) => void;
  creditError: Error | null;
  creditLoading: boolean;
  refetchCredits: () => void;
};

export const CreditContext = React.createContext<
  CreditContextValue | undefined
>(undefined);

export function useCreditContext(): CreditContextValue {
  const context = useContext(CreditContext);
  if (context === undefined) {
    throw new Error("useCredit must be used within a CreditContext");
  }

  return context;
}

export const CreditContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [credits, setCredits] = React.useState<Credits | undefined>();
  const setRemainingCredits = useCallback(
    (value: number) => {
      if (credits) {
        setCredits({ ...credits, remainingCredits: value });
      }
    },
    [credits, setCredits],
  );

  const {
    isLoading: creditLoading,
    error: creditError,
    data: creditResponse,
    refetch,
  } = useApi<CreditResponse>("/CreditBalanceInfo", {
    onError: (e: Error) => {
      console.error("Balance fetch error: " + e);
    },
  });

  useEffect(() => {
    if (creditResponse && creditResponse.customerCreditBalance) {
      setCredits(creditResponse.customerCreditBalance);
    } else {
      setCredits(undefined);
    }
  }, [creditResponse]);

  const { isAuthenticated, user } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      if (user && user.email && user.name) {
        const name = user.name;
        Userpilot.identify(user.email, {
          name: name,
          email: user.email,
        });
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  return (
    <CreditContext.Provider
      value={{
        credits,
        setRemainingCredits,
        creditError,
        creditLoading,
        refetchCredits: refetch,
      }}
    >
      {children}
    </CreditContext.Provider>
  );
};
