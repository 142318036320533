import { QueryKey, useMutation, useQueryClient } from "react-query";
import useEditUserFetcher from "./useEditUserFetcher";

export type Inputs = {
  id: string | undefined;
  firstName: string;
  lastName: string;
  role: string;
};

export const useEditUser = (
  toastHandler: (v: string, f: boolean, isEditAction: boolean) => void,
) => {
  const fetcher = useEditUserFetcher();

  const queryClient = useQueryClient();

  return useMutation(
    (params: Inputs) => {
      return fetcher({
        ...params,
      });
    },
    {
      onSuccess: () => {
        const base = `${process.env.REACT_APP_PORTAL_API_URL}`;
        const fullPath = `${base}/accounts/`;
        const queryKey: QueryKey = [fullPath];
        queryClient.refetchQueries(queryKey);
        toastHandler("Successfully edited user.", false, true);
      },
    },
  );
};
