import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch/Switch";
import { GraphOrientation } from "pages/Company/Tabs/Ubo/types";
import ResetLayoutButton from "components/Button";
import Typography from "@mui/material/Typography/Typography";
import PersonIcon from "./PersonIcon";
import CompanyIcon from "./CompanyIcon";
import JointShareholder from "./JointlyHeldShareholder";
import {
  azul,
  black,
  cherry,
  middark,
  primary_dark,
  primary_mid,
  scarlet,
  secondary,
} from "pages/variables";
import RepresentativesIcon from "./RepresentativesIcon";
import CircularOwnershipIcon from "./CircularOwnershipIcon";
import JointOwnershipIcon from "./JointlyHeldOwnershipIcon";
import { Paper } from "@mui/material";
import {
  FileDownloadOutlined,
  InfoOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { FloatingButtonMenu } from "./FloatingButtonMenu";
import { Userpilot } from "userpilot";

const LegendDescription = styled(Typography)({
  fontSize: "1.0rem",
  fontWeight: 500,
  fontFamily: "Anthro",
  marginLeft: "15px",
  alignSelf: "center",
});

const switchTheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked": {
            color: "#fff",
          },
        },
        track: {
          ".Mui-checked.Mui-checked + &": {
            opacity: 1,
            backgroundColor: secondary,
          },
        },
      },
    },
  },
});

type Props = {
  showRepresentatives: boolean;
  toggleRepresentatives: () => void;
  orientation: GraphOrientation;
  toggleOrientation: () => void;
  resetLayout: () => void;
  downloadUrl?: string;
};

const UboDrawer: React.FC<Props> = ({
  showRepresentatives,
  toggleRepresentatives,
  orientation,
  toggleOrientation,
  resetLayout,
  downloadUrl,
}): JSX.Element => {
  const [infoPopper, setInfoPopper] = React.useState<boolean>(false);
  const handleInfoPopper = (value: boolean) => {
    Userpilot.track("UBO Legend Clicked");
    setSettingsPopper(false);
    setInfoPopper(value);
  };
  const [settingsPopper, setSettingsPopper] = React.useState<boolean>(false);
  const handleSettingsPopper = (value: boolean) => {
    setInfoPopper(false);
    setSettingsPopper(value);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        zIndex: 1,
        marginLeft: "auto",
      }}
    >
      <FloatingButtonMenu
        icon={<InfoOutlined />}
        tooltipTitle="Legend"
        popperOpen={infoPopper}
        setPopperOpen={handleInfoPopper}
      >
        <Paper sx={{ padding: 2.5, width: "max-content" }}>
          <Box sx={{ display: "flex" }}>
            <PersonIcon maskBgColor={secondary} />
            <LegendDescription color="primary.dark">UBO</LegendDescription>
          </Box>
          <Box sx={{ display: "flex", mt: 2 }}>
            <PersonIcon maskBgColor={middark} />
            <LegendDescription color="primary.dark">
              Individual Shareholder
            </LegendDescription>
          </Box>
          <Box sx={{ display: "flex", mt: 2 }}>
            <JointShareholder maskBgColor={primary_dark} />
            <LegendDescription color="primary.dark">
              Joint Shareholder
            </LegendDescription>
          </Box>
          <Box sx={{ display: "flex", mt: 2 }}>
            <RepresentativesIcon maskBgColor={primary_mid} />
            <LegendDescription color="primary.dark">
              Representative
            </LegendDescription>
          </Box>
          <Box sx={{ display: "flex", mt: 2 }}>
            <CompanyIcon maskBgColor={azul} strokeColor={black} />
            <LegendDescription color="primary.dark">
              Company Shareholder
            </LegendDescription>
          </Box>
          <Box sx={{ display: "flex", mt: 2, mb: 1 }}>
            <CompanyIcon maskBgColor={cherry} strokeColor={scarlet} />
            <LegendDescription color="primary.dark">
              Non Continuation
            </LegendDescription>
          </Box>
          <Box sx={{ display: "flex", mt: 2, mb: 1 }}>
            <CircularOwnershipIcon />
            <LegendDescription color="primary.dark">
              Circular Ownership
            </LegendDescription>
          </Box>
          <Box sx={{ display: "flex", mt: 2, mb: 1 }}>
            <JointOwnershipIcon />
            <LegendDescription color="primary.dark">
              Jointly Held Ownership
            </LegendDescription>
          </Box>
        </Paper>
      </FloatingButtonMenu>
      <FloatingButtonMenu
        icon={<VisibilityOutlined />}
        tooltipTitle="Visual Display"
        popperOpen={settingsPopper}
        setPopperOpen={handleSettingsPopper}
      >
        <Paper sx={{ padding: 1 }}>
          <List>
            <ListItem
              key={"Show Representatives"}
              disablePadding
              sx={{ display: "block", px: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "initial",
                }}
              >
                <ThemeProvider theme={switchTheme}>
                  <Switch
                    checked={showRepresentatives}
                    onChange={toggleRepresentatives}
                  />
                </ThemeProvider>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontFamily: "Anthro",
                      fontWeight: "500",
                      fontSize: "1.0rem",
                    },
                  }}
                  primary={"Show Representatives"}
                />
              </Box>
            </ListItem>
            <ListItem
              key={"Flip Structure"}
              disablePadding
              sx={{ display: "block", px: 1, pb: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ThemeProvider theme={switchTheme}>
                  <Switch
                    checked={orientation === GraphOrientation.DownUp}
                    onChange={toggleOrientation}
                  />
                </ThemeProvider>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontFamily: "Anthro",
                      fontWeight: "500",
                      fontSize: "1.0rem",
                    },
                  }}
                  primary={"Flip Structure"}
                />
              </Box>
            </ListItem>
            <ListItem
              key={"Reset Visual"}
              disablePadding
              sx={{ display: "block", px: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: "auto",
                    justifyContent: "center",
                    flexGrow: 1,
                  }}
                >
                  <ResetLayoutButton
                    onClick={resetLayout}
                    sx={{
                      borderRadius: "4px",
                      height: "34px",
                      fontWeight: 500,
                      marginTop: 0.65,
                      paddingTop: "8px",
                      paddingInline: "12px",
                      textTransform: "capitalize",
                      backgroundColor: "primary.main",
                      color: "primary.light",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                      fontSize: "12px",
                      flexGrow: 1,
                    }}
                  >
                    Reset visual
                  </ResetLayoutButton>
                </ListItemIcon>
              </Box>
            </ListItem>
          </List>
        </Paper>
      </FloatingButtonMenu>
      {downloadUrl && (
        <FloatingButtonMenu
          icon={<FileDownloadOutlined />}
          tooltipTitle="Download"
          disabled={!downloadUrl}
          onClick={() => {
            Userpilot.track("UBO PDF Download Clicked");
            const win = window.open(downloadUrl, "_blank");
            win && win.focus();
          }}
        />
      )}
    </Box>
  );
};

export default UboDrawer;
