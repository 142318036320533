export const transform = (jurisdiction: string) => {
  if (!jurisdiction) {
    return jurisdiction;
  }

  if (jurisdiction === "CA") {
    jurisdiction = jurisdiction + "-ALL";
  }

  if (jurisdiction.startsWith("AE-")) {
    jurisdiction = "AE";
  }

  return jurisdiction;
};
