import {
  secondary_mid,
  secondary,
  secondary_dark,
  primary_dark,
  primary,
  primary_mid,
  darkest,
  darker,
  dark,
  middark,
  mid,
  midlight,
  secondary_light,
} from "./variables";

export const colors = [
  secondary_mid,
  secondary,
  secondary_dark,
  primary_dark,
  primary,
  primary_mid,
  darkest,
  darker,
  dark,
  middark,
  mid,
  midlight,
  secondary_light,
];
