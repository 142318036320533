import { Box, Typography } from "@mui/material";
import { LocationOnOutlined, CalendarMonthOutlined } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import styled from "styled-components";
import { countries } from "country-data";
import { toDisplayDate } from "utils/dates";
import CompanyButton from "components/Button";
import { dark, mid } from "../variables";
import { jurisdictions } from "jurisdictions";
import Twemoji from "react-twemoji";
import { Userpilot } from "userpilot";

const EmojiFlag = styled(Twemoji)`
  .emoji {
    width: 25px;
    margin-right: 10px;
  }
`;

EmojiFlag.defaultProps = {
  options: {
    base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
  },
};

interface ISearchResultProps {
  companyCode: string;
  companyName: string;
  companyNameInEnglish: string;
  legalStatus: string;
  jurisdiction: string;
  companyNumber: string;
  address?: string;
  incorporationDate: Date | null;
  onViewCompanyClicked: () => void;
}

export default function ResultItem({
  companyCode,
  companyName,
  companyNameInEnglish,
  legalStatus,
  jurisdiction,
  companyNumber,
  address,
  incorporationDate,
  onViewCompanyClicked,
}: ISearchResultProps): JSX.Element {
  const getJurisdictionName = (countryISO: string) => {
    if (countryISO.length > 2) {
      const country = jurisdictions.find((j) =>
        j.isoAlpha2Code.startsWith(countryISO.slice(0, 2)),
      );

      const subJurisdiction = country?.subJurisdictions?.find(
        (j) => j.isoAlpha2Code == countryISO,
      );
      return country?.name && subJurisdiction?.name
        ? `${country.name} - ${subJurisdiction.name}`
        : countryISO;
    } else {
      return countries[countryISO].name;
    }
  };

  const isoAlpha2Code = jurisdiction ? jurisdiction.substring(0, 2) : null;
  const isProfileAvailable = companyCode ? true : false;
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr auto",
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
        },
        paddingY: 1,
        borderBottom: `1px solid ${mid}`,
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "start",
        }}
      >
        <Typography variant="h6" color="primary.dark" fontWeight={700}>
          {companyName}
        </Typography>
        {companyNumber && (
          <Typography variant="caption" color="grey.500" fontWeight={500}>
            {companyNumber}
          </Typography>
        )}
        {companyNameInEnglish && (
          <Typography variant="caption">{companyNameInEnglish}</Typography>
        )}
        <Box
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            [theme.breakpoints.down("sm")]: {
              gridTemplateColumns: "auto auto",
            },
            alignSelf: "start",
            gridColumnGap: 8,
          })}
        >
          {legalStatus && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gridColumnStart: "1 / span 1",
              }}
            >
              <CircleIcon
                sx={{
                  width: "0.5em",
                  height: "0.5em",
                  marginRight: 1,
                  color: "#004257",
                }}
              />
              <Typography variant="caption" color={dark}>
                {legalStatus}
              </Typography>
            </Box>
          )}
          {isoAlpha2Code && (
            <Box
              sx={() => ({
                display: "flex",
                alignItems: "center",
                gridColumnStart: "2 / span 1",
              })}
            >
              <EmojiFlag>{countries[isoAlpha2Code].emoji}</EmojiFlag>
              <Typography variant="caption" color={dark}>
                {getJurisdictionName(jurisdiction)}
              </Typography>
            </Box>
          )}
          {incorporationDate && (
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("sm")]: {
                  gridColumnStart: "span 2",
                },
              })}
            >
              <CalendarMonthOutlined />
              <Typography variant="caption" color={dark}>
                Incorporated {toDisplayDate(incorporationDate)}
              </Typography>
            </Box>
          )}
          {address && (
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("sm")]: {
                  gridColumnStart: "span 2",
                },
              })}
            >
              <LocationOnOutlined />
              <Typography variant="caption" color={dark}>
                {address}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <CompanyButton
        sx={(theme) => ({
          alignSelf: "end",
          [theme.breakpoints.down("sm")]: {
            alignSelf: "start",
          },
        })}
        onClick={() => {
          Userpilot.track("View Company Profile Clicked");
          onViewCompanyClicked();
        }}
        disabled={!isProfileAvailable}
      >
        {isProfileAvailable ? "View Company Profile" : "Profile Unavailable"}
      </CompanyButton>
    </Box>
  );
}
