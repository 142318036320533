import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import OrderHistory from "./OrderHistory";

export default function CompanyContextWrapper(): JSX.Element {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <OrderHistory />
    </LocalizationProvider>
  );
}
