import { Box } from "@mui/material";
import SortSelect from "components/SortSelect";
import { useCompanyContext } from "contexts/CompanyContext";
import { useForm, useWatch } from "react-hook-form";
import Officer from "./Officer";
import useOfficersSort from "./useOfficersSort";

type FormInputs = {
  sortBy: { label: string; data: string };
  isDescending: boolean;
};

const Officers: React.FC = () => {
  const { companyProfile } = useCompanyContext();
  const officers = companyProfile.officers ?? [];

  const shouldShowAppointmentDate = !officers.some((o) => !o.date);
  const sortOptions = [
    ...(shouldShowAppointmentDate
      ? [
          {
            label: "Appointment Date",
            data: "appointment-date",
          },
        ]
      : []),
    { label: "Name", data: "name" },
    { label: "Role", data: "role" },
  ];

  const { control, setValue } = useForm<FormInputs>({
    defaultValues: {
      sortBy: sortOptions[0],
      isDescending: true,
    },
  });

  const sorting = useWatch({ control, name: "sortBy" });
  const isDescending = useWatch({ control, name: "isDescending" });
  const sortedOfficers = useOfficersSort(officers, sorting.data, isDescending);

  const onAscDescHandler = () => {
    setValue("isDescending", !isDescending);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
    >
      <SortSelect
        control={control}
        options={sortOptions}
        onAscDescHandler={onAscDescHandler}
        isDescending={isDescending}
        inputWidth="7rem"
      />
      <Box>
        {sortedOfficers &&
          sortedOfficers.map(({ name, title, address, date }) => (
            <Officer name={name} title={title} address={address} date={date} />
          ))}
      </Box>
    </Box>
  );
};

export default Officers;
