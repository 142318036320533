import {
  Autocomplete,
  FormControl,
  FormHelperText,
  AutocompleteProps,
  TextField,
} from "@mui/material";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

type Props<T extends FieldValues, F extends FieldValues> = {
  label: string;
  name: Path<F>;
  control: Control<F, string | undefined>;
  options: T[];
} & Omit<AutocompleteProps<T, false, true, false>, "renderInput">;

function SelectInput<T extends FieldValues, F extends FieldValues>({
  control,
  name,
  label,
  sx,
  onChange,
  ...rest
}: Props<T, F>): JSX.Element {
  return (
    <FormControl sx={{ ...(Boolean(sx) && sx) }} size="small">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <Autocomplete
                {...field}
                onChange={(e, data) => {
                  field.onChange(data);
                  if (onChange) {
                    onChange(e, data, "selectOption");
                  }
                }}
                ListboxProps={{
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  sx: {
                    fontSize: "0.75rem",
                  },
                }}
                sx={{
                  backgroundColor: "primary.light",
                  border: 0,
                  borderRadius: "5px",
                  fontWeight: 600,
                  "& .MuiAutocomplete-input": {
                    fontWeight: 600,
                  },
                  "& .MuiInputBase-input": {
                    padding: "10px 12px",
                    border: 0,
                    "&:target": {
                      fontWeight: 600,
                    },
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {},
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  fontSize: "0.75rem",
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                  },
                  "& .MuiFormLabel": {
                    fontSize: "0.75rem",
                  },
                  "& .MuiOutlinedInput-input": {
                    fontSize: "0.75rem",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    top: "-5px",
                  },
                  "& .MuiFormLabel-filled": {
                    top: "-5px",
                  },
                }}
                fullWidth
                {...rest}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    size="small"
                  />
                )}
              />
              {error && (
                <FormHelperText sx={{ fontSize: "0.75rem" }} error>
                  {error.message}
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
}

export default SelectInput;
