import { countries } from "country-data";
export interface IJurisdiction {
  name: string;
  emoji: string;
  isoAlpha2Code: string;
  regAuthCode?: string;
  subJurisdictions?: IJurisdiction[];
  nodeCodes?: string[];
}

export const getJurisdictionOrSubJurisdictionName = (code: string) => {
  if (code.length > 2) {
    const country = jurisdictions.find((j) =>
      j.isoAlpha2Code.startsWith(code.slice(0, 2)),
    );

    const subJurisdiction = country?.subJurisdictions?.find(
      (j) => j.isoAlpha2Code == code,
    );
    return subJurisdiction?.name ? subJurisdiction.name : code;
  } else {
    return (
      jurisdictions.find((j) => j.isoAlpha2Code == code)?.name ??
      countries[code].name
    );
  }
};

export const jurisdictions: IJurisdiction[] = [
  {
    isoAlpha2Code: "AL",
    nodeCodes: ["AL_1001"],
    name: "Albania",
    emoji: "🇦🇱",
  },
  {
    isoAlpha2Code: "AR",
    nodeCodes: ["AR_1001"],
    name: "Argentina",
    emoji: "🇦🇷",
  },
  {
    isoAlpha2Code: "AW",
    nodeCodes: ["AW_1001"],
    name: "Aruba",
    emoji: "🇦🇼",
  },
  {
    isoAlpha2Code: "AU",
    nodeCodes: ["AU_1001"],
    name: "Australia",
    emoji: "🇦🇺",
  },
  {
    isoAlpha2Code: "AT",
    nodeCodes: ["AUT_1027"],
    name: "Austria",
    emoji: "🇦🇹",
  },
  {
    isoAlpha2Code: "AZ",
    nodeCodes: ["AZ_1001"],
    name: "Azerbaijan",
    emoji: "🇦🇿",
  },
  {
    isoAlpha2Code: "BH",
    nodeCodes: ["BH_1001"],
    name: "Bahrain",
    emoji: "🇧🇭",
  },
  {
    isoAlpha2Code: "BD",
    nodeCodes: ["BD_1001"],
    name: "Bangladesh",
    emoji: "🇧🇩",
  },
  {
    isoAlpha2Code: "BB",
    nodeCodes: ["BB_1001"],
    name: "Barbados",
    emoji: "🇧🇧",
  },
  {
    isoAlpha2Code: "BY",
    nodeCodes: ["BY_1001"],
    name: "Belarus",
    emoji: "🇧🇾",
  },
  {
    isoAlpha2Code: "BE",
    nodeCodes: ["BEL_1021", "BEL_NG_edbfr1"],
    name: "Belgium",
    emoji: "🇧🇪",
  },
  {
    isoAlpha2Code: "BZ",
    nodeCodes: ["BZ_1001"],
    name: "Belize",
    emoji: "🇧🇿",
  },
  {
    isoAlpha2Code: "BO",
    nodeCodes: ["BO_1001"],
    name: "Bolivia",
    emoji: "🇧🇴",
  },
  {
    name: "Bonaire",
    emoji: "🇧🇶",
    isoAlpha2Code: "BQ",
  },
  {
    isoAlpha2Code: "BA",
    nodeCodes: ["BA_1001"],
    name: "Bosnia and Herzegovina",
    emoji: "🇧🇦",
  },
  {
    isoAlpha2Code: "BW",
    nodeCodes: ["BW_1001"],
    name: "Botswana",
    emoji: "🇧🇼",
  },
  {
    isoAlpha2Code: "BR",
    nodeCodes: ["BR_1001"],
    name: "Brazil",
    emoji: "🇧🇷",
  },
  {
    isoAlpha2Code: "VG",
    nodeCodes: ["VG_1001"],
    name: "British Virgin Islands",
    emoji: "🇻🇬",
  },
  {
    isoAlpha2Code: "BN",
    nodeCodes: ["BN_1001"],
    name: "Brunei",
    emoji: "🇧🇳",
  },
  {
    isoAlpha2Code: "BG",
    nodeCodes: ["BUL_1001"],
    name: "Bulgaria",
    emoji: "🇧🇬",
  },
  {
    isoAlpha2Code: "KH",
    nodeCodes: ["KH_1001"],
    name: "Cambodia",
    emoji: "🇰🇭",
  },
  {
    isoAlpha2Code: "CA-ALL",
    nodeCodes: ["CA_ALL"],
    name: "Canada",
    emoji: "🇨🇦",
    subJurisdictions: [
      {
        name: "Alberta",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-AB",
        nodeCodes: ["CA_AB"],
      },
      {
        name: "British Columbia",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-BC",
        nodeCodes: ["CA_BC"],
      },
      {
        name: "Manitoba",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-MB",
        nodeCodes: ["CA_MB"],
      },
      {
        name: "New Brunswick",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-NB",
        nodeCodes: ["CA_NB"],
      },
      {
        name: "Newfoundland & Labrador",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-NL",
        nodeCodes: ["CA_NL"],
      },
      {
        name: "Northwest Territories",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-NT",
        nodeCodes: ["CA_NT"],
      },
      {
        name: "Nova Scotia",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-NS",
        nodeCodes: ["CA_NS"],
      },
      {
        name: "Nunavut",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-NU",
        nodeCodes: ["CA_NU"],
      },
      {
        name: "Ontario",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-ON",
        nodeCodes: ["CA_ON"],
      },
      {
        name: "Prince Edward Island",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-PE",
        nodeCodes: ["CA_PE"],
      },
      {
        name: "Quebec",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-QC",
        nodeCodes: ["CA_QC"],
      },
      {
        name: "Saskatchewan",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-SK",
        nodeCodes: ["CA_SK"],
      },
      {
        name: "Yukon",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-YU",
        nodeCodes: ["CA_YU"],
      },
      {
        name: "Federal",
        emoji: "🇨🇦",
        isoAlpha2Code: "CA-FE",
        nodeCodes: ["CA_FE"],
      },
    ],
  },
  {
    isoAlpha2Code: "KY",
    nodeCodes: ["KY_1001"],
    name: "Cayman Islands",
    emoji: "🇰🇾",
  },
  {
    isoAlpha2Code: "CN",
    nodeCodes: ["CN_1001"],
    name: "China",
    emoji: "🇨🇳",
  },
  {
    isoAlpha2Code: "CO",
    nodeCodes: ["CO_1001"],
    name: "Colombia",
    emoji: "🇨🇴",
  },
  {
    isoAlpha2Code: "CR",
    nodeCodes: ["CR_1001"],
    name: "Costa Rica",
    emoji: "🇨🇷",
  },
  {
    isoAlpha2Code: "HR",
    nodeCodes: ["HR_1001"],
    name: "Croatia",
    emoji: "🇭🇷",
  },
  {
    isoAlpha2Code: "CW",
    nodeCodes: ["CW_1001"],
    name: "Curacao",
    emoji: "🇨🇼",
  },
  {
    isoAlpha2Code: "CY",
    nodeCodes: ["CY_1001"],
    name: "Cyprus",
    emoji: "🇨🇾",
  },
  {
    isoAlpha2Code: "CZ",
    nodeCodes: ["CZ_PROD"],
    name: "Czech Republic",
    emoji: "🇨🇿",
  },
  {
    isoAlpha2Code: "DK",
    nodeCodes: ["DNK_1024"],
    name: "Denmark",
    emoji: "🇩🇰",
  },
  {
    isoAlpha2Code: "DO",
    nodeCodes: ["DO_1001"],
    name: "Dominican Republic",
    emoji: "🇩🇴",
  },
  {
    isoAlpha2Code: "EC",
    nodeCodes: ["EC_1001"],
    name: "Ecuador",
    emoji: "🇪🇨",
  },
  {
    isoAlpha2Code: "EE",
    nodeCodes: ["EST_1022"],
    name: "Estonia",
    emoji: "🇪🇪",
  },
  {
    isoAlpha2Code: "FI",
    nodeCodes: ["FIN_1020"],
    name: "Finland",
    emoji: "🇫🇮",
  },
  {
    isoAlpha2Code: "FR",
    nodeCodes: ["FRA_1029", "FR_INFOG"],
    name: "France",
    emoji: "🇫🇷",
  },
  {
    isoAlpha2Code: "GE",
    nodeCodes: ["GE_1001"],
    name: "Georgia",
    emoji: "🇬🇪",
  },
  {
    isoAlpha2Code: "DE",
    nodeCodes: ["DEU_1013", "DEU_NG_CREFOT"],
    name: "Germany",
    emoji: "🇩🇪",
  },
  {
    isoAlpha2Code: "GH",
    nodeCodes: ["GH_1001"],
    name: "Ghana",
    emoji: "🇬🇭",
  },
  {
    isoAlpha2Code: "GI",
    nodeCodes: ["GIB_1031"],
    name: "Gibraltar",
    emoji: "🇬🇮",
  },
  {
    isoAlpha2Code: "GG",
    nodeCodes: ["GGY_1028"],
    name: "Guernsey",
    emoji: "🇬🇬",
  },
  {
    isoAlpha2Code: "GR",
    nodeCodes: ["GRC_1003"],
    name: "Greece",
    emoji: "🇬🇷",
  },
  {
    isoAlpha2Code: "HK",
    nodeCodes: ["HK_1001"],
    name: "Hong Kong",
    emoji: "🇭🇰",
  },
  {
    isoAlpha2Code: "HU",
    nodeCodes: ["HU_1001"],
    name: "Hungary",
    emoji: "🇭🇺",
  },
  {
    isoAlpha2Code: "IS",
    nodeCodes: ["IS_PROD"],
    name: "Iceland",
    emoji: "🇮🇸",
  },
  {
    isoAlpha2Code: "IN",
    nodeCodes: ["IN_1001"],
    name: "India",
    emoji: "🇮🇳",
  },
  {
    isoAlpha2Code: "ID",
    nodeCodes: ["ID_1001"],
    name: "Indonesia",
    emoji: "🇮🇩",
  },
  {
    isoAlpha2Code: "IE",
    nodeCodes: ["IRL_1001"],
    name: "Ireland",
    emoji: "🇮🇪",
  },
  {
    isoAlpha2Code: "IM",
    nodeCodes: ["IM_1001"],
    name: "Isle of Man",
    emoji: "🇮🇲",
  },
  {
    isoAlpha2Code: "IL",
    nodeCodes: ["IL_1001"],
    name: "Israel",
    emoji: "🇮🇱",
  },
  {
    isoAlpha2Code: "IT",
    nodeCodes: ["ITA_1018"],
    name: "Italy",
    emoji: "🇮🇹",
  },
  {
    isoAlpha2Code: "JM",
    nodeCodes: ["JM_1001"],
    name: "Jamaica",
    emoji: "🇯🇲",
  },
  {
    isoAlpha2Code: "JP",
    nodeCodes: ["JP_1001"],
    name: "Japan",
    emoji: "🇯🇵",
  },
  {
    isoAlpha2Code: "JE",
    nodeCodes: ["JEY_1016"],
    name: "Jersey",
    emoji: "🇯🇪",
  },
  {
    isoAlpha2Code: "JO",
    nodeCodes: ["JO_1001"],
    name: "Jordan",
    emoji: "🇯🇴",
  },
  {
    name: "Kosovo",
    emoji: "🇽🇰",
    isoAlpha2Code: "XK",
  },
  {
    isoAlpha2Code: "KW",
    nodeCodes: ["KW_1001"],
    name: "Kuwait",
    emoji: "🇰🇼",
  },
  {
    isoAlpha2Code: "LV",
    nodeCodes: ["LVA_1019"],
    name: "Latvia",
    emoji: "🇱🇻",
  },
  {
    isoAlpha2Code: "LS",
    nodeCodes: ["LS_1001"],
    name: "Lesotho",
    emoji: "🇱🇸",
  },
  {
    isoAlpha2Code: "LI",
    nodeCodes: ["LI_1001"],
    name: "Liechtenstein",
    emoji: "🇱🇮",
  },
  {
    isoAlpha2Code: "LT",
    nodeCodes: ["LTU_1014"],
    name: "Lithuania",
    emoji: "🇱🇹",
  },
  {
    isoAlpha2Code: "LU",
    nodeCodes: ["LUX_1012"],
    name: "Luxembourg",
    emoji: "🇱🇺",
  },
  {
    isoAlpha2Code: "MK",
    nodeCodes: ["MKD_1010", "MK_1001"],
    name: "Macedonia",
    emoji: "🇲🇰",
  },
  {
    isoAlpha2Code: "MY",
    nodeCodes: ["MY_1001"],
    name: "Malaysia",
    emoji: "🇲🇾",
  },
  {
    isoAlpha2Code: "MT",
    nodeCodes: ["MLT_1030"],
    name: "Malta",
    emoji: "🇲🇹",
  },
  {
    isoAlpha2Code: "MH",
    nodeCodes: ["MH_1001"],
    name: "Marshall Islands",
    emoji: "🇲🇭",
  },
  {
    isoAlpha2Code: "MU",
    nodeCodes: ["MU_1001"],
    name: "Mauritius",
    emoji: "🇲🇺",
  },
  {
    isoAlpha2Code: "MD",
    nodeCodes: ["MD_1001"],
    name: "Moldova",
    emoji: "🇲🇩",
  },
  {
    isoAlpha2Code: "MC",
    nodeCodes: ["MC_1001"],
    name: "Monaco",
    emoji: "🇲🇨",
  },
  {
    isoAlpha2Code: "ME",
    nodeCodes: ["ME_1001"],
    name: "Montenegro",
    emoji: "🇲🇪",
  },
  {
    isoAlpha2Code: "MA",
    nodeCodes: ["MA_1001"],
    name: "Morocco",
    emoji: "🇲🇦",
  },
  {
    isoAlpha2Code: "MM",
    nodeCodes: ["MM_1001"],
    name: "Myanmar",
    emoji: "🇲🇲",
  },
  {
    isoAlpha2Code: "NP",
    nodeCodes: ["NP_1001"],
    name: "Nepal",
    emoji: "🇳🇵",
  },
  {
    isoAlpha2Code: "NL",
    nodeCodes: ["NLD_1025"],
    name: "Netherlands",
    emoji: "🇳🇱",
  },
  {
    isoAlpha2Code: "NZ",
    nodeCodes: ["AP_NZ", "AP_NEW"],
    name: "New Zealand",
    emoji: "🇳🇿",
  },
  {
    isoAlpha2Code: "NI",
    nodeCodes: ["NI_1001"],
    name: "Nicaragua",
    emoji: "🇳🇮",
  },
  {
    isoAlpha2Code: "NO",
    nodeCodes: ["NOR_1004"],
    name: "Norway",
    emoji: "🇳🇴",
  },
  {
    isoAlpha2Code: "OM",
    nodeCodes: ["OM_1001"],
    name: "Oman",
    emoji: "🇴🇲",
  },
  {
    isoAlpha2Code: "PK",
    nodeCodes: ["PK_1001"],
    name: "Pakistan",
    emoji: "🇵🇰",
  },
  {
    isoAlpha2Code: "PA",
    nodeCodes: ["PA_1001"],
    name: "Panama",
    emoji: "🇵🇦",
  },
  {
    isoAlpha2Code: "PE",
    nodeCodes: ["PE_1001"],
    name: "Peru",
    emoji: "🇵🇪",
  },
  {
    isoAlpha2Code: "PR",
    nodeCodes: ["PR_1001"],
    name: "Puerto Rico",
    emoji: "🇺🇸",
  },
  {
    isoAlpha2Code: "PH",
    nodeCodes: ["PH_1001"],
    name: "Philippines",
    emoji: "🇵🇭",
  },
  {
    isoAlpha2Code: "PL",
    nodeCodes: ["PL_PROD"],
    name: "Poland",
    emoji: "🇵🇱",
  },
  {
    isoAlpha2Code: "PT",
    nodeCodes: ["PT_1001"],
    name: "Portugal",
    emoji: "🇵🇹",
  },
  {
    isoAlpha2Code: "QA",
    nodeCodes: ["QA_1001"],
    name: "Qatar",
    emoji: "🇶🇦",
  },
  {
    isoAlpha2Code: "RO",
    nodeCodes: ["RO_1001"],
    name: "Romania",
    emoji: "🇷🇴",
  },
  {
    isoAlpha2Code: "RU",
    nodeCodes: ["RU_1001"],
    name: "Russia",
    emoji: "🇷🇺",
  },
  {
    name: "Saba",
    emoji: "",
    isoAlpha2Code: "BQ",
  },
  {
    isoAlpha2Code: "WS",
    nodeCodes: ["WS_1001"],
    name: "Samoa",
    emoji: "🇼🇸",
  },
  {
    isoAlpha2Code: "SN",
    nodeCodes: ["SN_1001"],
    name: "Senegal",
    emoji: "🇸🇳",
  },
  {
    isoAlpha2Code: "RS",
    nodeCodes: ["SRB_1015"],
    name: "Serbia",
    emoji: "🇷🇸",
  },
  {
    isoAlpha2Code: "SG",
    nodeCodes: ["SG_1001"],
    name: "Singapore",
    emoji: "🇸🇬",
  },
  {
    name: "Sint Eustatius",
    emoji: "🇧🇶",
    isoAlpha2Code: "BQ",
  },
  {
    isoAlpha2Code: "SK",
    nodeCodes: ["SK_PROD"],
    name: "Slovakia",
    emoji: "🇸🇰",
  },
  {
    isoAlpha2Code: "SI",
    nodeCodes: ["SVN_1017"],
    name: "Slovenia",
    emoji: "🇸🇮",
  },
  {
    isoAlpha2Code: "SB",
    nodeCodes: ["SB_1001"],
    name: "Solomon Islands",
    emoji: "🇸🇧",
  },
  {
    isoAlpha2Code: "ZA",
    nodeCodes: ["ZA_1001"],
    name: "South Africa",
    emoji: "🇿🇦",
  },
  {
    isoAlpha2Code: "ES",
    nodeCodes: ["ESP_1007"],
    name: "Spain",
    emoji: "🇪🇸",
  },
  {
    isoAlpha2Code: "LK",
    nodeCodes: ["LK_1001"],
    name: "Sri Lanka",
    emoji: "🇱🇰",
  },
  {
    isoAlpha2Code: "SE",
    nodeCodes: ["SWE_1011"],
    name: "Sweden",
    emoji: "🇸🇪",
  },
  {
    isoAlpha2Code: "CH",
    nodeCodes: ["CH_PROD"],
    name: "Switzerland",
    emoji: "🇨🇭",
  },
  {
    isoAlpha2Code: "TW",
    nodeCodes: ["TW_1001"],
    name: "Taiwan",
    emoji: "🇹🇼",
  },
  {
    isoAlpha2Code: "TH",
    nodeCodes: ["TH_1001"],
    name: "Thailand",
    emoji: "🇹🇭",
  },
  {
    isoAlpha2Code: "TO",
    nodeCodes: ["TO_1001"],
    name: "Tonga",
    emoji: "🇹🇴",
  },
  {
    isoAlpha2Code: "TT",
    nodeCodes: ["TT_1001"],
    name: "Trinidad & Tobago",
    emoji: "🇹🇹",
  },
  {
    isoAlpha2Code: "TN",
    nodeCodes: ["TN_1001"],
    name: "Tunisia",
    emoji: "🇹🇳",
  },
  {
    isoAlpha2Code: "TR",
    nodeCodes: ["TR_1001"],
    name: "Turkey",
    emoji: "🇹🇷",
  },
  {
    isoAlpha2Code: "VI",
    nodeCodes: ["VI_1001"],
    name: "U.S Virgin Islands",
    emoji: "🇻🇮",
  },
  {
    isoAlpha2Code: "UG",
    nodeCodes: ["UG_1001"],
    name: "Uganda",
    emoji: "🇺🇬",
  },
  {
    isoAlpha2Code: "GB",
    nodeCodes: ["GBR_1000"],
    name: "United Kingdom",
    emoji: "🇬🇧",
  },
  {
    isoAlpha2Code: "US",
    nodeCodes: ["US_1001"],
    name: "United States",
    emoji: "🇺🇸",
    subJurisdictions: [
      {
        name: "Alabama",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-AL",
        nodeCodes: ["USA_AL"],
      },
      {
        name: "Alaska",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-AK",
        nodeCodes: ["USA_AK"],
      },
      {
        name: "Arizona",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-AZ",
        nodeCodes: ["USA_AZ"],
      },
      {
        name: "Arkansas",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-AR",
        nodeCodes: ["USA_AR"],
      },
      {
        name: "California",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-CA",
        nodeCodes: ["USA_CA"],
      },
      {
        name: "Colorado",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-CO",
        nodeCodes: ["USA_CO"],
      },
      {
        name: "Connecticut",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-CT",
        nodeCodes: ["USA_CT"],
      },
      {
        name: "Delaware",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-DE",
        nodeCodes: ["USA_DE"],
      },
      {
        name: "Florida",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-FL",
        nodeCodes: ["USA_FL"],
      },
      {
        name: "Georgia",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-GA",
        nodeCodes: ["USA_GA"],
      },
      {
        name: "Hawaii",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-HI",
        nodeCodes: ["USA_HI"],
      },
      {
        name: "Idaho",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-ID",
        nodeCodes: ["USA_ID"],
      },
      {
        name: "Illinois",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-IL",
        nodeCodes: ["USA_IL"],
      },
      {
        name: "Indiana",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-IN",
        nodeCodes: ["USA_IN"],
      },
      {
        name: "Iowa",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-IA",
        nodeCodes: ["USA_IA"],
      },
      {
        name: "Kansas",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-KS",
        nodeCodes: ["USA_KS"],
      },
      {
        name: "Kentucky",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-KY",
        nodeCodes: ["USA_KY"],
      },
      {
        name: "Louisiana",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-LA",
        nodeCodes: ["USA_LA"],
      },
      {
        name: "Maine",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-ME",
        nodeCodes: ["USA_ME"],
      },
      {
        name: "Maryland",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-MD",
        nodeCodes: ["USA_MD"],
      },
      {
        name: "Massachusetts",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-MA",
        nodeCodes: ["USA_MA"],
      },
      {
        name: "Michigan",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-MI",
        nodeCodes: ["USA_MI"],
      },
      {
        name: "Minnesota",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-MN",
        nodeCodes: ["USA_MN"],
      },
      {
        name: "Mississippi",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-MS",
        nodeCodes: ["USA_MS"],
      },
      {
        name: "Missouri",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-MO",
        nodeCodes: ["USA_MO"],
      },
      {
        name: "Montana",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-MT",
        nodeCodes: ["USA_MT"],
      },
      {
        name: "Nebraska",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-NE",
        nodeCodes: ["USA_NE"],
      },
      {
        name: "Nevada",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-NV",
        nodeCodes: ["USA_NV"],
      },
      {
        name: "New Hampshire",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-NH",
        nodeCodes: ["USA_NH"],
      },
      {
        name: "New Jersey",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-NJ",
        nodeCodes: ["USA_NJ"],
      },
      {
        name: "New Mexico",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-NM",
        nodeCodes: ["USA_NM"],
      },
      {
        name: "New York",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-NY",
        nodeCodes: ["USA_NY"],
      },
      {
        name: "North Carolina",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-NC",
        nodeCodes: ["USA_NC"],
      },
      {
        name: "North Dakota",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-ND",
        nodeCodes: ["USA_ND"],
      },
      {
        name: "Ohio",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-OH",
        nodeCodes: ["USA_OH"],
      },
      {
        name: "Oklahoma",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-OK",
        nodeCodes: ["USA_OK"],
      },
      {
        name: "Oregon",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-OR",
        nodeCodes: ["USA_OR"],
      },
      {
        name: "Pennsylvania",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-PA",
        nodeCodes: ["USA_PA"],
      },
      {
        name: "Rhode Island",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-RI",
        nodeCodes: ["USA_RI"],
      },
      {
        name: "South Carolina",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-SC",
        nodeCodes: ["USA_SC"],
      },
      {
        name: "South Dakota",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-SD",
        nodeCodes: ["USA_SD"],
      },
      {
        name: "Tennessee",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-TN",
        nodeCodes: ["USA_TN"],
      },
      {
        name: "Texas",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-TX",
        nodeCodes: ["USA_TX"],
      },
      {
        name: "Utah",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-UT",
        nodeCodes: ["USA_UT"],
      },
      {
        name: "Vermont",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-VT",
        nodeCodes: ["USA_VT"],
      },
      {
        name: "Virginia",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-VA",
        nodeCodes: ["USA_VA"],
      },
      {
        name: "Washington",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-WA",
        nodeCodes: ["USA_WA"],
      },
      {
        name: "Washington DC",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-DC",
        nodeCodes: ["USA_DC"],
      },
      {
        name: "West Virginia",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-WV",
        nodeCodes: ["USA_WV"],
      },
      {
        name: "Wisconsin",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-WI",
        nodeCodes: ["USA_WI"],
      },
      {
        name: "Wyoming",
        emoji: "🇺🇸",
        isoAlpha2Code: "US-WY",
        nodeCodes: ["USA_WY"],
      },
    ],
  },
  {
    isoAlpha2Code: "AE",
    nodeCodes: ["AE_1001"],
    name: "United Arab Emirates",
    emoji: "🇦🇪",
  },
  {
    isoAlpha2Code: "UY",
    nodeCodes: ["UY_1001"],
    name: "Uruguay",
    emoji: "🇺🇾",
  },
  {
    isoAlpha2Code: "VU",
    nodeCodes: ["VU_1001"],
    name: "Vanuatu",
    emoji: "🇻🇺",
  },
  {
    isoAlpha2Code: "VN",
    nodeCodes: ["VN_1001"],
    name: "Vietnam",
    emoji: "🇻🇳",
  },
];
