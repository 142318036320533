import { SxProps } from "@mui/material/styles";
import { black } from "pages/variables";

type Props = {
  width?: string;
  height?: string;
  transform?: string;
  maskBgColor?: string;
  strokeColor?: string;
  sx?: SxProps;
};

const CompanyIcon: React.FC<Props> = ({
  width,
  height,
  transform,
  strokeColor,
  maskBgColor,
}): JSX.Element => {
  const defaultTransform = "scale(0.35) translate(13.75,8.75)";
  return (
    <svg
      width={width ?? 35}
      height={height ?? 35}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="companyMask">
        <rect width="100%" height="100%" fill="white" />
        <g transform={transform ?? defaultTransform}>
          <path
            d="M21.3425 22.0404C21.7754 22.0404 22.1284 21.6855 22.1284 21.2502V18.6746C22.1284 18.2393 21.7762 17.8844 21.3425 17.8844C20.9088 17.8844 20.5559 18.2393 20.5559 18.6746V21.2502C20.5559 21.6855 20.9088 22.0404 21.3425 22.0404Z"
            fill={black}
          />
          <path
            d="M25.5071 22.0404C25.9401 22.0404 26.293 21.6855 26.293 21.2502V18.6746C26.293 18.2393 25.9408 17.8844 25.5071 17.8844C25.0734 17.8844 24.7205 18.2393 24.7205 18.6746V21.2502C24.7205 21.6855 25.0734 22.0404 25.5071 22.0404Z"
            fill={black}
          />
          <path
            d="M29.6717 22.0404C30.1047 22.0404 30.4576 21.6855 30.4576 21.2502V18.6746C30.4576 18.2393 30.1054 17.8844 29.6717 17.8844C29.238 17.8844 28.8851 18.2393 28.8851 18.6746V21.2502C28.8851 21.6855 29.2387 22.0404 29.6717 22.0404Z"
            fill={black}
          />
          <path
            d="M21.3425 16.3074C21.7754 16.3074 22.1284 15.9525 22.1284 15.5172V12.9416C22.1284 12.5063 21.7762 12.1514 21.3425 12.1514C20.9088 12.1514 20.5559 12.5063 20.5559 12.9416V15.5172C20.5559 15.9525 20.9088 16.3074 21.3425 16.3074Z"
            fill={black}
          />
          <path
            d="M25.5071 16.3074C25.9401 16.3074 26.293 15.9525 26.293 15.5172V12.9416C26.293 12.5063 25.9408 12.1514 25.5071 12.1514C25.0734 12.1514 24.7205 12.5063 24.7205 12.9416V15.5172C24.7205 15.9525 25.0734 16.3074 25.5071 16.3074Z"
            fill={black}
          />
          <path
            d="M29.6717 16.3074C30.1047 16.3074 30.4576 15.9525 30.4576 15.5172V12.9416C30.4576 12.5063 30.1054 12.1514 29.6717 12.1514C29.238 12.1514 28.8851 12.5063 28.8851 12.9416V15.5172C28.8851 15.9525 29.2387 16.3074 29.6717 16.3074Z"
            fill={black}
          />
          <path
            d="M21.3425 10.5737C21.7754 10.5737 22.1284 10.2188 22.1284 9.78345V7.2072C22.1284 6.77183 21.7762 6.41693 21.3425 6.41693C20.9088 6.41693 20.5559 6.77183 20.5559 7.2072V9.78345C20.5559 10.2188 20.9088 10.5737 21.3425 10.5737Z"
            fill={black}
          />
          <path
            d="M25.5071 10.5737C25.9401 10.5737 26.293 10.2188 26.293 9.78345V7.2072C26.293 6.77183 25.9408 6.41693 25.5071 6.41693C25.0734 6.41693 24.7205 6.77183 24.7205 7.2072V9.78345C24.7205 10.2188 25.0734 10.5737 25.5071 10.5737Z"
            fill={black}
          />
          <path
            d="M29.6717 10.5737C30.1047 10.5737 30.4576 10.2188 30.4576 9.78345V7.2072C30.4576 6.77183 30.1054 6.41693 29.6717 6.41693C29.238 6.41693 28.8851 6.77183 28.8851 7.2072V9.78345C28.8851 10.2188 29.2387 10.5737 29.6717 10.5737Z"
            fill={black}
          />
          <path
            d="M21.3425 27.7727C21.7754 27.7727 22.1284 27.4178 22.1284 26.9824V24.4069C22.1284 23.9715 21.7762 23.6166 21.3425 23.6166C20.9088 23.6166 20.5559 23.9715 20.5559 24.4069V26.9824C20.5559 27.4178 20.9088 27.7727 21.3425 27.7727Z"
            fill={black}
          />
          <path
            d="M25.5071 27.7727C25.9401 27.7727 26.293 27.4178 26.293 26.9824V24.4069C26.293 23.9715 25.9408 23.6166 25.5071 23.6166C25.0734 23.6166 24.7205 23.9715 24.7205 24.4069V26.9824C24.7205 27.4178 25.0734 27.7727 25.5071 27.7727Z"
            fill={black}
          />
          <path
            d="M29.6717 27.7727C30.1047 27.7727 30.4576 27.4178 30.4576 26.9824V24.4069C30.4576 23.9715 30.1054 23.6166 29.6717 23.6166C29.238 23.6166 28.8851 23.9715 28.8851 24.4069V26.9824C28.8851 27.4178 29.2387 27.7727 29.6717 27.7727Z"
            fill={black}
          />
          <path
            d="M9.77816 28.4732C10.2111 28.4732 10.5648 28.1183 10.5648 27.6829V26.0004C10.5648 25.565 10.2118 25.2101 9.77816 25.2101C9.34448 25.2101 8.99225 25.565 8.99225 26.0004V27.6829C8.99225 28.1183 9.34448 28.4732 9.77816 28.4732Z"
            fill={black}
          />
          <path
            d="M12.8568 28.4732C13.2898 28.4732 13.6427 28.1183 13.6427 27.6829V26.0004C13.6427 25.565 13.2905 25.2101 12.8568 25.2101C12.4231 25.2101 12.0702 25.565 12.0702 26.0004V27.6829C12.0702 28.1183 12.4231 28.4732 12.8568 28.4732Z"
            fill={black}
          />
          <path
            d="M9.77816 33.2557C10.2111 33.2557 10.5648 32.9008 10.5648 32.4654V30.7822C10.5648 30.3468 10.2111 29.9919 9.77816 29.9919C9.34519 29.9919 8.99225 30.3468 8.99225 30.7822V32.4647C8.99225 32.9001 9.34448 33.2557 9.77816 33.2557Z"
            fill={black}
          />
          <path
            d="M12.8568 33.2557C13.2898 33.2557 13.6427 32.9008 13.6427 32.4654V30.7829C13.6427 30.3475 13.2905 29.9926 12.8568 29.9926C12.4231 29.9926 12.0702 30.3475 12.0702 30.7829V32.4654C12.0702 32.9008 12.4231 33.2557 12.8568 33.2557Z"
            fill={black}
          />
          <path
            d="M9.77816 38.0389C10.2111 38.0389 10.5648 37.684 10.5648 37.2487V35.5661C10.5648 35.1308 10.2111 34.7759 9.77816 34.7759C9.34519 34.7759 8.99225 35.1308 8.99225 35.5661V37.2487C8.99225 37.684 9.34448 38.0389 9.77816 38.0389Z"
            fill={black}
          />
          <path
            d="M12.8568 38.0389C13.2898 38.0389 13.6427 37.684 13.6427 37.2487V35.5661C13.6427 35.1308 13.2905 34.7759 12.8568 34.7759C12.4231 34.7759 12.0702 35.1308 12.0702 35.5661V37.2487C12.0702 37.684 12.4231 38.0389 12.8568 38.0389Z"
            fill={black}
          />
          <path
            d="M9.77816 42.8207C10.2111 42.8207 10.5648 42.4658 10.5648 42.0305V40.3479C10.5648 39.9126 10.2111 39.5577 9.77816 39.5577C9.34519 39.5577 8.99225 39.9126 8.99225 40.3479V42.0305C8.99225 42.4658 9.34448 42.8207 9.77816 42.8207Z"
            fill={black}
          />
          <path
            d="M12.8568 42.8207C13.2898 42.8207 13.6427 42.4658 13.6427 42.0305V40.3479C13.6427 39.9126 13.2905 39.5577 12.8568 39.5577C12.4231 39.5577 12.0702 39.9126 12.0702 40.3479V42.0305C12.0702 42.4658 12.4231 42.8207 12.8568 42.8207Z"
            fill={black}
          />
          <path
            d="M9.77816 47.6047C10.2111 47.6047 10.5648 47.2498 10.5648 46.8144V45.1312C10.5648 44.6958 10.2111 44.3409 9.77816 44.3409C9.34519 44.3409 8.99225 44.6958 8.99225 45.1312V46.8137C8.99225 47.2491 9.34448 47.6047 9.77816 47.6047Z"
            fill={black}
          />
          <path
            d="M12.8568 47.6047C13.2898 47.6047 13.6427 47.2498 13.6427 46.8144V45.1319C13.6427 44.6965 13.2905 44.3416 12.8568 44.3416C12.4231 44.3416 12.0702 44.6965 12.0702 45.1319V46.8144C12.0702 47.2498 12.4231 47.6047 12.8568 47.6047Z"
            fill={black}
          />
          <path
            d="M39.7692 50.6508C39.6206 50.5021 39.4249 50.4202 39.2155 50.4202H34.5072V0.790263C34.5072 0.3549 34.1543 0 33.7206 0C21.5018 0 16.0962 5.28542 15.8725 5.51029C15.7239 5.65972 15.6418 5.85872 15.6418 6.06994V20.8055H6.23227C5.79931 20.8055 5.44565 21.1604 5.44565 21.5957V50.4188H0.786625C0.35366 50.4188 0 50.7737 0 51.209C0 51.6444 0.352945 52 0.786625 52H39.2141C39.6471 52 40.0007 51.6451 40.0007 51.209C40.0007 50.9978 39.9178 50.7995 39.7692 50.6508ZM15.6425 50.4195H7.0189V22.3867H15.6425V50.4195ZM28.0213 50.4195H22.1277V44.8582H28.0213V50.4195ZM32.9354 50.4195H29.5938V44.0679C29.5938 43.6326 29.2409 43.2777 28.8065 43.2777H21.3418C20.9088 43.2777 20.5551 43.6326 20.5551 44.0679V50.4188H17.2129V38.7042H32.9347V50.4188L32.9354 50.4195ZM32.9354 37.1237H17.2136V31.5724H32.9361L32.9354 37.1237ZM32.9354 29.9912H17.2136V6.42268C18.246 5.55124 23.263 1.78168 32.9361 1.58699V29.9919L32.9354 29.9912Z"
            fill={black}
          />
        </g>
      </mask>
      <g transform={transform ?? defaultTransform}>
        <path
          d="M21.3425 22.0404C21.7754 22.0404 22.1284 21.6855 22.1284 21.2502V18.6746C22.1284 18.2393 21.7762 17.8844 21.3425 17.8844C20.9088 17.8844 20.5559 18.2393 20.5559 18.6746V21.2502C20.5559 21.6855 20.9088 22.0404 21.3425 22.0404Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 22.0404C25.9401 22.0404 26.293 21.6855 26.293 21.2502V18.6746C26.293 18.2393 25.9408 17.8844 25.5071 17.8844C25.0734 17.8844 24.7205 18.2393 24.7205 18.6746V21.2502C24.7205 21.6855 25.0734 22.0404 25.5071 22.0404Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 22.0404C30.1047 22.0404 30.4576 21.6855 30.4576 21.2502V18.6746C30.4576 18.2393 30.1054 17.8844 29.6717 17.8844C29.238 17.8844 28.8851 18.2393 28.8851 18.6746V21.2502C28.8851 21.6855 29.2387 22.0404 29.6717 22.0404Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 16.3074C21.7754 16.3074 22.1284 15.9525 22.1284 15.5172V12.9416C22.1284 12.5063 21.7762 12.1514 21.3425 12.1514C20.9088 12.1514 20.5559 12.5063 20.5559 12.9416V15.5172C20.5559 15.9525 20.9088 16.3074 21.3425 16.3074Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 16.3074C25.9401 16.3074 26.293 15.9525 26.293 15.5172V12.9416C26.293 12.5063 25.9408 12.1514 25.5071 12.1514C25.0734 12.1514 24.7205 12.5063 24.7205 12.9416V15.5172C24.7205 15.9525 25.0734 16.3074 25.5071 16.3074Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 16.3074C30.1047 16.3074 30.4576 15.9525 30.4576 15.5172V12.9416C30.4576 12.5063 30.1054 12.1514 29.6717 12.1514C29.238 12.1514 28.8851 12.5063 28.8851 12.9416V15.5172C28.8851 15.9525 29.2387 16.3074 29.6717 16.3074Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 10.5737C21.7754 10.5737 22.1284 10.2188 22.1284 9.78345V7.2072C22.1284 6.77183 21.7762 6.41693 21.3425 6.41693C20.9088 6.41693 20.5559 6.77183 20.5559 7.2072V9.78345C20.5559 10.2188 20.9088 10.5737 21.3425 10.5737Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 10.5737C25.9401 10.5737 26.293 10.2188 26.293 9.78345V7.2072C26.293 6.77183 25.9408 6.41693 25.5071 6.41693C25.0734 6.41693 24.7205 6.77183 24.7205 7.2072V9.78345C24.7205 10.2188 25.0734 10.5737 25.5071 10.5737Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 10.5737C30.1047 10.5737 30.4576 10.2188 30.4576 9.78345V7.2072C30.4576 6.77183 30.1054 6.41693 29.6717 6.41693C29.238 6.41693 28.8851 6.77183 28.8851 7.2072V9.78345C28.8851 10.2188 29.2387 10.5737 29.6717 10.5737Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 27.7727C21.7754 27.7727 22.1284 27.4178 22.1284 26.9824V24.4069C22.1284 23.9715 21.7762 23.6166 21.3425 23.6166C20.9088 23.6166 20.5559 23.9715 20.5559 24.4069V26.9824C20.5559 27.4178 20.9088 27.7727 21.3425 27.7727Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 27.7727C25.9401 27.7727 26.293 27.4178 26.293 26.9824V24.4069C26.293 23.9715 25.9408 23.6166 25.5071 23.6166C25.0734 23.6166 24.7205 23.9715 24.7205 24.4069V26.9824C24.7205 27.4178 25.0734 27.7727 25.5071 27.7727Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 27.7727C30.1047 27.7727 30.4576 27.4178 30.4576 26.9824V24.4069C30.4576 23.9715 30.1054 23.6166 29.6717 23.6166C29.238 23.6166 28.8851 23.9715 28.8851 24.4069V26.9824C28.8851 27.4178 29.2387 27.7727 29.6717 27.7727Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 28.4732C10.2111 28.4732 10.5648 28.1183 10.5648 27.6829V26.0004C10.5648 25.565 10.2118 25.2101 9.77816 25.2101C9.34448 25.2101 8.99225 25.565 8.99225 26.0004V27.6829C8.99225 28.1183 9.34448 28.4732 9.77816 28.4732Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 28.4732C13.2898 28.4732 13.6427 28.1183 13.6427 27.6829V26.0004C13.6427 25.565 13.2905 25.2101 12.8568 25.2101C12.4231 25.2101 12.0702 25.565 12.0702 26.0004V27.6829C12.0702 28.1183 12.4231 28.4732 12.8568 28.4732Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 33.2557C10.2111 33.2557 10.5648 32.9008 10.5648 32.4654V30.7822C10.5648 30.3468 10.2111 29.9919 9.77816 29.9919C9.34519 29.9919 8.99225 30.3468 8.99225 30.7822V32.4647C8.99225 32.9001 9.34448 33.2557 9.77816 33.2557Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 33.2557C13.2898 33.2557 13.6427 32.9008 13.6427 32.4654V30.7829C13.6427 30.3475 13.2905 29.9926 12.8568 29.9926C12.4231 29.9926 12.0702 30.3475 12.0702 30.7829V32.4654C12.0702 32.9008 12.4231 33.2557 12.8568 33.2557Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 38.0389C10.2111 38.0389 10.5648 37.684 10.5648 37.2487V35.5661C10.5648 35.1308 10.2111 34.7759 9.77816 34.7759C9.34519 34.7759 8.99225 35.1308 8.99225 35.5661V37.2487C8.99225 37.684 9.34448 38.0389 9.77816 38.0389Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 38.0389C13.2898 38.0389 13.6427 37.684 13.6427 37.2487V35.5661C13.6427 35.1308 13.2905 34.7759 12.8568 34.7759C12.4231 34.7759 12.0702 35.1308 12.0702 35.5661V37.2487C12.0702 37.684 12.4231 38.0389 12.8568 38.0389Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 42.8207C10.2111 42.8207 10.5648 42.4658 10.5648 42.0305V40.3479C10.5648 39.9126 10.2111 39.5577 9.77816 39.5577C9.34519 39.5577 8.99225 39.9126 8.99225 40.3479V42.0305C8.99225 42.4658 9.34448 42.8207 9.77816 42.8207Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 42.8207C13.2898 42.8207 13.6427 42.4658 13.6427 42.0305V40.3479C13.6427 39.9126 13.2905 39.5577 12.8568 39.5577C12.4231 39.5577 12.0702 39.9126 12.0702 40.3479V42.0305C12.0702 42.4658 12.4231 42.8207 12.8568 42.8207Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 47.6047C10.2111 47.6047 10.5648 47.2498 10.5648 46.8144V45.1312C10.5648 44.6958 10.2111 44.3409 9.77816 44.3409C9.34519 44.3409 8.99225 44.6958 8.99225 45.1312V46.8137C8.99225 47.2491 9.34448 47.6047 9.77816 47.6047Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 47.6047C13.2898 47.6047 13.6427 47.2498 13.6427 46.8144V45.1319C13.6427 44.6965 13.2905 44.3416 12.8568 44.3416C12.4231 44.3416 12.0702 44.6965 12.0702 45.1319V46.8144C12.0702 47.2498 12.4231 47.6047 12.8568 47.6047Z"
          fill={strokeColor}
        />
        <path
          d="M39.7692 50.6508C39.6206 50.5021 39.4249 50.4202 39.2155 50.4202H34.5072V0.790263C34.5072 0.3549 34.1543 0 33.7206 0C21.5018 0 16.0962 5.28542 15.8725 5.51029C15.7239 5.65972 15.6418 5.85872 15.6418 6.06994V20.8055H6.23227C5.79931 20.8055 5.44565 21.1604 5.44565 21.5957V50.4188H0.786625C0.35366 50.4188 0 50.7737 0 51.209C0 51.6444 0.352945 52 0.786625 52H39.2141C39.6471 52 40.0007 51.6451 40.0007 51.209C40.0007 50.9978 39.9178 50.7995 39.7692 50.6508ZM15.6425 50.4195H7.0189V22.3867H15.6425V50.4195ZM28.0213 50.4195H22.1277V44.8582H28.0213V50.4195ZM32.9354 50.4195H29.5938V44.0679C29.5938 43.6326 29.2409 43.2777 28.8065 43.2777H21.3418C20.9088 43.2777 20.5551 43.6326 20.5551 44.0679V50.4188H17.2129V38.7042H32.9347V50.4188L32.9354 50.4195ZM32.9354 37.1237H17.2136V31.5724H32.9361L32.9354 37.1237ZM32.9354 29.9912H17.2136V6.42268C18.246 5.55124 23.263 1.78168 32.9361 1.58699V29.9919L32.9354 29.9912Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 22.0404C21.7754 22.0404 22.1284 21.6855 22.1284 21.2502V18.6746C22.1284 18.2393 21.7762 17.8844 21.3425 17.8844C20.9088 17.8844 20.5559 18.2393 20.5559 18.6746V21.2502C20.5559 21.6855 20.9088 22.0404 21.3425 22.0404Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 22.0404C25.9401 22.0404 26.293 21.6855 26.293 21.2502V18.6746C26.293 18.2393 25.9408 17.8844 25.5071 17.8844C25.0734 17.8844 24.7205 18.2393 24.7205 18.6746V21.2502C24.7205 21.6855 25.0734 22.0404 25.5071 22.0404Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 22.0404C30.1047 22.0404 30.4576 21.6855 30.4576 21.2502V18.6746C30.4576 18.2393 30.1054 17.8844 29.6717 17.8844C29.238 17.8844 28.8851 18.2393 28.8851 18.6746V21.2502C28.8851 21.6855 29.2387 22.0404 29.6717 22.0404Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 16.3074C21.7754 16.3074 22.1284 15.9525 22.1284 15.5172V12.9416C22.1284 12.5063 21.7762 12.1514 21.3425 12.1514C20.9088 12.1514 20.5559 12.5063 20.5559 12.9416V15.5172C20.5559 15.9525 20.9088 16.3074 21.3425 16.3074Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 16.3074C25.9401 16.3074 26.293 15.9525 26.293 15.5172V12.9416C26.293 12.5063 25.9408 12.1514 25.5071 12.1514C25.0734 12.1514 24.7205 12.5063 24.7205 12.9416V15.5172C24.7205 15.9525 25.0734 16.3074 25.5071 16.3074Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 16.3074C30.1047 16.3074 30.4576 15.9525 30.4576 15.5172V12.9416C30.4576 12.5063 30.1054 12.1514 29.6717 12.1514C29.238 12.1514 28.8851 12.5063 28.8851 12.9416V15.5172C28.8851 15.9525 29.2387 16.3074 29.6717 16.3074Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 10.5737C21.7754 10.5737 22.1284 10.2188 22.1284 9.78345V7.2072C22.1284 6.77183 21.7762 6.41693 21.3425 6.41693C20.9088 6.41693 20.5559 6.77183 20.5559 7.2072V9.78345C20.5559 10.2188 20.9088 10.5737 21.3425 10.5737Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 10.5737C25.9401 10.5737 26.293 10.2188 26.293 9.78345V7.2072C26.293 6.77183 25.9408 6.41693 25.5071 6.41693C25.0734 6.41693 24.7205 6.77183 24.7205 7.2072V9.78345C24.7205 10.2188 25.0734 10.5737 25.5071 10.5737Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 10.5737C30.1047 10.5737 30.4576 10.2188 30.4576 9.78345V7.2072C30.4576 6.77183 30.1054 6.41693 29.6717 6.41693C29.238 6.41693 28.8851 6.77183 28.8851 7.2072V9.78345C28.8851 10.2188 29.2387 10.5737 29.6717 10.5737Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 27.7727C21.7754 27.7727 22.1284 27.4178 22.1284 26.9824V24.4069C22.1284 23.9715 21.7762 23.6166 21.3425 23.6166C20.9088 23.6166 20.5559 23.9715 20.5559 24.4069V26.9824C20.5559 27.4178 20.9088 27.7727 21.3425 27.7727Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 27.7727C25.9401 27.7727 26.293 27.4178 26.293 26.9824V24.4069C26.293 23.9715 25.9408 23.6166 25.5071 23.6166C25.0734 23.6166 24.7205 23.9715 24.7205 24.4069V26.9824C24.7205 27.4178 25.0734 27.7727 25.5071 27.7727Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 27.7727C30.1047 27.7727 30.4576 27.4178 30.4576 26.9824V24.4069C30.4576 23.9715 30.1054 23.6166 29.6717 23.6166C29.238 23.6166 28.8851 23.9715 28.8851 24.4069V26.9824C28.8851 27.4178 29.2387 27.7727 29.6717 27.7727Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 28.4732C10.2111 28.4732 10.5648 28.1183 10.5648 27.6829V26.0004C10.5648 25.565 10.2118 25.2101 9.77816 25.2101C9.34448 25.2101 8.99225 25.565 8.99225 26.0004V27.6829C8.99225 28.1183 9.34448 28.4732 9.77816 28.4732Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 28.4732C13.2898 28.4732 13.6427 28.1183 13.6427 27.6829V26.0004C13.6427 25.565 13.2905 25.2101 12.8568 25.2101C12.4231 25.2101 12.0702 25.565 12.0702 26.0004V27.6829C12.0702 28.1183 12.4231 28.4732 12.8568 28.4732Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 33.2557C10.2111 33.2557 10.5648 32.9008 10.5648 32.4654V30.7822C10.5648 30.3468 10.2111 29.9919 9.77816 29.9919C9.34519 29.9919 8.99225 30.3468 8.99225 30.7822V32.4647C8.99225 32.9001 9.34448 33.2557 9.77816 33.2557Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 33.2557C13.2898 33.2557 13.6427 32.9008 13.6427 32.4654V30.7829C13.6427 30.3475 13.2905 29.9926 12.8568 29.9926C12.4231 29.9926 12.0702 30.3475 12.0702 30.7829V32.4654C12.0702 32.9008 12.4231 33.2557 12.8568 33.2557Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 38.0389C10.2111 38.0389 10.5648 37.684 10.5648 37.2487V35.5661C10.5648 35.1308 10.2111 34.7759 9.77816 34.7759C9.34519 34.7759 8.99225 35.1308 8.99225 35.5661V37.2487C8.99225 37.684 9.34448 38.0389 9.77816 38.0389Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 38.0389C13.2898 38.0389 13.6427 37.684 13.6427 37.2487V35.5661C13.6427 35.1308 13.2905 34.7759 12.8568 34.7759C12.4231 34.7759 12.0702 35.1308 12.0702 35.5661V37.2487C12.0702 37.684 12.4231 38.0389 12.8568 38.0389Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 42.8207C10.2111 42.8207 10.5648 42.4658 10.5648 42.0305V40.3479C10.5648 39.9126 10.2111 39.5577 9.77816 39.5577C9.34519 39.5577 8.99225 39.9126 8.99225 40.3479V42.0305C8.99225 42.4658 9.34448 42.8207 9.77816 42.8207Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 42.8207C13.2898 42.8207 13.6427 42.4658 13.6427 42.0305V40.3479C13.6427 39.9126 13.2905 39.5577 12.8568 39.5577C12.4231 39.5577 12.0702 39.9126 12.0702 40.3479V42.0305C12.0702 42.4658 12.4231 42.8207 12.8568 42.8207Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 47.6047C10.2111 47.6047 10.5648 47.2498 10.5648 46.8144V45.1312C10.5648 44.6958 10.2111 44.3409 9.77816 44.3409C9.34519 44.3409 8.99225 44.6958 8.99225 45.1312V46.8137C8.99225 47.2491 9.34448 47.6047 9.77816 47.6047Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 47.6047C13.2898 47.6047 13.6427 47.2498 13.6427 46.8144V45.1319C13.6427 44.6965 13.2905 44.3416 12.8568 44.3416C12.4231 44.3416 12.0702 44.6965 12.0702 45.1319V46.8144C12.0702 47.2498 12.4231 47.6047 12.8568 47.6047Z"
          fill={strokeColor}
        />
        <path
          d="M39.7692 50.6508C39.6206 50.5021 39.4249 50.4202 39.2155 50.4202H34.5072V0.790263C34.5072 0.3549 34.1543 0 33.7206 0C21.5018 0 16.0962 5.28542 15.8725 5.51029C15.7239 5.65972 15.6418 5.85872 15.6418 6.06994V20.8055H6.23227C5.79931 20.8055 5.44565 21.1604 5.44565 21.5957V50.4188H0.786625C0.35366 50.4188 0 50.7737 0 51.209C0 51.6444 0.352945 52 0.786625 52H39.2141C39.6471 52 40.0007 51.6451 40.0007 51.209C40.0007 50.9978 39.9178 50.7995 39.7692 50.6508ZM15.6425 50.4195H7.0189V22.3867H15.6425V50.4195ZM28.0213 50.4195H22.1277V44.8582H28.0213V50.4195ZM32.9354 50.4195H29.5938V44.0679C29.5938 43.6326 29.2409 43.2777 28.8065 43.2777H21.3418C20.9088 43.2777 20.5551 43.6326 20.5551 44.0679V50.4188H17.2129V38.7042H32.9347V50.4188L32.9354 50.4195ZM32.9354 37.1237H17.2136V31.5724H32.9361L32.9354 37.1237ZM32.9354 29.9912H17.2136V6.42268C18.246 5.55124 23.263 1.78168 32.9361 1.58699V29.9919L32.9354 29.9912Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 22.0404C21.7754 22.0404 22.1284 21.6855 22.1284 21.2502V18.6746C22.1284 18.2393 21.7762 17.8844 21.3425 17.8844C20.9088 17.8844 20.5559 18.2393 20.5559 18.6746V21.2502C20.5559 21.6855 20.9088 22.0404 21.3425 22.0404Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 22.0404C25.9401 22.0404 26.293 21.6855 26.293 21.2502V18.6746C26.293 18.2393 25.9408 17.8844 25.5071 17.8844C25.0734 17.8844 24.7205 18.2393 24.7205 18.6746V21.2502C24.7205 21.6855 25.0734 22.0404 25.5071 22.0404Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 22.0404C30.1047 22.0404 30.4576 21.6855 30.4576 21.2502V18.6746C30.4576 18.2393 30.1054 17.8844 29.6717 17.8844C29.238 17.8844 28.8851 18.2393 28.8851 18.6746V21.2502C28.8851 21.6855 29.2387 22.0404 29.6717 22.0404Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 16.3074C21.7754 16.3074 22.1284 15.9525 22.1284 15.5172V12.9416C22.1284 12.5063 21.7762 12.1514 21.3425 12.1514C20.9088 12.1514 20.5559 12.5063 20.5559 12.9416V15.5172C20.5559 15.9525 20.9088 16.3074 21.3425 16.3074Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 16.3074C25.9401 16.3074 26.293 15.9525 26.293 15.5172V12.9416C26.293 12.5063 25.9408 12.1514 25.5071 12.1514C25.0734 12.1514 24.7205 12.5063 24.7205 12.9416V15.5172C24.7205 15.9525 25.0734 16.3074 25.5071 16.3074Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 16.3074C30.1047 16.3074 30.4576 15.9525 30.4576 15.5172V12.9416C30.4576 12.5063 30.1054 12.1514 29.6717 12.1514C29.238 12.1514 28.8851 12.5063 28.8851 12.9416V15.5172C28.8851 15.9525 29.2387 16.3074 29.6717 16.3074Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 10.5737C21.7754 10.5737 22.1284 10.2188 22.1284 9.78345V7.2072C22.1284 6.77183 21.7762 6.41693 21.3425 6.41693C20.9088 6.41693 20.5559 6.77183 20.5559 7.2072V9.78345C20.5559 10.2188 20.9088 10.5737 21.3425 10.5737Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 10.5737C25.9401 10.5737 26.293 10.2188 26.293 9.78345V7.2072C26.293 6.77183 25.9408 6.41693 25.5071 6.41693C25.0734 6.41693 24.7205 6.77183 24.7205 7.2072V9.78345C24.7205 10.2188 25.0734 10.5737 25.5071 10.5737Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 10.5737C30.1047 10.5737 30.4576 10.2188 30.4576 9.78345V7.2072C30.4576 6.77183 30.1054 6.41693 29.6717 6.41693C29.238 6.41693 28.8851 6.77183 28.8851 7.2072V9.78345C28.8851 10.2188 29.2387 10.5737 29.6717 10.5737Z"
          fill={strokeColor}
        />
        <path
          d="M21.3425 27.7727C21.7754 27.7727 22.1284 27.4178 22.1284 26.9824V24.4069C22.1284 23.9715 21.7762 23.6166 21.3425 23.6166C20.9088 23.6166 20.5559 23.9715 20.5559 24.4069V26.9824C20.5559 27.4178 20.9088 27.7727 21.3425 27.7727Z"
          fill={strokeColor}
        />
        <path
          d="M25.5071 27.7727C25.9401 27.7727 26.293 27.4178 26.293 26.9824V24.4069C26.293 23.9715 25.9408 23.6166 25.5071 23.6166C25.0734 23.6166 24.7205 23.9715 24.7205 24.4069V26.9824C24.7205 27.4178 25.0734 27.7727 25.5071 27.7727Z"
          fill={strokeColor}
        />
        <path
          d="M29.6717 27.7727C30.1047 27.7727 30.4576 27.4178 30.4576 26.9824V24.4069C30.4576 23.9715 30.1054 23.6166 29.6717 23.6166C29.238 23.6166 28.8851 23.9715 28.8851 24.4069V26.9824C28.8851 27.4178 29.2387 27.7727 29.6717 27.7727Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 28.4732C10.2111 28.4732 10.5648 28.1183 10.5648 27.6829V26.0004C10.5648 25.565 10.2118 25.2101 9.77816 25.2101C9.34448 25.2101 8.99225 25.565 8.99225 26.0004V27.6829C8.99225 28.1183 9.34448 28.4732 9.77816 28.4732Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 28.4732C13.2898 28.4732 13.6427 28.1183 13.6427 27.6829V26.0004C13.6427 25.565 13.2905 25.2101 12.8568 25.2101C12.4231 25.2101 12.0702 25.565 12.0702 26.0004V27.6829C12.0702 28.1183 12.4231 28.4732 12.8568 28.4732Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 33.2557C10.2111 33.2557 10.5648 32.9008 10.5648 32.4654V30.7822C10.5648 30.3468 10.2111 29.9919 9.77816 29.9919C9.34519 29.9919 8.99225 30.3468 8.99225 30.7822V32.4647C8.99225 32.9001 9.34448 33.2557 9.77816 33.2557Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 33.2557C13.2898 33.2557 13.6427 32.9008 13.6427 32.4654V30.7829C13.6427 30.3475 13.2905 29.9926 12.8568 29.9926C12.4231 29.9926 12.0702 30.3475 12.0702 30.7829V32.4654C12.0702 32.9008 12.4231 33.2557 12.8568 33.2557Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 38.0389C10.2111 38.0389 10.5648 37.684 10.5648 37.2487V35.5661C10.5648 35.1308 10.2111 34.7759 9.77816 34.7759C9.34519 34.7759 8.99225 35.1308 8.99225 35.5661V37.2487C8.99225 37.684 9.34448 38.0389 9.77816 38.0389Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 38.0389C13.2898 38.0389 13.6427 37.684 13.6427 37.2487V35.5661C13.6427 35.1308 13.2905 34.7759 12.8568 34.7759C12.4231 34.7759 12.0702 35.1308 12.0702 35.5661V37.2487C12.0702 37.684 12.4231 38.0389 12.8568 38.0389Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 42.8207C10.2111 42.8207 10.5648 42.4658 10.5648 42.0305V40.3479C10.5648 39.9126 10.2111 39.5577 9.77816 39.5577C9.34519 39.5577 8.99225 39.9126 8.99225 40.3479V42.0305C8.99225 42.4658 9.34448 42.8207 9.77816 42.8207Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 42.8207C13.2898 42.8207 13.6427 42.4658 13.6427 42.0305V40.3479C13.6427 39.9126 13.2905 39.5577 12.8568 39.5577C12.4231 39.5577 12.0702 39.9126 12.0702 40.3479V42.0305C12.0702 42.4658 12.4231 42.8207 12.8568 42.8207Z"
          fill={strokeColor}
        />
        <path
          d="M9.77816 47.6047C10.2111 47.6047 10.5648 47.2498 10.5648 46.8144V45.1312C10.5648 44.6958 10.2111 44.3409 9.77816 44.3409C9.34519 44.3409 8.99225 44.6958 8.99225 45.1312V46.8137C8.99225 47.2491 9.34448 47.6047 9.77816 47.6047Z"
          fill={strokeColor}
        />
        <path
          d="M12.8568 47.6047C13.2898 47.6047 13.6427 47.2498 13.6427 46.8144V45.1319C13.6427 44.6965 13.2905 44.3416 12.8568 44.3416C12.4231 44.3416 12.0702 44.6965 12.0702 45.1319V46.8144C12.0702 47.2498 12.4231 47.6047 12.8568 47.6047Z"
          fill={strokeColor}
        />
        <path
          d="M39.7692 50.6508C39.6206 50.5021 39.4249 50.4202 39.2155 50.4202H34.5072V0.790263C34.5072 0.3549 34.1543 0 33.7206 0C21.5018 0 16.0962 5.28542 15.8725 5.51029C15.7239 5.65972 15.6418 5.85872 15.6418 6.06994V20.8055H6.23227C5.79931 20.8055 5.44565 21.1604 5.44565 21.5957V50.4188H0.786625C0.35366 50.4188 0 50.7737 0 51.209C0 51.6444 0.352945 52 0.786625 52H39.2141C39.6471 52 40.0007 51.6451 40.0007 51.209C40.0007 50.9978 39.9178 50.7995 39.7692 50.6508ZM15.6425 50.4195H7.0189V22.3867H15.6425V50.4195ZM28.0213 50.4195H22.1277V44.8582H28.0213V50.4195ZM32.9354 50.4195H29.5938V44.0679C29.5938 43.6326 29.2409 43.2777 28.8065 43.2777H21.3418C20.9088 43.2777 20.5551 43.6326 20.5551 44.0679V50.4188H17.2129V38.7042H32.9347V50.4188L32.9354 50.4195ZM32.9354 37.1237H17.2136V31.5724H32.9361L32.9354 37.1237ZM32.9354 29.9912H17.2136V6.42268C18.246 5.55124 23.263 1.78168 32.9361 1.58699V29.9919L32.9354 29.9912Z"
          fill={strokeColor}
        />
      </g>
      <circle
        cx="12"
        cy="12"
        r="12"
        fill={maskBgColor ?? black}
        mask="url(#companyMask)"
      />
    </svg>
  );
};

export default CompanyIcon;
