import Company from "./Company";
import { CompanyContextProvider } from "contexts/CompanyContext";
import ManualPage from "./Manual";

export default function CompanyContextWrapper(): JSX.Element {
  return (
    <CompanyContextProvider>
      <Company />
    </CompanyContextProvider>
  );
}

export const Manual = (): JSX.Element => {
  return (
    <CompanyContextProvider isManual>
      <ManualPage />
    </CompanyContextProvider>
  );
};
