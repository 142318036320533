import React from "react";
import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Restore, CalendarMonth, PendingOutlined } from "@mui/icons-material";

import FormGroup from "@mui/material/FormGroup";
import { Userpilot } from "userpilot";
import { differenceInDays, format } from "date-fns";

import Button from "components/Button/Button";
import { Order } from "pages/OrderHistory/types";

type Props = {
  dialogOpen: boolean;
  setDialogDismissed: (value: React.SetStateAction<boolean>) => void;
  doAction: () => void;
  order?: Order;
};

export let _currentDate = () => new Date();
export const _overrideDate = (date: Date) => {
  _currentDate = () => date;
};
export const _resetDate = () => {
  _currentDate = () => new Date();
};

const DuplicateOrderDialog: React.FC<Props> = ({
  dialogOpen,
  setDialogDismissed,
  order,
  doAction,
}) => {
  const handleCloseDialog = () => {
    Userpilot.track("Dismiss Repeat Order Pop Up");
    setDialogDismissed(true);
  };

  const downloadPreviousOrder = () => {
    if (order?.links?.document) {
      const win = window.open(order.links.document, "_blank");
      win && win.focus();
    }
  };

  const dayDiff = order
    ? differenceInDays(_currentDate(), new Date(order?.orderDate))
    : 0;

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      data-testid="filing-duplicate-order-dialog"
    >
      <DialogContent
        sx={{
          fontFamily: "Anthro",
          fontSize: "0.874rem",
          color: "primary.dark",
        }}
      >
        {order && (
          <>
            <Box
              sx={{
                height: "160px",
                marginBottom: "10%",
                borderWidth: "2px",
                borderColor: "primary.dark",
                borderStyle: "solid",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                placeContent: "space-around",
                pt: 1,
                pb: 1,
                pl: 6,
                pr: 6,
                textAlign: "center",
              }}
              data-testid="filing-duplicate-order-found"
            >
              <Typography
                variant="h2"
                fontSize="1.2rem"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  fontWeight: "bold",
                }}
              >
                <Restore sx={{ mr: 1 }} />
                <span>This document was already ordered.</span>
              </Typography>
              <Box>
                <Typography variant="h3" fontSize="1.2rem">
                  Order ID - {order.orderId}
                </Typography>
                {order.customerReference && (
                  <Typography
                    title={order.customerReference}
                    sx={{
                      display: "inline-block",
                      fontSize: "0.8rem",
                      textOverflow: "ellipsis",
                      maxWidth: "350px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      margin: "auto",
                    }}
                  >
                    {order.customerReference}
                  </Typography>
                )}
              </Box>
              <Typography
                variant="h4"
                fontSize="0.9rem"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CalendarMonth sx={{ mr: 0.5 }} />
                <span>
                  {format(new Date(order.orderDate), "MMM do yyyy HH:mm")}
                </span>
                {dayDiff > 0 && (
                  <span>
                    &nbsp;({dayDiff} day{dayDiff > 1 ? "s" : ""} ago)
                  </span>
                )}
                {order?.status === "Pending" && (
                  <>
                    <PendingOutlined sx={{ ml: 0.5, mr: 0.5 }} />
                    <span>Order Pending</span>
                  </>
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginBottom: "10%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3rem",
                  textAlign: "center",
                }}
              >
                Information may have changed since the last order.
                <br />
                Would you like to order a new document?
              </Typography>
            </Box>
          </>
        )}
        {!order && <>Something went wrong checking for previous orders</>}
      </DialogContent>
      <DialogActions
        sx={{
          paddingRight: 2,
          paddingBottom: 2,
          paddingLeft: 2,
          paddingTop: 0,
          mt: -2,
        }}
      >
        <FormGroup
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            flexFlow: "row nowrap",
            width: "100%",
            gap: 1,
          }}
        >
          <Box>
            <Button
              autoFocus
              onClick={handleCloseDialog}
              sx={{
                backgroundColor: "primary.light",
                color: "primary.dark",
                "&:hover": {
                  color: "white",
                },
              }}
            >
              Cancel
            </Button>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              sx={{
                backgroundColor: "white",
                color: "primary.dark",
                ":hover": {
                  color: "white",
                },
              }}
              disabled={!order || !order.links?.document}
              data-testid="filing-dialog-duplicate-download-button"
              variant="outlined"
              onClick={() => {
                Userpilot.track("Downloaded Past Order");
                downloadPreviousOrder();
              }}
            >
              Download Past Order
            </Button>
            <Button
              data-testid="filing-dialog-duplicate-order-button"
              onClick={() => {
                Userpilot.track("Overridden Past Order");
                doAction();
              }}
            >
              Order New Document
            </Button>
          </Box>
        </FormGroup>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateOrderDialog;
