export interface IUBOStatus {
  status: string;
  message: string;
}

export const uboStatuses: IUBOStatus[] = [
  {
    status: "SERVICE_UNAVAILABLE",
    message: "Service unavailable at this time, please try again later.",
  },
  {
    status: "NO_SHAREHOLDERS",
    message: "No shareholder data available to display ownership.",
  },
  {
    status: "INVALID_REGAUTH",
    message: "Invalid Registration Authority.",
  },
  {
    status: "REGISTRY_NOT_AVAILABLE",
    message: "Registry not available at this time.",
  },
  {
    status: "INSUFFICIENT_MAX_CREDIT_COST",
    message: "Not enough credits to perform UBO Verify.",
  },
  {
    status: "INSUFFICIENT_CREDITS",
    message: "Not enough credits to perform UBO Verify.",
  },
  {
    status: "105",
    message: "Company not found.",
  },
];

export const uboPersonValues: string[] = [
  "Person",
  "Individual",
  "Natural person shareholder",
  "P",
  "PERSONA",
  'PERSONA "Person"',
  "Sole Shareholder",
  "Personne Physique",
  'Personne Physique "Person"',
  "自然人股东",
];

export const defaultMaxCreditCost_const = 5;
export const defaultStartCostRate_const = 3;
export const defaultUnwrapFee_const = 2;
