import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  RadioGroupProps,
  Box,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

type ControlLabel = {
  id: string;
  value: string;
};

type Props<T extends FieldValues> = {
  label: string;
  name: Path<T>;
  control: Control<T, string>;
  data: ControlLabel[];
  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;
} & RadioGroupProps;

const RadioButtonGroupInput = <T extends FieldValues>({
  control,
  name,
  label,
  data,
  value,
  setValue,
  sx,
}: Props<T>) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) {
      setValue(event.target.value);
    }
  };

  return (
    <FormControl sx={{ ...(Boolean(sx) && sx) }} size="small">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <FormLabel>{label}</FormLabel>
              <RadioGroup
                {...field}
                row
                sx={{
                  display: "block",
                  "& .Mui-checked": {
                    color: "rgb(0, 198, 115)",
                  },
                }}
                value={value}
                onChange={handleChange}
              >
                <Box>
                  {data.map((datum) => (
                    <FormControlLabel
                      label={datum.value}
                      key={datum.id}
                      value={datum.id}
                      control={<Radio color="primary" />}
                    />
                  ))}
                </Box>
                <Box>
                  {error && (
                    <FormHelperText sx={{ fontSize: "0.75rem" }} error>
                      {error.message}
                    </FormHelperText>
                  )}
                </Box>
              </RadioGroup>
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default RadioButtonGroupInput;
