import { Box } from "@mui/material";
import { useCompanyContext } from "contexts/CompanyContext";
import CompanyOfficial from "./CompanyOfficial";
import useCompanyOfficialsSort from "./useCompanyOfficialsSort";
import { useForm, useWatch } from "react-hook-form";
import SortSelect from "components/SortSelect";

type FormInputs = {
  sortBy: { label: string; data: string };
  isDescending: boolean;
};

const CompanyOfficials: React.FC = () => {
  const { companyProfile } = useCompanyContext();
  const directors = companyProfile.directorAndShareDetails?.directors ?? [];
  const companyOfficials = directors.map((director) => ({
    name: director.name,
    addressInOneLine: `${director.address1 || ""} ${director.address2 || ""} ${
      director.address3 || ""
    } ${director.address4 || ""} ${director.address5 || ""} ${
      director.address6 || ""
    } ${director.postcode || ""} ${director.cityTown || ""} ${
      director.country || ""
    }`,
    nationality: director.nationality,
    dateOfBirth: director.birthdate,
    role: director.title || director.officerRole,
    dateAppointed: director.appointedOn,
  }));
  const shouldShowAppointmentDate = !companyOfficials.some(
    (co) => !co.dateAppointed,
  );
  const sortOptions = [
    ...(shouldShowAppointmentDate
      ? [
          {
            label: "Appointment Date",
            data: "appointment-date",
          },
        ]
      : []),
    { label: "Name", data: "name" },
    { label: "Role", data: "role" },
  ];

  const { control, setValue } = useForm<FormInputs>({
    defaultValues: {
      sortBy: sortOptions[0],
      isDescending: true,
    },
  });

  const sorting = useWatch({ control, name: "sortBy" });
  const isDescending = useWatch({ control, name: "isDescending" });
  const sortedOfficials = useCompanyOfficialsSort(
    companyOfficials,
    sorting.data,
    isDescending,
  );

  const onAscDescHandler = () => {
    setValue("isDescending", !isDescending);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
    >
      <SortSelect
        control={control}
        options={sortOptions}
        onAscDescHandler={onAscDescHandler}
        isDescending={isDescending}
        inputWidth="7rem"
      />
      <Box>
        {sortedOfficials &&
          sortedOfficials.map(
            (
              {
                name,
                addressInOneLine,
                nationality,
                dateOfBirth,
                dateAppointed,
                role,
              },
              index,
            ) => (
              <CompanyOfficial
                key={index}
                name={name}
                addressInOneLine={addressInOneLine}
                nationality={nationality}
                dateOfBirth={dateOfBirth}
                dateAppointed={dateAppointed}
                role={role}
              />
            ),
          )}
      </Box>
    </Box>
  );
};

export default CompanyOfficials;
