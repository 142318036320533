export class FetchError<T = unknown> extends Error {
  message: string;

  code: number;

  response: T;

  constructor(message: string, code: number, response: T) {
    super();
    this.message = message;
    this.code = code;
    this.response = response;
  }
}
