import React from "react";
import { Schedule } from "@mui/icons-material";
import { dark } from "../variables";
import { Box, Typography } from "@mui/material";

type Props = {
  time: string;
};

const Time: React.FC<Props> = ({ time }): JSX.Element => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Schedule sx={{ color: dark, fontSize: "0.875rem" }} />
      <Typography color={dark} sx={{ fontSize: "0.875rem" }}>
        {time}
      </Typography>
    </Box>
  );
};

export default Time;
