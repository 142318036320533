import { useEffect, useState } from "react";
import isEmpty from "lodash.isempty";
import { Box, Tabs, Tab, SxProps, Typography } from "@mui/material";
import { useCompanyContext } from "contexts/CompanyContext";
import Filings from "./Tabs/Filings";
import CompanyOfficials from "./Tabs/CompanyOfficials";
import Shareholders from "./Tabs/Shareholders";
import PersonsOfSignificantControl from "./Tabs/PersonsOfSignificantControl";
import useVisitedTab, { IActionType } from "./Tabs/useVisitedTab";
import GreenPulsingDot from "components/GreenPulsingDot";
import UBOVerify from "./Tabs/Ubo";
import Officers from "./Tabs/Officers";
import { IAccountInfo } from "types";
import { useApi } from "hooks/useApi";
import RegistrationInformation from "./RegistrationInformation";
import { DirectorAndShareDetails, Grid } from "./types";
import { TabPages } from "../constants";
import { CompanyProfile } from "contexts/CompanyContext/types";
import { Userpilot } from "userpilot";

type Props = {
  sx: SxProps;
  grid: Grid;
  setGrid: React.Dispatch<React.SetStateAction<Grid>>;
  selectedTab?: string;
  treeId?: number | null;
  compName: string | null;
  regAuth?: string | null;
  directorAndShareDetails: DirectorAndShareDetails | null;
  cachedProfile: { companyProfile: CompanyProfile } | null;
};

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  selected: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, selected, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== selected}
      id={`scrollable-auto-tabpanel-${value}`}
      aria-labelledby={`scrollable-auto-tab-${value}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const TabbedContent: React.FC<Props> = ({
  sx,
  setGrid,
  grid,
  selectedTab, //used to programmatically change the tab, pass the name of the tab you wanna open
  treeId, //ubo tree id to display, passed from history
  compName, //compName is passed through when clicking the UBO preview from history. Ubo order doesn't have the company name otherwise
  regAuth,
  directorAndShareDetails,
  cachedProfile,
}): JSX.Element => {
  const { companyProfile, uboVerifyEnabled } = useCompanyContext();

  if (directorAndShareDetails) {
    companyProfile.directorAndShareDetails = {
      directors: directorAndShareDetails.directors,
      shareHolderSummary: {
        shareCapital:
          directorAndShareDetails.shareHolderSummary?.shareCapital ?? 0,
      },
      personsOfSignificantControl:
        directorAndShareDetails.personsOfSignificantControl ?? [],
      shareHolders: directorAndShareDetails.shareHolders,
      capitalReserves: null,
    };
  }

  const { data } = useApi<IAccountInfo>("/accountInfo");
  const userHasUboVerifyEnabled = data?.isUboVerifyEnabled || false;
  const [value, setValue] = useState(TabPages.FILINGS.toString());
  const [isInitialUnwrap, setIsInitialUnwrap] = useState<boolean>(true);
  const {
    uboVisited,
    companyOfficialsVisited,
    officersVisited,
    shareholdersVisited,
    pscVisited,
    setVisited,
  } = useVisitedTab();

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    Userpilot.track(event.currentTarget.textContent + " Tab Clicked");

    setValue(newValue);
    if (newValue !== TabPages.FILINGS) {
      setVisited(newValue as IActionType);
    }
  };

  const setSelectedTab = (tab: TabPages) => {
    setValue(tab);
  };

  useEffect(() => {
    if (value === TabPages.UBO) {
      setVisited(value as IActionType);
    }
  }, [setVisited, value]);

  useEffect(() => {
    if (
      uboVerifyEnabled &&
      userHasUboVerifyEnabled &&
      selectedTab === TabPages.UBO
    ) {
      setValue(TabPages.UBO.toString());
    }
  }, [uboVerifyEnabled, userHasUboVerifyEnabled, selectedTab]);

  useEffect(() => {
    if (cachedProfile) {
      if (cachedProfile.companyProfile.name) {
        companyProfile.name = cachedProfile.companyProfile.name;
      }
      if (cachedProfile.companyProfile.code) {
        companyProfile.code = cachedProfile.companyProfile.code;
      }
      if (cachedProfile.companyProfile.registrationAuthority) {
        companyProfile.registrationAuthority =
          cachedProfile.companyProfile.registrationAuthority;
      }
      if (cachedProfile.companyProfile.registrationAuthorityCode) {
        companyProfile.registrationAuthorityCode =
          cachedProfile.companyProfile.registrationAuthorityCode;
      }
    }
  }, [cachedProfile, companyProfile]);

  useEffect(() => {
    if (compName) {
      companyProfile.name = compName;
    }
  }, [compName, companyProfile]);
  const greenDot = (beenVisited: boolean) => {
    return beenVisited ? <></> : <GreenPulsingDot />;
  };

  useEffect(() => {
    if (value === TabPages.UBO && !isInitialUnwrap) {
      setGrid({
        gridTemplateColumns: "1fr",
        gridTemplateAreas: "ubo",
        isSingleLayout: true,
      } as Grid);
    } else {
      setGrid({
        gridTemplateColumns: "1fr 2fr",
        gridTemplateAreas: '"registration tabs"',
        isSingleLayout: false,
      } as Grid);
    }
  }, [value, setGrid, isInitialUnwrap]);

  const isSingleLayout = grid?.isSingleLayout;

  return (
    <>
      {!isSingleLayout && (
        <RegistrationInformation
          sx={{ gridArea: "registration" }}
          setSelectedTab={setSelectedTab}
        />
      )}
      <Box sx={{ minWidth: "400px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="structured-company-information"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.primary.light}`,
            sx,
            "& .MuiTabs-indicator": {
              backgroundColor: "success.light",
            },
            "& .MuiTab-root": {
              textTransform: "none",
            },
          })}
        >
          {/* TODO: Refactor company name as part of grid, then put this outside the Tabs component to avoid console errors in styled Tab properties */}
          {isSingleLayout && (
            <Typography
              variant="h4"
              fontSize="2.375rem"
              fontFamily="Sohne"
              color="primary.dark"
              mb={1}
              style={{ marginRight: 100 }}
            >
              {companyProfile.name}
            </Typography>
          )}
          <Tab value={TabPages.FILINGS} label="Filings" />
          {!isEmpty(companyProfile?.directorAndShareDetails?.directors) && (
            <Tab
              value={TabPages.OFFICIALS}
              icon={greenDot(companyOfficialsVisited)}
              iconPosition="end"
              label="Company Officials"
            />
          )}
          {!isEmpty(companyProfile?.officers) && (
            <Tab
              value={TabPages.OFFICERS}
              icon={greenDot(officersVisited)}
              iconPosition="end"
              label="Officers"
            />
          )}
          {!isEmpty(companyProfile?.directorAndShareDetails?.shareHolders) && (
            <Tab
              value={TabPages.OWNERSHIP}
              icon={greenDot(shareholdersVisited)}
              iconPosition="end"
              label="Shareholding"
            />
          )}
          {uboVerifyEnabled && userHasUboVerifyEnabled && (
            <Tab
              value={TabPages.UBO}
              icon={greenDot(uboVisited)}
              iconPosition="end"
              label="UBO Verify"
            />
          )}
          {!isEmpty(
            companyProfile?.directorAndShareDetails
              ?.personsOfSignificantControl,
          ) && (
            <Tab
              value={TabPages.SIGNIFICANT_CONTROL}
              icon={greenDot(pscVisited)}
              iconPosition="end"
              label="Persons of Significant Control"
            />
          )}
        </Tabs>
        <TabPanel selected={value} value={TabPages.FILINGS}>
          <Filings />
        </TabPanel>
        {uboVerifyEnabled && userHasUboVerifyEnabled && (
          <TabPanel selected={value} value={TabPages.UBO}>
            <UBOVerify
              grid={grid}
              isActive={value == TabPages.UBO}
              treeId={treeId}
              regAuth={regAuth}
              isInitialUnwrap={isInitialUnwrap}
              setIsInitialUnwrap={setIsInitialUnwrap}
            />
          </TabPanel>
        )}
        {!isEmpty(companyProfile?.officers) && (
          <TabPanel selected={value} value={TabPages.OFFICERS}>
            <Officers />
          </TabPanel>
        )}
        {!isEmpty(companyProfile?.directorAndShareDetails?.directors) && (
          <TabPanel selected={value} value={TabPages.OFFICIALS}>
            <CompanyOfficials />
          </TabPanel>
        )}
        {!isEmpty(companyProfile?.directorAndShareDetails?.shareHolders) && (
          <TabPanel selected={value} value={TabPages.OWNERSHIP}>
            <Shareholders
              userHasUboVerifyEnabled={userHasUboVerifyEnabled}
              setCurrentTabValue={setValue}
            />
          </TabPanel>
        )}
        {!isEmpty(
          companyProfile?.directorAndShareDetails?.personsOfSignificantControl,
        ) && (
          <TabPanel selected={value} value={TabPages.SIGNIFICANT_CONTROL}>
            <PersonsOfSignificantControl />
          </TabPanel>
        )}
      </Box>
    </>
  );
};

export default TabbedContent;
