import { SxProps } from "@mui/material/styles";

type Props = {
  width?: string;
  height?: string;
  transform?: string;
  sx?: SxProps;
};

const CircularOwnershipIcon: React.FC<Props> = ({
  width,
  height,
  transform,
}): JSX.Element => {
  const defaultTransform = "scale(1.5) translate(23.25,0.75)";
  return (
    <svg
      width={width ?? 35} // 17
      height={height ?? 22}
      viewBox="0 0 35 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform={transform ?? defaultTransform}>
        <path
          d="M-20 9H15"
          stroke="#CA2828"
          stroke-width="2"
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
};

export default CircularOwnershipIcon;
