import { ReactNode, useEffect, useRef, useState } from "react";
import { Theme, ThemeProvider } from "@mui/material";
import { Box } from "@mui/material";
import theme from "pages/theme";
import { useLocation } from "react-router-dom";
import CompanySearch from "components/CompanySearch";
import Header from "components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { lightest } from "../pages/variables";

type Props = {
  children: ReactNode;
};

export default ({ children }: Props) => {
  document.body.style.backgroundColor = lightest;
  const { pathname } = useLocation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  const headerRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLDivElement | null>(null);

  const showSearch = ["manual-retrieval", "search", "company"].some(
    (element) => {
      if (pathname.includes(element)) {
        return true;
      }

      return false;
    },
  );

  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [searchHeight, setSearchHeight] = useState<number>(0);

  useEffect(() => {
    const { current: header } = headerRef;
    const { current: search } = searchRef;

    if (header) {
      setHeaderHeight(header.getBoundingClientRect().height);
    }

    if (search) {
      setSearchHeight(search.getBoundingClientRect().height);
    }
  }, [pathname, isMobile, headerRef, searchRef]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "fixed",
          zIndex: 999,
          width: "100%",
        }}
      >
        <Box
          ref={headerRef}
          sx={{
            [theme.breakpoints.down("sm")]: {
              marginX: "10px",
            },
            [theme.breakpoints.up("sm")]: {
              paddingX: "70px",
            },
            backgroundColor: "white",
          }}
        >
          <Header />
        </Box>
        {showSearch && (
          <Box
            ref={searchRef}
            sx={{
              [theme.breakpoints.down("sm")]: {
                marginX: "10px",
              },
              [theme.breakpoints.up("sm")]: {
                marginX: "70px",
              },
              backgroundColor: lightest,
            }}
          >
            <CompanySearch />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          paddingTop: `${headerHeight + searchHeight}px`,
        }}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
};
