import { useMemo } from "react";
import { PersonOfSignificantControl } from "./PersonOfSignificantControl";

const usePersonOfSignificantControlSort = (
  personsOfSignificantControl: PersonOfSignificantControl[],
  sortBy: string,
  isDescending: boolean,
) => {
  const sortedPOSC = useMemo(() => {
    const sorted = [...(personsOfSignificantControl || [])].sort((a, b) => {
      if (sortBy === "nationality") {
        return a.nationality < b.nationality ? -1 : 1;
      }
      if (sortBy === "name") {
        return a.name < b.name ? -1 : 1;
      }
      if (sortBy === "country-of-residence") {
        return a.residency < b.residency ? -1 : 1;
      }

      return 0;
    });

    if (!isDescending) {
      return sorted.reverse();
    }

    return sorted;
  }, [sortBy, personsOfSignificantControl, isDescending]);

  return sortedPOSC;
};

export default usePersonOfSignificantControlSort;
