import { QueryKey } from "react-query";
import { useAuthFetch } from "./useAuthFetch";

type Props = {
  queryKey: QueryKey;
};

const useApiFetcher = <T>() => {
  const fetch = useAuthFetch();

  return async ({ queryKey }: Props) => {
    const [path] = queryKey;
    const response = await fetch(path as RequestInfo);

    const data = await response.json();

    return data as T;
  };
};

export default useApiFetcher;
