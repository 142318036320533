import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { Room } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

const InnerContainer = styled.div`
  height: 30vh;
`;

interface IMapPinProps {
  lat: number;
  lng: number;
}

const MapPin: React.FC<IMapPinProps> = () => <Room />;

interface IGoogleMapProps {
  address: string;
}

type Response = {
  results: { geometry: { location: IMapPinProps } }[];
};

const GoogleMap: React.FC<IGoogleMapProps> = ({ address }) => {
  const [data, setData] = useState<Response | null>(null);

  async function getGoogleMaps() {
    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address,
      )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    ).then((data) => data.json() as Promise<Response>);
  }

  useEffect(() => {
    if (address && !data) {
      getGoogleMaps()
        .then((resp) => {
          setData(resp);
        })
        .catch(() => console.log("Error requesting a map"));
    }
    // TODO:: Bad use of the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, data]);

  if (data && data?.results?.[0]?.geometry?.location.lat) {
    return (
      <>
        <InnerContainer>
          <Typography fontSize="0.75rem">Google Maps Location</Typography>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
            }}
            defaultCenter={data?.results?.[0]?.geometry?.location}
            defaultZoom={16}
            options={{
              disableDefaultUI: true,
            }}
          >
            <MapPin
              lat={data?.results?.[0]?.geometry?.location.lat}
              lng={data?.results?.[0]?.geometry?.location.lng}
            />
          </GoogleMapReact>
        </InnerContainer>
      </>
    );
  } else {
    return (
      <InnerContainer>
        <Typography fontSize="0.75rem">Location is unavailable</Typography>
      </InnerContainer>
    );
  }
};

export default GoogleMap;
