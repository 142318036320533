import React from "react";
import { Box, Typography, SxProps, CircularProgress } from "@mui/material";

type Props = {
  message?: string;
  sx?: SxProps;
};

const LoadingMessage: React.FC<Props> = ({
  message = "We're authenticating your request",
  sx,
}): JSX.Element => {
  return (
    <Box
      sx={
        sx
          ? sx
          : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: "75vh",
              gap: 2,
            }
      }
    >
      <CircularProgress size="1em" color="success" variant="indeterminate" />
      <Typography color="primary" fontSize="0.75rem">
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingMessage;
