import { TextField, TextFieldProps } from "@mui/material";
import { Controller, Control, Path, FieldValues } from "react-hook-form";
import { middark } from "pages/variables";

type Props<T extends FieldValues> = {
  name: string;
  label?: string;
  control: Control<T, unknown>;
} & TextFieldProps;

const TextInput = <T extends FieldValues>({
  name,
  label,
  control,
  sx,
  ...textFieldProps
}: Props<T>): JSX.Element => {
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field, fieldState: { invalid, error } }) => (
        <TextField
          {...field}
          {...(Boolean(label) && { label })}
          InputProps={{ "aria-label": name }}
          error={invalid}
          variant="standard"
          sx={{
            margin: 2,
            "& .MuiInput-root": {
              fontSize: "0.875rem",
              fontWeight: 500,
              "& :placeholder": {
                color: middark,
              },
            },
            ...(sx && sx),
          }}
          {...(Boolean(error) && {
            helperText: error?.message,
          })}
          {...textFieldProps}
        />
      )}
    />
  );
};

export default TextInput;
