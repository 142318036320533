import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import TabbedContent from "./TabbedContent";
import { DirectorAndShareDetails, Grid } from "./types";
import { useLocation } from "react-router-dom";
import { Userpilot } from "userpilot";

export default function Company(): JSX.Element {
  const [grid, setGrid] = useState<Grid>({
    gridTemplateColumns: "1fr 2fr",
    gridTemplateAreas: '"registration tabs"',
    isSingleLayout: false,
  } as Grid);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const selectedTab = query.get("selectedTab") ?? undefined;
  const treeIdStr = query.get("treeId");
  const compName = query.get("compName");
  const regAuth = query.get("registrationAuthorityCode");
  const retrievalUrl = query.get("url");
  const treeId = treeIdStr ? Number(treeIdStr) : null;
  let directorAndShareDetails: DirectorAndShareDetails | null = null;
  const [cachedProfile, setCachedProfile] = useState(null);
  useEffect(() => {
    Userpilot.track("Entered Company Profile Page");
  }, []);

  function getJsonEnhancedProfileFromUrl(theUrl: string) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
        try {
          // Replace [Field": ] with [": ]. Aka remove the Field suffix from keys in the safest way to not match Field in values
          const cleanResponse = xmlHttp.responseText.replace(
            /Field": /g,
            '": ',
          );
          const profile = JSON.parse(cleanResponse);
          setCachedProfile(profile);
          directorAndShareDetails =
            profile.companyProfile.directorAndShareDetails;
        } catch (error) {
          console.error("Could not read json from retrieval url: " + theUrl);
          console.error(error);
        }
      }
    };

    try {
      xmlHttp.open("GET", theUrl, false);
      xmlHttp.send(null);
    } catch (error) {
      console.error("Could not get from url: " + theUrl);
      console.error(error);
    }
  }
  useEffect(() => {
    if (retrievalUrl) {
      let url = retrievalUrl;
      // fix the url in case it's not pointing to the json
      // from : https://kyckrportalcidownloadapim.azure-api.net/file/969658544
      // to   : https://kyckrportalcidownloadapim.azure-api.net/file/json/969658544
      if (!retrievalUrl.includes("json")) {
        url = retrievalUrl.replace("file/", "file/json/");
      }

      getJsonEnhancedProfileFromUrl(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrievalUrl]);

  return (
    <Box
      p="20px 70px"
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: grid.gridTemplateColumns,
        gridTemplateAreas: grid.gridTemplateAreas,
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
          gridTemplateAreas: '"registration" "tabs"',
        },
        gridColumnGap: "50px",
      })}
    >
      <TabbedContent
        sx={{ gridArea: "tabs" }}
        setGrid={setGrid}
        grid={grid}
        selectedTab={selectedTab}
        treeId={treeId}
        compName={compName}
        regAuth={regAuth}
        directorAndShareDetails={directorAndShareDetails}
        cachedProfile={cachedProfile}
      />
    </Box>
  );
}
