import { Snackbar } from "@mui/material";

export interface IToastProps {
  message: string;
  isOpen: boolean;
  duration?: number;
  onClose?: () => void;
}

export const Toast: React.FC<IToastProps> = ({
  message,
  isOpen,
  duration,
  onClose,
}) => {
  return (
    <Snackbar
      open={isOpen}
      message={message}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={duration}
      onClose={onClose}
    />
  );
};
